import React from "react";
import { TableData, SelectColumnFilter } from "../../../components/TableData";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { Link } from "react-router-dom";
import { getStaticData } from "../../../data/static";

export default function TableStyle() {
  const [value, loading, error] = useDocumentData(getStaticData(), {
    idField: "key"
  });
  const columns = React.useMemo(
    () => [
      {
        Header: "Colour Label (English)",
        accessor: "label",
        Filter: SelectColumnFilter,
        filter: "includes",
        sortType: "basic"
      },
      {
        Header: "Colour internal value",
        accessor: "value",
        Filter: SelectColumnFilter,
        filter: "includes",
        sortType: "basic"
      },
      {
        Header: "Actions",
        id: "edit",
        Cell: function renderCellEdit(page) {
          return (
            <Link to={`/edit-colour/${page.row.original.value}`}>Edit</Link>
          );
        }
      }
    ],
    []
  );
  const colours = React.useMemo(() => {
    if (loading) {
      return [];
    }
    if (!loading && error) {
      console.log(error);
    }
    return value.Colours;
  }, [loading, value, error]);

  return (
    <div>
      <TableData columns={columns} data={colours} />
    </div>
  );
}
