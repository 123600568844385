import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import Skeleton from '@mui/material/Skeleton';
import { useDocumentData } from "react-firebase-hooks/firestore";
import { Box, Card, CardContent, Grid, colors } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import moment from "moment";
import { itemAnalyticsMonth } from "../../../data/item";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%"
  },
  differenceIconPositive: {
    color: colors.green[900]
  },
  differenceValuePositive: {
    color: colors.green[900],
    marginRight: theme.spacing(1)
  },
  differenceIconNegative: {
    color: colors.red[900]
  },
  differenceValueNegative: {
    color: colors.red[900],
    marginRight: theme.spacing(1)
  }
}));

export default function ItemAnalyticsMonth(props) {
  const { id } = props;
  const classes = useStyles();
  // Year & Months
  const selectedYear = moment().format("YYYY");
  const selectedMonth = moment().format("MM");
  // Item in back-end
  const itemId = id;
  const refItem = itemAnalyticsMonth(itemId, selectedYear, selectedMonth);
  
  const [
    collectionAnalytics,
    loadingAnalytics,
    errorAnalytics
  ] = useDocumentData(refItem);

  return <>
    {errorAnalytics && (
      <Typography>Error: {JSON.stringify(errorAnalytics)}</Typography>
    )}
    {loadingAnalytics ? (
      <Skeleton variant="rectangular" width="99%" height={50} animation="wave" />
    ) : (
      <Card className={classes.root}>
        <CardContent>
          <Grid container justifyContent="space-between" spacing={2}>
            <Grid item>
              <Typography color="textSecondary" gutterBottom variant="h6">
                MONTHY ANALYTICS
              </Typography>
              <Typography color="textPrimary" variant="h4">
                {collectionAnalytics
                  ? collectionAnalytics.itemPutInAR
                  : "NaN"}
              </Typography>
            </Grid>
          </Grid>
          <Box mt={2} display="flex" alignItems="center">
            {collectionAnalytics ? (
              parseInt(collectionAnalytics.itemPutInAR_MoM) > 0 ? (
                <>
                  <ArrowUpwardIcon
                    className={classes.differenceIconPositive}
                  />
                  <Typography
                    className={classes.differenceValuePositive}
                    variant="body2"
                  >
                    {collectionAnalytics.itemPutInAR_MoM}%
                  </Typography>
                </>
              ) : (
                <>
                  <ArrowDownwardIcon
                    className={classes.differenceIconNegative}
                  />
                  <Typography
                    className={classes.differenceValueNegative}
                    variant="body2"
                  >
                    {collectionAnalytics.itemPutInAR_MoM}%
                  </Typography>
                </>
              )
            ) : (
              "NaN"
            )}
            <Typography color="textSecondary" variant="caption">
              Since last month
            </Typography>
          </Box>
        </CardContent>
      </Card>
    )}
  </>;
}

ItemAnalyticsMonth.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired
};
