import React from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import { useController } from "react-hook-form";

/* eslint-disable no-unused-vars */
function Input({ control, name, label, defaultValue, ...option }) {
    const {
      field: { onChange, onBlur, nameInput, value, ref },
      fieldState: { invalid, isTouched, isDirty, error },
      formState: { touchedFields, dirtyFields }
    } = useController({
      name,
      control,
      rules: { required: true },
      defaultValue: defaultValue || "",
    });
  
    return (
      <TextField 
        onChange={onChange} // send value to hook form 
        onBlur={onBlur} // notify when input is touched/blur
        value={value} // input value
        name={nameInput} // send down the input name
        label={label}
        inputRef={ref} // send input ref, so we can focus on input when error appear
        //sx={{ m: 1 }}
        fullWidth
        variant="standard"
        {...option}
        error={invalid}
        helperText={invalid && error?.message}
      />
    );
  }

  export default Input;

  Input.propTypes = {
    control: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    defaultValue: PropTypes.string
  };
  