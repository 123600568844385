import * as yup from "yup";

export const categorySchema = yup.object().shape({
  label: yup
    .string()
    .min(2)
    .max(30)
    .required(),
  value: yup
    .string()
    .matches(/^\S*$/, { message: "No whitespace allowed" })
    .required(),
  order: yup
    .number()
    .integer()
    .required()
});

export const styleSchema = yup.object().shape({
  label: yup
    .string()
    .min(2)
    .max(30)
    .required(),
  value: yup.string().required()
});

export const colourSchema = yup.object().shape({
  label: yup
    .string()
    .min(2)
    .max(30)
    .required(),
  value: yup.string().required()
});
