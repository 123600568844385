import React from "react";
import Typography from "@mui/material/Typography";
import Alert from '@mui/material/Alert';
import Link from "@mui/material/Link";
import { useDocumentData } from "react-firebase-hooks/firestore";
import makeStyles from '@mui/styles/makeStyles';
import Avatar from "@mui/material/Avatar";
import LinearProgress from "@mui/material/LinearProgress";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { LinkRef } from "../../components/design/button-link";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { itemRef } from "../../data/item";

const useStyles = makeStyles(theme => ({
  content: {
    paddingTop: 56,
    height: "100%",
    [theme.breakpoints.up("sm")]: {
      paddingTop: 64
    }
  },
  card: {
    padding: theme.spacing(1),
    maxWidth: 800
    //color: theme.palette.text.primary,
  },
  gridItem: {
    textAlign: "center"
  },
  chip: {
    marginRight: theme.spacing(1)
  },
  avatar: {
    height: "50px",
    width: "50px",
    flexShrink: 0,
    flexGrow: 0
  },
  media: {
    height: 0,
    paddingTop: "100%" //"56.25%" // 16:9
  },
  color: {
    width: "36px",
    height: "14px",
    borderRadius: "2px"
  },
  toolbarLogo: {
    height: 58
  },
  storeImage:{
    width: 150
  },
  viewARbutton:{
      width: '100%'
  }
}));

export default function PublicItem() {
  const classes = useStyles();
  const urlParams = useParams();
  const itemId=urlParams.id

  const refItem = itemRef(itemId);

  const decodeSize = (SizeObject, SizeParameter) => {
    if (typeof SizeObject === "undefined") {
      return "N/A";
    }
    return SizeObject[SizeParameter];
  };

  const [item, loading, error] = useDocumentData(refItem);

  return <>
    <AppBar position="static" color="primary">
      <Toolbar variant="dense">
        <Link href="https://www.snapscene.sg">
          <img
            alt="Snapscene Logo"
            src="/images/SS_Horizontal_White.png"
            className={classes.toolbarLogo}
          />
        </Link>
      </Toolbar>
    </AppBar>
    <main className={classes.content}>
      {error && <Alert severity="warning">Error loading item: {error}</Alert>}
      {loading && <LinearProgress />}
      {!loading && !item && (
        <Alert severity="warning">Error item {itemId} not found</Alert>
      )}
      {!loading && item && (
        <Grid
          container
          spacing={1}
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Card className={classes.card}>
              <CardHeader
                avatar={
                  <Avatar
                    aria-label="logo"
                    className={classes.avatar}
                    src={item.imageAR}
                  >
                    No AR Image
                  </Avatar>
                }
                titleTypographyProps={{ variant: "h5" }}
                title={item.Name}
                subheader={item.Category + " / " + item.Style}
              />
              <CardMedia
                className={classes.media}
                image={
                  _.has(item.imagesItem[0], "name")
                    ? item.imagesItem[0].name
                    : item.imageAR
                }
                title={item.Name}
              />
              <CardContent>
                <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Button
                    size="large"
                    color="primary"
                    variant="contained" 
                    className={classes.viewARbutton}
                    href={item.dynamicLink}
                  >
                    View in AR
                  </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography gutterBottom variant="subtitle1">
                      Brand: {item.Brand}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography gutterBottom variant="subtitle1">
                      Designer: {item.Designer||item.RetailerName}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography gutterBottom variant="subtitle1">
                      Dimension: L{decodeSize(item.Size, "Depth") + " "}W
                      {decodeSize(item.Size, "Width") + " "}H
                      {decodeSize(item.Size, "Height") + " "}
                    </Typography>
                  </Grid>{" "}
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">
                      Description: {item.Description}
                    </Typography>
                  </Grid>
                  <Grid container item xs={12} spacing={2}>
                  <Grid item >
                    <Typography variant="subtitle1">
                      Available on:{" "}
                    </Typography>  
                  </Grid>
                  <Grid item >
                      <Link href="https://apps.apple.com/sg/app/snapscene-ar/id1481912483">
                        <img
                          src="/images/AppleApp.png"
                          alt="Open on App store - iOS and iPad"
                          className={classes.storeImage}
                        />
                      </Link>
                      </Grid> 
                      <Grid item >    
                      <Link href="https://play.google.com/store/apps/details?id=com.Snapscene.SnapsceneAR&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                        <img 
                            alt="Get it on Google Play" 
                            src="/images/GooglePlay.png"
                            className={classes.storeImage}
                        />    
                      </Link>
                     </Grid> 
                  </Grid>
                </Grid>
                <Typography gutterBottom variant="subtitle1">
                  Retailer: {item.RetailerName}
                  <Button
                    size="small"
                    color="primary"
                    component={LinkRef}
                    to={"/retailer/" + item.RetailerId}
                  >
                    VIEW RETAILER PAGE
                  </Button>
                </Typography>
                <Typography gutterBottom variant="subtitle1">
                  Retailer product url:
                  {item.urlItemRetailer ? (
                    <Link href={item.urlItemRetailer}>
                      {item.urlItemRetailer}
                    </Link>
                  ) : (
                    "Not link defined"
                  )}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </main>
  </>;
}

