import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Avatar, Typography } from "@mui/material";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { retailerRef } from "../../../../../../data/retailer";
import Skeleton from '@mui/material/Skeleton';
import { getAuth } from "firebase/auth";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "fit-content"
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    marginTop: theme.spacing(1)
  }
}));

const Profile = props => {
  const { className, retailerId, ...rest } = props;
  const classes = useStyles();
  const [userName, setUserName] = useState("Loading...");

  const refRetailer = retailerRef(retailerId);
  const [retailer, loading] = useDocumentData(refRetailer);
  const auth = getAuth();
  const user = auth.currentUser;

  useEffect(() => {
    const name = user.displayName !== null ? user.displayName : user.email;
    setUserName(name);
  }, [user]);

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <>
        {loading ? (
          <Skeleton
            animation="wave"
            variant="circular"
            className={classes.avatar}
          />
        ) : (
          <Avatar
            alt="Person"
            className={classes.avatar}
            component={RouterLink}
            src={retailer.LogoImagePath}
            to="/settings"
          />
        )}
        <Typography className={classes.name} variant="h5">
          {loading ? <Skeleton animation="wave" width={200} /> : retailer.Name}
        </Typography>
        <Typography variant="body2">
          {loading ? <Skeleton animation="wave" width={200} /> : userName}
        </Typography>
      </>
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string,
  retailerId: PropTypes.string.isRequired
};

export default Profile;
