import React from "react";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import { LinkRef } from "../../components/design/button-link";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import { itemAlignments } from "./item-constant";
import ShowModelURL from "../Model/Show-Model-Url";
import Alert from '@mui/material/Alert';
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import { useDocumentData } from "react-firebase-hooks/firestore";
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Avatar from "@mui/material/Avatar";
import ShowAudit from "../../components/Show-Audit";
import LinearProgress from "@mui/material/LinearProgress";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import AwesomeSlider from "react-awesome-slider";
import Tooltip from "@mui/material/Tooltip";
import "react-awesome-slider/dist/styles.css";
import { colorToHex } from "../StaticData/Colour/Function-Colour";
import LinkItem from "../Item/Link-Item";
import ShareItem from "./Components/Share-Item";
import QRCodeItem from "../Item/Components/QR-Item";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ItemAnalytics from "./Components/Item-analytics";
import ItemAnalyticsMonth from "./Components/Item-analytics-month";
import PaperWithTitle from "../../components/PaperWithTitle";
import { useParams } from "react-router-dom";
import { itemRef, updateItemFields } from "../../data/item";

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.primary
  },
  card: {
    height: "100%"
  },
  deleteButton: {
    background: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    width: "100%"
  },
  color: {
    width: "36px",
    height: "14px",
    borderRadius: "2px"
  },
  swatch: {
    padding: "2px",
    background: "#fff",
    borderRadius: "1px",
    boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
    display: "inline-block",
    cursor: "pointer"
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

export default function ShowItem() {
  const classes = useStyles();
  const urlParams = useParams();
  const itemId = urlParams.id;
  const [value, setValue] = React.useState(0);

  const refItem = itemRef(itemId);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const decodeAlignement = intAlignment => {
    const alignement = itemAlignments.find(
      item => item.value === parseInt(intAlignment)
    );
    return alignement ? alignement.label : "Alignment unknow:" + intAlignment;
  };
  // const decodeTimeStamp = timeStamp => {
  //   if (
  //     typeof timeStamp === "undefined" ||
  //     timeStamp === null ||
  //     typeof timeStamp === "string"
  //   ) {
  //     return "N/A";
  //   }
  //   const date = new Date(timeStamp.toDate());
  //   return date.toString();
  // };
  const updateIsDeleted = async(id) => {
    let currentDeletedValue = item.isDeleted;
      try{
        await updateItemFields(id, {
          isDeleted: !currentDeletedValue
        });   
   
        let successMsg = !currentDeletedValue ? "deleted" : "restored";
        enqueueSnackbar("Item has been " + successMsg + ".", {
          variant: "success"
        });

        if (!currentDeletedValue) {
          navigate("/list-items");
        }
      }
      catch(error) {
        enqueueSnackbar("Firebase error when deleting." + error, {
          variant: "error"
        });
      }
  };

  const [item, loading, error] = useDocumentData(refItem, {
    snapshotListenOptions: { includeMetadataChanges: true }
  });
  let navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      {error && <Alert severity="warning">Error loading item: {error}</Alert>}
      {loading && <LinearProgress />}
      {!loading && !item && (
        <Alert severity="warning">Error item {itemId} not found</Alert>
      )}
      {!loading && item && (
        <Grid container spacing={4}>
          <Grid item xs={12} sm={9}>
            <Card className={classes.card}>
              <CardHeader
                avatar={
                  <Avatar
                    aria-label="logo"
                    sx={{ width: 50, height: 50 }}
                    src={item.imageAR}
                  >
                    No AR Image
                  </Avatar>
                }
                titleTypographyProps={{ variant: "h5" }}
                title={item.Name}
                subheader={
                  "Last modifed: " +
                  item.lastModifedString +
                  " by " +
                  item.lastUser +
                  " "
                }
              />
              <CardActions disableSpacing>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    <Button
                      variant="contained"
                      size="medium"
                      color="primary"
                      component={LinkRef}
                      to={`/edit-item/${item.key}`}
                    >
                      Edit the item
                    </Button>
                  </Grid>
                  <Grid item>
                    <ShareItem itemId={item.key} />
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Card>
              <ItemAnalyticsMonth id={item.key} />
            </Card>
          </Grid>
          {item.isDeleted && (
            <Grid item xs={12}>
              <Alert severity="warning">This item has been deleted!</Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <Paper>
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab label="Info page" />
                <Tab label="Sharing" />
                <Tab label="Model and filtering" />
                <Tab label="Images" />
                <Tab label="Analytics" />
                <Tab label="Audit Trail" />
              </Tabs>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <TabPanel value={value} index={0}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <PaperWithTitle title="Item information">
                      <Typography gutterBottom variant="subtitle1">
                        Brand: {item.Brand}
                      </Typography>
                      <Typography gutterBottom variant="subtitle1">
                        Designer: {item.Designer}
                      </Typography>
                      <Typography variant="subtitle1">
                        Description: {item.Description}
                      </Typography>
                      <Typography gutterBottom variant="subtitle1">
                        Size: L{item.Depth+ " H"+item.Height+ " W"+item.Width}
                      </Typography>
                    </PaperWithTitle>
                </Grid>
                <Grid item xs={12} md={6}>
                    <PaperWithTitle title="Retailer information" >
                      <Typography gutterBottom variant="subtitle1">
                        Retailer:
                        <LinkRef to={`/show-retailer/${item.RetailerId}`}>
                          {item.RetailerName}
                        </LinkRef>
                      </Typography>
                      <Typography gutterBottom variant="subtitle1">
                        Retail price: {item.Price}
                      </Typography>{" "}
                      <Typography gutterBottom variant="subtitle1">
                        Retailer product url:
                        {item.urlItemRetailer ? (
                          <Link href={item.urlItemRetailer}>
                            {item.urlItemRetailer}
                          </Link>
                        ) : (
                          "Not link defined"
                        )}
                      </Typography>
                    </PaperWithTitle>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <Card>
                    <CardHeader title="Links" />
                    <CardContent>
                      <List>
                        <ListItem>
                          Dynamic link:
                          {item.dynamicLink ? (
                            <Link
                              size="small"
                              color="primary"
                              href={item.dynamicLink}
                            >
                              {item.dynamicLink}
                            </Link>
                          ) : (
                            " create a Dynamic link first"
                          )}
                        </ListItem>
                        <ListItem>
                          Public web page:
                          <Button
                            size="small"
                            color="primary"
                            component={LinkRef}
                            to={"/item/" + item.key}
                          >
                            {"https://snapscene.app/item/" + item.key}
                          </Button>
                        </ListItem>
                      </List>
                    </CardContent>
                    <CardActions disableSpacing>
                      <LinkItem itemId={item.key} />
                    </CardActions>
                  </Card>
                </Grid>

                <Grid item xs={12} md={6}>
                    <PaperWithTitle title="QR Code">
                      <QRCodeItem url={item.dynamicLink || ""} />
                  </PaperWithTitle>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  
                    <PaperWithTitle title="Model information">
                    
                      <Typography gutterBottom variant="body2">
                        USDZ Model path in back-end:
                        {item.ModelPath}
                      </Typography>{" "}
                      <Typography gutterBottom variant="body2">
                        View model in AR (iOS only):
                        {item.ModelPath && (
                          <ShowModelURL fullPath={item.ModelPath} />
                        )}
                      </Typography>
                      <Typography gutterBottom variant="body2">
                        GLB Model path in back-end:
                        {item.ModelPathGLB}
                      </Typography>
                      <Typography gutterBottom variant="body2">
                        Download GLB model:
                        {item.ModelPathGLB && (
                          <ShowModelURL fullPath={item.ModelPathGLB} />
                        )}
                      </Typography>
                      {" "}
                      <Typography gutterBottom variant="body2">
                        Alignement:
                        {decodeAlignement(item.Alignment)}
                      </Typography>
                    </PaperWithTitle>
                  
                </Grid>
                <Grid item xs={12} md={4}>
                    <PaperWithTitle title="Selection and Filtering option" >
                
                      <Typography
                        gutterBottom
                        variant="body2"
                        component={"span"}
                      >
                        Category:
                        <Chip
                          label={item.Category}
                          variant="outlined"
                          color="primary"
                          sx={{m:1}}
                        />
                      </Typography>
                      <Typography
                        gutterBottom
                        variant="body2"
                        component={"span"}
                      >
                        Style:
                        <Chip
                          label={item.Style}
                          variant="outlined"
                          color="primary"
                          sx={{m:1}}
                        />
                      </Typography>
                      <Typography
                        gutterBottom
                        variant="body2"
                        component={"span"}
                      >
                        Primary color:
                        {item.primaryColor === undefined ? (
                          "Not defined"
                        ) : (
                          <Box className={classes.swatch}>
                            <Tooltip
                              title={"RGB:" + colorToHex(item.primaryColor)}
                              placement="top-start"
                            >
                              <Button
                                className={classes.color}
                                style={{
                                  backgroundColor: colorToHex(item.primaryColor)
                                }}
                              >
                                {" "}
                              </Button>
                            </Tooltip>
                          </Box>
                        )}
                        Secondary color:
                        {item.secondaryColor === undefined ? (
                          "Not defined"
                        ) : (
                          <Box className={classes.swatch}>
                            <Tooltip
                              title={"RGB:" + colorToHex(item.secondaryColor)}
                              placement="top-start"
                            >
                              <Button
                                className={classes.color}
                                style={{
                                  backgroundColor: colorToHex(
                                    item.secondaryColor
                                  )
                                }}
                              >
                                {" "}
                              </Button>
                            </Tooltip>
                          </Box>
                        )}
                      </Typography>
                    </PaperWithTitle>
                </Grid>
                <Grid item xs={12} md={4}>
                    <PaperWithTitle title="Display dates" >
                      <Typography gutterBottom variant="body2">
                        Display after: {item.StartDisplayString}
                      </Typography>
                      <Typography gutterBottom variant="body2">
                        Do NOT display after:{" "}
                        {item.StopDisplayString}
                      </Typography>
                    </PaperWithTitle>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Typography>AR Image:</Typography>
              <img src={item.imageAR} alt={item.imageAR} />
              <Typography>Photo gallery:</Typography>
              <AwesomeSlider
                media={item.imagesItem.map(image => ({ source: image.name }))}
              />
              );
            </TabPanel>
            <TabPanel value={value} index={4}>
              <ItemAnalytics id={item.key} />
            </TabPanel>
            <TabPanel value={value} index={5}>
                <PaperWithTitle title="Audit trail:" >
                  <ShowAudit id={item.key} collection="Item" />
              </PaperWithTitle>
            </TabPanel>
          </Grid>
          <Grid item xs={12}>
            <Button
              className={classes.deleteButton}
              onClick={updateIsDeleted.bind(this, item.key)}
            >
              {item.isDeleted ? "Restore" : "Delete"}
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
}

