import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Alert from '@mui/material/Alert';
import makeStyles from '@mui/styles/makeStyles';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useForm } from "react-hook-form";
import { green } from "@mui/material/colors";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { useDocumentData } from "react-firebase-hooks/firestore";
import Input from "../../components/Input";
import {itemRef, updateItemFields} from "../../data/item";

const useStyles = makeStyles(theme => ({
  resultAlert: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2)
    }
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative"
  }
}));

const LinkItem = ({ itemId }) => {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const refItem = itemRef(itemId);

  const [item, loading] = useDocumentData(refItem);

  const [submit, setSubmit] = useState({
    result: 0,
    message: ""
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = data => {
    setSubmit({
      result: 1,
      message: "Loading"
    });
    console.log(data);

    const payload = {
      dynamicLinkInfo: {
        domainUriPrefix: "https://snapscenear.page.link",
        link: "https://snapscene.app/item/" + itemId,
        iosInfo: {
          iosBundleId: "com.Snapscene.SnapsceneARSW",
          iosAppStoreId: "1481912483",
          iosFallbackLink: "https://snapscene.sg/"
        },
        androidInfo: {
          androidPackageName: "com.Snapscene.SnapsceneAR",
        },
        socialMetaTagInfo: {
          socialTitle: data.title,
          socialDescription: data.description,
          socialImageLink: data.imageDyanmicLink
        }
      }
    };

    axios
      .post(
        "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyAQH_HiLh6sS5VDeq5m9FUqFrjIztSMj1Q",
        payload
      )
      .then(async(response) => {
        console.log(response);
        if (response.status === 200) {
          const link = response.data.shortLink;
          //Update in database
          try{
            await updateItemFields(itemId, {
              dynamicLink: link
            });
            setSubmit({
                result: 3,
                message: "Link created"
              });
              setOpen(false);
          } catch (error){
            setSubmit({
                result: 2,
                message: error.message
              });
          }
    
        } else {
          setSubmit({
            result: 2,
            message: response.statusText
          });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  const { handleSubmit, control } = useForm({
    mode: "onBlur"
  });

  return (
    <div>
      {loading ? (
        <CircularProgress size={24} className={classes.buttonProgress} />
      ) : (
        <>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={handleClickOpen}
          >
            {item.dynamicLink === undefined
              ? "Create Dynamic Link"
              : "Regenerate Dynamic Link"}
          </Button>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            {" "}
            <form onSubmit={handleSubmit(onSubmit)}>
              <DialogTitle id="form-dialog-title">
                Create your Dynamic link
              </DialogTitle>
              <DialogContent>
                <DialogContentText>For later</DialogContentText>
                {submit.result > 1 && (
                  <div className={classes.resultAlert}>
                    <Alert severity={submit.result === 3 ? "success" : "error"}>
                      {submit.message}
                    </Alert>
                  </div>
                )}
                <Input name="title" label="Title" control={control}  defaultValue={item.Name} />
                <Input name="imageDyanmicLink" label="Image" control={control}  defaultValue={item.imageAR} />
                <Input name="description" label="Description" control={control}  defaultValue={item.Name} autoFocus
                  multiline
                  rows={4}/>
                {/* // <Controller
                //   as={TextField}
                //   autoFocus
                //   margin="dense"
                //   id="title"
                //   label="Title"
                //   fullWidth
                //   name="title"
                //   control={control}
                //   variant="outlined"
                //   defaultValue={item.Name}
                // /> 
                <Controller
                  as={TextField}
                  margin="dense"
                  id="imageDyanmicLink"
                  label="Image"
                  fullWidth
                  name="imageDyanmicLink"
                  control={control}
                  variant="outlined"
                  defaultValue={item.imageAR}
                />
                <Controller
                  as={TextField}
                  autoFocus
                  multiline
                  rows={4}
                  margin="dense"
                  id=""
                  label="Desciption"
                  fullWidth
                  name="description"
                  control={control}
                  variant="outlined"
                  defaultValue={item.Description}
                /> */}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <div className={classes.wrapper}>
                  <Button
                    color="primary"
                    type="submit"
                    disabled={submit.result === 1}
                  >
                    Create Link
                  </Button>
                  {submit.result === 1 && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </div>
              </DialogActions>
            </form>
          </Dialog>
        </>
      )}
    </div>
  );
};

export default LinkItem;

LinkItem.propTypes = {
  itemId: PropTypes.string.isRequired
};
