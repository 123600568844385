import React from "react";
import { getStorage, ref } from 'firebase/storage';
import { useUploadFile } from 'react-firebase-hooks/storage'
import PropTypes from "prop-types";
import {firebaseApp} from "../data/config";

export default function UploadFile(props) {
  const [uploadFile, /*uploading,, snapshot,  error */ ] = useUploadFile();
  const storage = getStorage(firebaseApp);

  const { fileUrl, storePath, fileName, multiple, enqueueSnackbar } = props;

  const multipleFile = multiple ? {multiple: "multiple"} : {};

  const upload = async (selectedFile) => {
    try{
      enqueueSnackbar("Uploading new file..", {
        variant: "info"
      });
      const extension = /(?:\.([^.]+))?$/.exec(selectedFile.name);
      const name = fileName ? fileName + extension[0] : selectedFile.name
      const refImage = ref(storage, storePath + '/' + name);
      const result = await uploadFile(refImage, selectedFile, {
        contentType: selectedFile.type
      });
      return fileUrl && fileUrl(result.metadata.name, fileName);

    }
    catch(error){
      enqueueSnackbar("Error when uploading file.", {
      variant: "error"
    });
      enqueueSnackbar(JSON.stringify(error), {
      variant: "error"
    });

    }
  }


  return (

    <input
          type="file"
          style={{ display: 'none' }}
          {...multipleFile}
          onChange={(e) => {
          const files = e.target.files ? e.target.files : {};
          Object.entries(files).forEach(([, file]) => {
              upload(file);
            });
           
          }}
    />
  );
}

UploadFile.propTypes = {
  fileUrl: PropTypes.func.isRequired,
  storePath: PropTypes.string.isRequired,
  fileName: PropTypes.string,
  multiple: PropTypes.bool,
  enqueueSnackbar: PropTypes.func.isRequired
};
