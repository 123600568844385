import { getFirestore, doc, collection, query, where, updateDoc, getDocs, serverTimestamp } from 'firebase/firestore';
import { getAuth } from "firebase/auth";
import {firebaseApp} from "./config";
import _ from "lodash";
import { colorToHex } from "../views/StaticData/Colour/Function-Colour";
import {retailerRef} from "./retailer";


export const itemRef = (ref) =>{
    const fireapp = firebaseApp;
    return doc(getFirestore(fireapp), 'Item', ref).withConverter(itemConverter)
  }
  
  export const itemsCollection = () => {
    const fireapp = firebaseApp;
   return collection(getFirestore(fireapp), 'Item').withConverter(itemConverter)
  }
 
  export const itemsActive = () => {
   return query(itemsCollection(), where("isDeleted", "==", false)) 
  }
 
  export const itemsActiveRetailer = (reftailerRef) => {
   return query(itemsCollection(), where("isDeleted", "==", false), where("RetailerId", "==", reftailerRef)) 
  }
 
  export const itemsAllRetailer = (reftailerRef) => {
   return query(itemsCollection(), where("RetailerId", "==", reftailerRef)) 
  }
 
  export const itemsAllStaticData = (staticDatatype, staticDatReference) => {
   return query(itemsCollection(), where(staticDatatype, "==", staticDatReference)) 
  }
 
  export const modelUsed = async(filetype, modelPath,) => {
    const field = filetype === "GLB" ? "ModelPathGLB" : "ModelPath";
    const q = query(itemsCollection(), where(field, "==", modelPath)) 
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty; 
  }

  export const itemConverter = {
   toFirestore: (item) => {
 
     const Size = {
       Width: Number(item.Width) || 0,
       Height: Number(item.Height) || 0,
       Depth: Number(item.Depth) || 0
     };
 
     const auth = getAuth(firebaseApp);
     const lastUser = auth.currentUser.displayName;
 
     let RetailerRef = null;
     let RetailerName = null;
     let RetailerId = null;
     if (item.retailerId instanceof Object) {
       RetailerName = item.retailerId.Name;
       RetailerId = item.retailerId.id;
       RetailerRef = retailerRef(RetailerId);
     }
     // const primaryColor = {};
     // primaryColor.hsv = _.has(data.primaryColor, "hsv")
     //   ? data.primaryColor.hsv
     //   : item.primaryColor.hsv;
     // primaryColor.rgb = _.has(data.primaryColor, "rgb")
     //   ? data.primaryColor.rgb
     //   : item.primaryColor.rgb;
     // const secondaryColor = {};
     // secondaryColor.hsv = _.has(data.secondaryColor, "hsv")
     //   ? data.secondaryColor.hsv
     //   : item.secondaryColor.hsv;
     // secondaryColor.rgb = _.has(data.secondaryColor, "rgb")
     //   ? data.secondaryColor.rgb
     //   : item.secondaryColor.rgb;
 
     return { 
       Name: item.Name,
       Brand: item.Brand || "",
       Designer: item.Designer || "",
       Category: item.Category,
       Description: _.trim(item.Description),
       Alignment: parseInt(item.Alignment, 10),
       Size: Size,
       Price: item.Price || 0,
       //Colours: data.Colours || [],
       primaryColor: item.primaryColor,
       secondaryColor: item.secondaryColor,
       Style: item.Style,
       StartDisplay: item.StartDisplay,
       StopDisplay: item.StopDisplay,
       ModelPath: item.ModelPath,
       ModelPathGLB: item.ModelPathGLB,
       RetailerRef: RetailerRef,
       RetailerName: RetailerName,
       RetailerId: RetailerId,
       imagesItem: item.imagesItem, //imagesItem || [],
       urlItemRetailer: item.urlItemRetailer || "",
       lastModifed: serverTimestamp(),
       lastUser: lastUser,
       isDeleted: false
 
   };
   },
   fromFirestore: (snapshot, options ) => {
     const data = snapshot.data(options);
     if (data.primaryColor) {
       data.primaryColor.hex = colorToHex(data.primaryColor);
           } else {
             data.primaryColor = {};
             data.primaryColor.hex = "";
             data.primaryColor.rgb = {};
             data.primaryColor.hsv = {};
           }
           if (data.secondaryColor) {
             data.secondaryColor.hex = colorToHex(data.secondaryColor);
           } else {
             data.secondaryColor = {};
             data.secondaryColor.hex = "";
             data.secondaryColor.rgb = {};
             data.secondaryColor.hsv = {};
           }
     return {
       key: snapshot.id,
       Name: data.Name, 
       imageAR: data.imageAR || "",
       RetailerName: data.RetailerName,
       RetailerId: data.RetailerId,
       urlItemRetailer: data.urlItemRetailer || "",
       Price: parseInt(data.Price || 0),
       Category: data.Category,
       Style: data.Style,
       Brand: data.Brand || "",
       Designer: data.Designer || "",
       isDeleted: data.isDeleted,
       lastUser: data.lastUser,
       lastModifed: data.lastModifed,
       lastModifedString: data.lastModifed instanceof Object ? data.lastModifed.toDate().toString()  : "",
       StartDisplay: data.StartDisplay instanceof Object ? data.StartDisplay.toDate()  : null,
       StopDisplay: data.StopDisplay instanceof Object ? data.StopDisplay.toDate()  : null,
       StartDisplayString: data.StartDisplay instanceof Object ? data.StartDisplay.toDate().toString()  : "",
       StopDisplayString: data.StopDisplay instanceof Object ? data.StopDisplay.toDate().toString()  : "",
       imagesItem: data.imagesItem ? data.imagesItem : [],
       Description: data.Description,
       Size: data.Size ? data.Size : [],
       Height: data.Size ? data.Size["Height"] : 0,
       Depth: data.Size ? data.Size["Depth"] : 0,
       Width: data.Size ? data.Size["Width"] : 0,
       primaryColor: data.primaryColor,
       secondaryColor: data.secondaryColor,
       dynamicLink: data.dynamicLink,
       ModelPath: data.ModelPath,
       ModelPathGLB: data.ModelPathGLB,
       Alignment: data.Alignment,
 
 
     };
   }
 };
 
 export const updateItemFields = async(itemId, fields) => {
   const refItem = itemRef(itemId);
   await updateDoc(refItem, fields);
   const auth = getAuth();
   const user = auth.currentUser;
   await updateDoc(refItem,
     {
     lastModifed: serverTimestamp(),
     lastUser: user.displayName !== null ? user.displayName : user.email
   });
 }

 // Analytics
 export const itemAnalyticsMonth = (ref, year, month) =>{
  const fireapp = firebaseApp;
  return doc(getFirestore(fireapp), 'Item', ref, 'Analytics', 'Analytics', year, month);
}

export const itemAnalyticsYear = (ref, year) =>{
  const fireapp = firebaseApp;
  return collection(getFirestore(fireapp), 'Item', ref, 'Analytics', 'Analytics', year);
}
