import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from "@mui/material/CircularProgress";
import { useController } from "react-hook-form";
import PropTypes from "prop-types";
import { retailersActive } from "../../data/retailer"
import { getDocs } from "firebase/firestore";

export default function AutocompleteRetailer({ control, name }) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;
  
  /* eslint-disable no-unused-vars */
  const {
    field: { onChange, onBlur, nameInput, value, ref },
    fieldState: { invalid, isTouched, isDirty, error },
    formState: { touchedFields, dirtyFields }
  } = useController({
    name,
    control,
    rules: { required: true },
    defaultValue: "",
  });

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const obj = [];
      let filteredRetailers = retailersActive();
      const response = await getDocs(filteredRetailers);
      response.forEach(function(doc) {
        const r = doc.data();
        r.id = doc.id;
        obj.push(r);
      });
      if (active) {
        setOptions(obj);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (

        <Autocomplete
          id="asynchronous-retailer"
          style={{ width: 300 }}
          value={value}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          isOptionEqualToValue={(option, value) => option.key === value.key}
          getOptionLabel={option => (option === "" ? option : option.Name)}
          options={options}
          loading={loading}
          renderInput={params => (
            <TextField
              {...params}
              label="Retailer"
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                )
              }}
            />
          )}
          onChange={(_, data) => onChange(data)}
          onBlur={onBlur}
          name={nameInput}
        />
  );
}

AutocompleteRetailer.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired
};
