import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CircularProgress,
  Divider,
  Button,
} from "@mui/material";
import { green } from "@mui/material/colors";
import Alert from '@mui/material/Alert';
import { useForm } from "react-hook-form";
import { passwordSchema } from "./Password-validation";
import Input from "../../../components/Input"
import { getAuth, updatePassword } from "firebase/auth";

const useStyles = makeStyles(theme => ({
  resultAlert: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2)
    }
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative"
  }
}));

const Password = () => {

  const classes = useStyles();

  const [submit, setSubmit] = useState({
    result: 0,
    message: ""
  });

  const onSubmit = data => {
    if (data.password === data.confirmPassword) {
      setSubmit({
        result: 1,
        message: "Loading"
      });
      const auth = getAuth();
      const user = auth.currentUser;
      updatePassword(user, data.password).then(() => {
          setSubmit({
            result: 3,
            message: "Password updated"
          });
        })
        .catch(error => {
          setSubmit({
            result: 2,
            message: error.message
          });
        });
    } else {
      setSubmit({
        result: 2,
        message: "Password not matching"
      });
    }
  };

  const { handleSubmit, control } = useForm({
    mode: "onBlur",
    validationSchema: passwordSchema,
    defaultValues: { password: "", confirmPassword: "" }
  });

  return (
    <>
      {submit.result > 1 && (
        <div className={classes.resultAlert}>
          <Alert severity={submit.result === 3 ? "success" : "error"}>
            {submit.message}
          </Alert>
        </div>
      )}
      <Card className={classes.root}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CardHeader
            subheader="Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
            title="Password"
          />
          <Divider />
          <CardContent>
          <Input name="password" label="Password" type="password" fullWidth control={control} />
          <Input name="confirmPassword" label="Confirm Password" type="password" fullWidth control={control} />
          </CardContent>
          <Divider />
          <CardActions>
            <div className={classes.wrapper}>
              <Button
                color="primary"
                variant="outlined"
                type="submit"
                disabled={submit.result === 1}
              >
                Update Password
              </Button>
              {submit.result === 1 && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </CardActions>
        </form>
      </Card>
    </>
  );
};

export default Password;
