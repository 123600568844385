import { getAuth, signOut } from "firebase/auth";
import {firebaseApp} from "./config";

export const userLogout = () => {
    const auth = getAuth(firebaseApp);
    signOut(auth).then(() => {
        console.log("Logout")
        return true;
    }).catch((error) => {
        return error;
    });
  
  }

  export const getCurrentUser = () => {
    const auth = getAuth(firebaseApp);
    const user = auth.currentUser;
    return user !== null ? true: false;
  }

