import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useForm } from "react-hook-form";
import { retailerSchema } from "./Retailer-validation";
import Avatar from "@mui/material/Avatar";
import Paper from "@mui/material/Paper";
import UploadFile from "../../components/Upload-File";
import { LinkRef } from "../../components/design/button-link";
import { useSnackbar } from "notistack";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CardHeader from "@mui/material/CardHeader";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import { yupResolver } from '@hookform/resolvers/yup';
import Input from "../../components/Input";
import { createDocument } from "../../data/api";
import { getFirestore, doc, collection, updateDoc } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { retailerRef } from "../../data/retailer";
import {firebaseApp} from "../../data/config";

function RetailerFieldForm(props) {
  const { nextStep } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, control, register } = useForm({
    mode: "onBlur",
    resolver: yupResolver(retailerSchema),
    defaultValues: {}
  });
  const onSubmit = async(data) => {
    console.log(data);
    data.imageLogo= "";
    data.imageBanner= "";
    const retailerCollection = collection(getFirestore(firebaseApp), 'Retailer')
    const newRetailer = doc(retailerCollection)
    await createDocument(newRetailer,data,enqueueSnackbar);
    const newRetailerId = newRetailer.id;
    return nextStep(newRetailerId);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader
              title="Retailer info"
              subheader="Name and short description"
            />
            <CardContent>
              <Input name="Name" label="Retailer Name" control={control} />
              <Input name="Description" label="Retailer Description" control={control} multiline rows="10" />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader
              title="Contact card"
              subheader="Mail, phone, address..."
            />
            <CardContent>
              <Input name="Country" label="Country" control={control} />
              <Input name="Email" label="Email" control={control} type="email" />
              <Input name="Address" label="Retailer Address" control={control} multiline rows="3" />
              <Input name="Contact" label="Retailer Phone Number" control={control} />
              <Input name="whatsapp" label="Whatsapp number" control={control} />
              <Input name="Website" label="Retailer Website" control={control} type="url" />
                  <RadioGroup>
                    <FormControlLabel
                      value="hide"
                      control={<Radio color="primary" />}
                      {...register("display")}
                      label="Hide"
                    />
                    <FormControlLabel
                      value="show"
                      control={<Radio color="primary" />}
                      {...register("display")}
                      label="Show"
                    />
                    <FormControlLabel
                      value="showAdminOnly"
                      {...register("display")}
                      control={<Radio color="primary" />}
                      label="Show to admin profile only"
                    />
                  </RadioGroup>
            </CardContent>
            <CardActions disableSpacing>
              <Button
                variant="contained"
                size="medium"
                color="primary"
                type="submit"
              >
                Save and go next
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </form>
  );
}

RetailerFieldForm.propTypes = {
  nextStep: PropTypes.func.isRequired
};

function RetailerImageForm(props) {
  const { nextStep, retailerId } = props;
  const [imageLogo, setimageLogo] = useState("");
  const [imageBanner, setimageBanner] = useState("");
  const retailerImageStorage = "Retailer/" + retailerId + "/Image/";
  const { enqueueSnackbar } = useSnackbar();
  const [isImageUploading, setImageUploading] = useState(false);
  const storage = getStorage(firebaseApp);

  const handleUploadStart = () => {
    setImageUploading(true);
  };

  const handleUploadSuccess = async(uploadedFilename, filename) => {
    const url = await getDownloadURL(ref(storage, retailerImageStorage + '/' + uploadedFilename));
    filename === "logo" ? setimageLogo(url) : setimageBanner(url)
    setImageUploading(false);
  };

  const handleSave = async() => {
    const refRetailer = retailerRef(retailerId);
    await updateDoc(refRetailer, {
      LogoImagePath: imageLogo,
      BannerImagePath: imageBanner
    });
    return nextStep();
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={3}>
        <Card>
          <CardHeader title="Logo" subheader="Upload Retailer's logo" />
          {imageLogo && <CardMedia image={imageLogo} title={imageLogo} />}
          <CardContent>
            <Typography>Avatar preview</Typography>
            <Avatar sx={{ width: 110, height: 110 }} src={imageLogo} />
          </CardContent>
          <CardActions>
            <Button
              size="small"
              color="primary"
              component="label"
              onClick={handleUploadStart}
            >
              <UploadFile
                fileUrl={handleUploadSuccess}
                storePath={retailerImageStorage}
                fileName={"logo"}
                enqueueSnackbar={enqueueSnackbar}
              />
              Upload Icon image
            </Button>
          </CardActions>
        </Card>
      </Grid>

      <Grid item xs={12} md={9}>
        <Card>
          <CardHeader title="Banner" subheader="Upload Retailer's Banner" />
          {imageBanner && <CardMedia image={imageBanner} title={imageBanner} />}
          <CardContent>
            <Typography>Banner preview</Typography>
            <img src={imageBanner} alt={imageBanner} />
          </CardContent>
          <CardActions>
            <Button
              size="small"
              color="primary"
              component="label"
              onClick={handleUploadStart}
            >
              <UploadFile
                fileUrl={handleUploadSuccess}
                storePath={retailerImageStorage}
                fileName={"banner"}
                enqueueSnackbar={enqueueSnackbar}
              />
              Upload Banner image
            </Button>
          </CardActions>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          size="medium"
          color="primary"
          onClick={handleSave}
          disabled={isImageUploading}
        >
          Save Images and go next
        </Button>
      </Grid>
    </Grid>
  );
}
RetailerImageForm.propTypes = {
  nextStep: PropTypes.func.isRequired,
  retailerId: PropTypes.string.isRequired
};

function RetailerOtherForm(props) {
  const { retailerId } = props;
  return (
    <Paper>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          Review retail:
          <Button
            variant="outlined"
            size="medium"
            color="primary"
            sx={{ m: 1 }}
            component={LinkRef}
            to={`/show-retailer/${retailerId}`}
          >
            SEE RETAILER
          </Button>
        </Grid>
        <Grid item xs={12} md={4}>
          List models:
          <Button
            variant="outlined"
            size="medium"
            color="primary"
            sx={{ m: 1 }}
            component={LinkRef}
            to="/list-model"
          >
            Model list
          </Button>
        </Grid>
        <Grid item xs={12} md={4}>
          Add items:{" "}
          <Button
            variant="outlined"
            size="medium"
            color="primary"
            sx={{ m: 1 }}
            component={LinkRef}
            to="/create-item"
          >
            Add Item
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}
RetailerOtherForm.propTypes = {
  retailerId: PropTypes.string.isRequired
};

function RetailerStepper() {
  const [activeStep, setActiveStep] = useState(0);
  const [retailerId, setRetailerId] = useState("");
  const steps = [
    "Create a new retailer",
    "Select logo and banner",
    "Next steps"
  ];

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <RetailerFieldForm nextStep={goStepTwo} />;
      case 1:
        return <RetailerImageForm nextStep={goStepThree} retailerId={retailerId} />
      case 2:
        return <RetailerOtherForm retailerId={retailerId} />;
      default:
        return "Unknown step";
    }
  }

  const goStepThree = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const goStepTwo = id => {
    setRetailerId(id);
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleRetailer = () => {
    setActiveStep(0);
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={index} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Grid>
      <Grid item xs={12}>
        {activeStep === steps.length ? (
          <div>
            <Typography>
              Retailer added to the back-end - you&apos;re finished
            </Typography>
            <Button onClick={handleRetailer} >
              View retailer profile
            </Button>
          </div>
        ) : (
          getStepContent(activeStep)
        )}
      </Grid>
    </Grid>
  );
}

export default function CreateRetailer() {
  return (
      <Grid container sx={{ mt: 3 }}>
        <Grid item xs={12}>
          <RetailerStepper />
        </Grid>
      </Grid>
  );
}
