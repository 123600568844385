import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles, useTheme } from "@mui/styles";
import { useMediaQuery } from "@mui/material";
import { Outlet } from "react-router-dom";
import { Sidebar, Topbar, Footer } from "./components";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 56,
    height: "100%",
    [theme.breakpoints.up("sm")]: {
      paddingTop: 64
    }
  },
  shiftContent: {
    paddingLeft: 240
  },
  content: {
    height: "100%"
  }
}));

export default function SnapRetailer({ title, retailerId }) {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true
  });

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const shouldOpenSidebar = isDesktop ? true : openSidebar;

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop
      })}
    >
      <Topbar onSidebarOpen={handleSidebarOpen} />
      <div>{title}</div>
      <Sidebar
        onClose={handleSidebarClose}
        open={shouldOpenSidebar}
        retailerId={retailerId}
        variant={isDesktop ? "persistent" : "temporary"}
      />
      <main className={classes.content}>
        <Outlet />
        <Footer />
      </main>
    </div>
  );
}

SnapRetailer.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  retailerId: PropTypes.string
};
