import React, { useState } from "react";
import { getAuth } from "firebase/auth";
import {signInWithEmailAndPassword} from 'firebase/auth'
import { Navigate } from "react-router-dom";
import _ from "lodash";
import Input from "../../components/Input";
// Material components
import {
  Grid,
  Button,
  CircularProgress,
  Typography
} from "@mui/material";
import {getCurrentUser} from "../../data/auth"
import {firebaseApp} from "../../data/config";
// Component styles
import { useLoginStyles } from "./styles";

// Form validation schema
import { loginSchema } from "./Login-validation";
import { useForm } from "react-hook-form";

export default function Login() {
  const classes = useLoginStyles();
  const auth = getAuth(firebaseApp);
  const [isLoading, setLoading] = useState(false);
  const [submitError, setSubmitError] = useState();
  const [isAuth, setAuth] = useState(getCurrentUser() ? true : false);

  const signIn = (email, password) => {
    // signIn an existing user with email and password
    signInWithEmailAndPassword(auth, email, password).then( () => {
      setAuth(true)
    })
    .catch(error => {
      setLoading(false);
      setSubmitError(error);
      console.log("Return from back-end login:" + error);
    }
    )
  }

  const onSubmit = async data => {
    try {
      setLoading(true);
      await signIn(data.email, data.password);
      setLoading(false);
      setAuth(getCurrentUser() ? true : false);
    } catch (error) {
      setLoading(false);
      setSubmitError(error);
      console.log("Return from back-end login:" + error);
    }
  };

  const { handleSubmit, control, errors } = useForm({
    mode: "onBlur",
    validationSchema: loginSchema,
    defaultValues: { login: "", password: "" }
  });

  return isAuth ? (
    <Navigate push to="/" from="/login" />
  ) : (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.quoteWrapper} item lg={5}>
          <div className={classes.quote}>
            <div className={classes.quoteInner}>
              <Typography className={classes.quoteText} variant="h3">
                Snapscene Admin
              </Typography>
              <div className={classes.person}>
                <Typography className={classes.name} variant="body1">
                  Allow you to manage Snapscene platform
                </Typography>
                <Typography className={classes.bio} variant="body2">
                  Maitained by the Snapscene team
                </Typography>
              </div>
            </div>
          </div>
        </Grid>
        <Grid className={classes.content} item lg={7} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentHeader}></div>
            <div className={classes.contentBody}>
              <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
                <Typography className={classes.title} variant="h2">
                  Sign in with your email address
                </Typography>
                <div className={classes.fields}>
                  <Input name="email" label="Email address" control={control} />
                  <Input name="password" label="Password" control={control} type="password" />
                </div>

                {submitError && (
                  <Typography className={classes.submitError} variant="body2">
                    {submitError.message}
                  </Typography>
                )}
                {isLoading ? (
                  <CircularProgress className={classes.progress} />
                ) : (
                  <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={_.isEmpty(errors) ? false : true}
                    type="submit"
                    size="large"
                    variant="contained"
                  >
                    Sign in now
                  </Button>
                )}
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
