import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Button from "@mui/material/Button";
import makeStyles from '@mui/styles/makeStyles';
import { categorySchema } from "../StaticData-validation";
import Avatar from "@mui/material/Avatar";
import { useSnackbar } from "notistack";
import UploadFile from "../../../components/Upload-File";
import { useNavigate } from "react-router-dom";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import _ from "lodash";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import SaveIcon from "@mui/icons-material/Save";
import TableItemsStaticData from "../../Item/Table-item-staticData";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Input from "../../../components/Input";
import { useParams } from "react-router-dom";
import { updateFieldArrayUnion, updateFieldArrayRemove } from "../../../data/api";
import { getStaticData } from "../../../data/static";
import { staticDataStorage } from "../../../data/storage";
import { getDownloadURL } from "firebase/storage";
import { useDocumentData } from "react-firebase-hooks/firestore";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3)
  },
  avatar: {
    marginLeft: "auto",
    height: "110px",
    width: "110px",
    flexShrink: 0,
    flexGrow: 0,
    backgroundColor: theme.palette.primary.main
  }
}));

export default function EditCategory() {
  const classes = useStyles();
  const [category, setCategory] = useState({
    value: "",
    label: "",
    //display: true,
    displayCategory: "hide",
    imagePath: "",
    order: 0
  });
  // Define if the form is for edit a new entry
  const [newEntry, setNewEntry] = useState(false);

  const [isImageUploading, setImageUploading] = useState(false);
  const [image, setimage] = useState("");
  let navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const urlParams = useParams();
  const categoryID = urlParams.id

  const { handleSubmit, reset, control } = useForm({
    validationSchema: categorySchema,
    mode: "onBlur",
    defaultValues: {}
  });

  const [ staticDataDocument, loading, ] = useDocumentData(getStaticData());

  useEffect(() => {
    function getCategories() { 
        const { Categories } = staticDataDocument;
        const cat = Categories.filter(function(c) {
          return c.value === categoryID;
        });
        if (cat.length > 0) {
          setNewEntry(false);
          setCategory(cat[0]);
          reset(cat[0]);
          setimage(cat[0].imagePath);
        }
      }
      !loading && getCategories();
  }, [reset, staticDataDocument, loading,  categoryID]);

  useEffect(() => {
    if (categoryID === "new_entry") {
      setNewEntry(true);
      reset(category);
    }
  }, [categoryID, category, reset]);

  const onSubmit = async(data) => {
    // Adding imagePath to data
    data.imagePath = image;
    if (_.isEqual(data, category)) return;

    const submittedArray = {
      order: parseInt(data.order || 0),
      label: data.label,
      value: data.value || data.label.replace(/\s+/g, ''),
      imagePath: data.imagePath,
      //display: data.display,
      displayCategory: data.displayCategory
    }

    await updateFieldArrayUnion(getStaticData(), "Categories", submittedArray, enqueueSnackbar);
    if (newEntry != true) {
      const previousArray = {
        order: parseInt(category.order || 0),
        label: category.label,
        value: category.value,
        imagePath: category.imagePath,
        //display: category.display || false,
        displayCategory: category.displayCategory || "hide"
      }
      await updateFieldArrayRemove(getStaticData(), "Categories", previousArray, enqueueSnackbar);
    }
    navigate("/list-staticdata/");

 
  };

  const handleUploadSuccess = async(uploadedFilename, ) => {
    const file = staticDataStorage("Categories", uploadedFilename);
    const url = await getDownloadURL(file)
    setimage(url);
    setImageUploading(false);
  };

  const handleUploadStart = () => {
    setImageUploading(true);
  };

  return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={9}>
            <Card>
              <CardHeader
                title={
                  newEntry ? "New Category " : "Category: " + category.label
                }
                subheader="Update category information"
              />
              <Divider />
              <CardContent>
              <Input name="label" label="Category Name" control={control} />
              <Input name="value" label="Value" control={control} disabled={!newEntry} />
              <Input name="order" label="order" control={control} />
                <Controller
                  render={({ field }) => (
                    <RadioGroup {...field}>
                      <FormControlLabel
                        value="hide"
                        control={<Radio color="primary" />}
                        label="Hide"
                      />
                      <FormControlLabel
                        value="show"
                        control={<Radio color="primary" />}
                        label="Show"
                      />
                      <FormControlLabel
                        value="showAdminOnly"
                        control={<Radio color="primary" />}
                        label="Show to admin profile only"
                      />
                    </RadioGroup>
                  )}
                  name="displayCategory"
                  control={control}
                  defaultValue={0}
                />
              </CardContent>
              <Divider />
              <CardActions>
                <Button
                  size="small"
                  color="primary"
                  type="submit"
                  startIcon={<SaveIcon />}
                  disabled={isImageUploading}
                >
                  Save details
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card>
              <CardHeader
                title="Category Image"
                subheader="Upload/Replace the logo"
              />
              <Divider />
              <CardContent>
                <Avatar className={classes.avatar} src={image} />
              </CardContent>
              <Divider />
              <CardActions disableSpacing>
                <Button
                  size="small"
                  color="primary"
                  component="label"
                  onClick={handleUploadStart}
                  startIcon={<CloudUploadIcon />}
                >
                  <UploadFile
                    fileUrl={handleUploadSuccess}
                    storePath={"StaticData/Categories"}
                    fileName={category.value}
                    enqueueSnackbar={enqueueSnackbar}
                  />
                  Upload
                </Button>
              </CardActions>
            </Card>
          </Grid>

          {!newEntry && category.label && (
            <Grid item xs={12}>
              <Card>
                <CardHeader title="List of item(s) under this Category:" />
                <Divider />
                <CardContent>
                  <TableItemsStaticData
                    staticData="Category"
                    staticDataValue={category.label}
                  />
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      </form>
  );
}
