import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import Password from "./Password/Password";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Settings = () => {
  const classes = useStyles();

  return (
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item md={7} xs={12}>
            <Password />
          </Grid>
        </Grid>
      </div>
  );
};

export default Settings;

