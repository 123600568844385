import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Alert from '@mui/material/Alert';
import { useDocumentData } from "react-firebase-hooks/firestore";
import makeStyles from '@mui/styles/makeStyles';
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CardHeader from "@mui/material/CardHeader";
import Link from "@mui/material/Link";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PublicIcon from "@mui/icons-material/Public";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import BusinessIcon from "@mui/icons-material/Business";
import LinkIcon from "@mui/icons-material/Link";
import Divider from "@mui/material/Divider";
import FromDescription from "./Profile/Profile-Description";
import FromContact from "./Profile/Profile-Contact";
import { retailerRef } from "../../data/retailer";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    padding: theme.spacing(4)
  },
  countryText: {
    marginTop: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  avatar: {
    marginLeft: "auto",
    height: "110px",
    width: "110px",
    flexShrink: 0,
    flexGrow: 0
  },
  banner: {
    height: "200px"
  }
}));

function RetailerDetails({ retailerId }) {
  const classes = useStyles();

  const refRetailer = retailerRef(retailerId);
  const [retailer, loading, error] = useDocumentData(refRetailer);

  if (loading) return <Typography>Loading Profile</Typography>;

  if (!loading && error)
    return <Alert severity="warning">Error loading retailer: {error}</Alert>;

  if (!retailer)
    return (
      <Alert severity="warning">
        Error retailer profile {retailerId} not found
      </Alert>
    );

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            titleTypographyProps={{ variant: "h5" }}
            title="Banner"
            subheader="Contact us if you would like to change it"
          />
          <CardActionArea>
            <CardMedia
              className={classes.banner}
              image={retailer.BannerImagePath}
              title={retailer.Name}
            />
          </CardActionArea>
        </Card>
      </Grid>

      <Grid item xs={12} md={12}>
        <Card>
          <CardHeader
            avatar={
              <Avatar
                aria-label="logo"
                className={classes.avatar}
                src={retailer.LogoImagePath}
              >
                No Logo
              </Avatar>
            }
            titleTypographyProps={{ variant: "h3" }}
            title={retailer.Name}
            subheader={
              "Please contact us if you need to change your name or logo"
            }
          />
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p">
              {retailer.Description}
            </Typography>
          </CardContent>
          <Divider />
          <CardActions disableSpacing>
            <Grid container justifyContent="space-between">
              <Grid item>
                <FromDescription
                  retailerId={retailerId}
                  description={retailer.Description}
                />
              </Grid>{" "}
            </Grid>
          </CardActions>
        </Card>
      </Grid>
      <Grid item xs={12} md={12}>
        <Card>
          <CardHeader title="Contact information" />
          <CardContent>
            <Typography variant="body2" component="p">
              <PublicIcon />
              {retailer.Country}
            </Typography>
            <Typography variant="body2" component="p">
              <PhoneIphoneIcon />
              {retailer.Contact}
            </Typography>
            <Typography variant="body2" component="p">
              <WhatsAppIcon />
              {retailer.whatsapp}
            </Typography>
            <Typography variant="body2" component="p">
              <MailOutlineIcon />
              {retailer.Email}
            </Typography>
            <Typography variant="body2" component="p">
              <BusinessIcon /> {retailer.Address}
            </Typography>
            <Typography variant="body2" component="p">
              <LinkIcon />
              {retailer.Website ? (
                <Link href={retailer.Website}>{retailer.Website}</Link>
              ) : (
                "No link defined"
              )}
            </Typography>
          </CardContent>
          <Divider />
          <CardActions>
            <FromContact
              retailerId={retailerId}
              contact={{
                Contact: retailer.Contact,
                whatsapp: retailer.whatsapp,
                Email: retailer.Email,
                Address: retailer.Address,
                Website: retailer.Website
              }}
            />
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
}

RetailerDetails.propTypes = {
  retailerId: PropTypes.string.isRequired
};

const ProfileRetailer = ({ retailerId }) => {
  const classes = useStyles();

  return (
      <div className={classes.root}>
        <RetailerDetails retailerId={retailerId} />
      </div>
  );
};

export default ProfileRetailer;

ProfileRetailer.propTypes = {
  retailerId: PropTypes.string
};
