import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { AppBar, Toolbar, Badge, Hidden, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/NotificationsOutlined";
import InputIcon from "@mui/icons-material/Input";
import {userLogout} from "../../../../data/auth";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: "none"
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  toolbarLogo: {
    height: 58
  }
}));

const Topbar = props => {
  const { className, onSidebarOpen, ...rest } = props;
  let navigate = useNavigate();

  const classes = useStyles();

  async function logout() {
    await userLogout();
    navigate("/");
  }

  const [notifications] = useState(["New quote received", "Another quote"]);

  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <RouterLink to="/">
          <img
            alt="Snapscene Logo"
            src="/images/SS_Horizontal_White.png"
            className={classes.toolbarLogo}
          />
        </RouterLink>
        <div className={classes.flexGrow} />
        {/* <Hidden xlDown> */}
          <IconButton color="inherit" size="large">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton
            className={classes.signOutButton}
            color="inherit"
            onClick={() => logout()}
            size="large">
            <InputIcon />
          </IconButton>
        {/* </Hidden> */}
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarOpen} size="large">
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
