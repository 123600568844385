import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Alert from '@mui/material/Alert';
import makeStyles from '@mui/styles/makeStyles';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useForm } from "react-hook-form";
import { green } from "@mui/material/colors";
import CircularProgress from "@mui/material/CircularProgress";
import _ from "lodash";
import Input from "../../../components/Input";
import { retailerRef } from "../../../data/retailer";
import { updateDoc } from 'firebase/firestore';

const useStyles = makeStyles(theme => ({
  resultAlert: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2)
    }
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative"
  }
}));

const FromDescription = ({ retailerId, description }) => {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const [submit, setSubmit] = useState({
    result: 0,
    message: ""
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async(data) => {
    setSubmit({
      result: 1,
      message: "Loading"
    });
    const refRetailer = retailerRef(retailerId);
    try{
      await updateDoc(refRetailer,
      {
          Description: _.trim(data.description)
      })
        setSubmit({
          result: 3,
          message: "Description updated"
        });
        setOpen(false);
      }
      catch(error) {
        setSubmit({
          result: 2,
          message: error.message
        });
      }
  };

  const { handleSubmit, control, setValue} = useForm({
    mode: "onBlur"
  });

  useEffect(() => {
    setValue("description", description)
}, [setValue, description]);

  return (
    <div>
      <Button color="primary" size="small" onClick={handleClickOpen}>
        Edit Description
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        {" "}
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id="form-dialog-title">
            Edit your profile description
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Your profile description is displayed on the Snapscene application
              profile page.
            </DialogContentText>
            {submit.result > 1 && (
              <div className={classes.resultAlert}>
                <Alert severity={submit.result === 3 ? "success" : "error"}>
                  {submit.message}
                </Alert>
              </div>
            )}
             <Input name="description" autoFocus label="Retailer Description" control={control} multiline rows={4} />

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <div className={classes.wrapper}>
              <Button color="primary" type="submit">
                Update description
              </Button>
              {submit.result === 1 && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default FromDescription;

FromDescription.propTypes = {
  retailerId: PropTypes.string.isRequired,
  description: PropTypes.string
};
