import React from "react";
import Typography from "@mui/material/Typography";
import Alert from '@mui/material/Alert';
import Link from "@mui/material/Link";
import {
  useDocumentData,
  useCollectionData
} from "react-firebase-hooks/firestore";
import makeStyles from '@mui/styles/makeStyles';
import Avatar from "@mui/material/Avatar";
import LinearProgress from "@mui/material/LinearProgress";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import { LinkRef } from "../../components/design/button-link";
import { useParams } from "react-router-dom";
import { retailerRef } from "../../data/retailer";
import { itemsActiveRetailer } from "../../data/item";

const useStyles = makeStyles(theme => ({
  content: {
    paddingTop: 56,
    height: "100%",
    [theme.breakpoints.up("sm")]: {
      paddingTop: 64
    }
  },
  card: {
    padding: theme.spacing(1),
    maxWidth: 800
    //color: theme.palette.text.primary,
  },
  gridItem: {
    textAlign: "center"
  },
  chip: {
    marginRight: theme.spacing(1)
  },
  avatar: {
    height: "50px",
    width: "50px",
    flexShrink: 0,
    flexGrow: 0
  },
  media: {
    height: 0,
    paddingTop: "56.25%" // 16:9
  },
  color: {
    width: "36px",
    height: "14px",
    borderRadius: "2px"
  },
  toolbarLogo: {
    height: 58
  },
  storeImage:{
    width: 150
  },
  cardMediaItem: ({ bgColor = "rgba(0, 0, 0, 0.08)" }) => ({
    width: "100%",
    paddingBottom: "56.25%",
    clipPath: "polygon(0 0, 100% 0%, 100% 84%, 0% 100%)",
    backgroundColor: bgColor
  }),
  cardItem: ({ inactive }) => ({
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    transition: "0.3s",
    ...(!inactive && {
      "&:hover": {
        boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)"
      }
    })
  })
}));

export default function PublicRetailer() {
  const classes = useStyles();
  const urlParams = useParams();
  const retailerId = urlParams.id;

  const refRetailer = retailerRef(retailerId);
  const [retailer, loading, error] = useDocumentData(refRetailer);

  let filteredItems = itemsActiveRetailer(retailerId);
  const [items, loadingItems, errorItems] = useCollectionData(filteredItems);

  return <>
    <AppBar position="static" color="primary">
      <Toolbar variant="dense">
        <Link href="https://www.snapscene.sg">
          <img
            alt="Snapscene Logo"
            src="/images/SS_Horizontal_White.png"
            className={classes.toolbarLogo}
          />
        </Link>
      </Toolbar>
    </AppBar>
    <main className={classes.content}>
      {error && (
        <Alert severity="warning">Error loading retailer: {error}</Alert>
      )}
      {loading && <LinearProgress />}
      {!loading && !retailer && (
        <Alert severity="warning">
          Error retailer {retailerId} not found
        </Alert>
      )}
      {!loading && retailer && (
        <Grid
          container
          spacing={1}
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Card className={classes.card}>
              <CardHeader
                avatar={
                  <Avatar
                    aria-label="logo"
                    className={classes.avatar}
                    src={retailer.LogoImagePath}
                  >
                    No Logo
                  </Avatar>
                }
                titleTypographyProps={{ variant: "h5" }}
                title={retailer.Name}
                subheader={retailer.Country}
              />
              <CardMedia
                className={classes.media}
                image={retailer.BannerImagePath}
                title={retailer.Name}
              />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">
                      Description: {retailer.Description}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography gutterBottom variant="subtitle1">
                      Website:
                      {retailer.Website ? (
                        <Link href={retailer.Website}>
                          {retailer.Website}
                        </Link>
                      ) : (
                        "No website available"
                      )}
                    </Typography>
                  </Grid>

                 
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5">Listed products:</Typography>
                </Grid>
                <Grid item xs={12}>
                  {errorItems && (
                    <Alert severity="warning">
                      Error loading retailer: {error}
                    </Alert>
                  )}
                  {loadingItems && <LinearProgress />}
                  {!loadingItems && !items && (
                    <Alert severity="warning">
                      Error items for retailer {retailerId} not found
                    </Alert>
                  )}
                  {!loading && items && (
                    <Grid container spacing={1}>
                      {items.map(item => (
                        <Grid item key={item.Name} xs={12} sm={6} md={4}>
                          <Card className={classes.cardItem}>
                            <CardHeader
                              classes={{
                                action: classes.action
                              }}
                              avatar={
                                <Avatar
                                  aria-label="ARImage"
                                  className={classes.avatar}
                                  src={item.imageAR}
                                >
                                  AR
                                </Avatar>
                              }
                              title={item.Name}
                              subheader={item.Category}
                            />
                            <CardActions>
                              <Button
                                size="small"
                                color="primary"
                                component={LinkRef}
                                to={"/item/" + item.key}
                              >
                                VIEW PRODUCT
                              </Button>
                            </CardActions>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </Grid>
                <Grid container item xs={12} spacing={2}>
                  <Grid item >
                    <Typography variant="subtitle1">
                      Available on:{" "}
                    </Typography>  
                  </Grid>
                  <Grid item >
                      <Link href="https://apps.apple.com/sg/app/snapscene-ar/id1481912483">
                        <img
                          src="/images/AppleApp.png"
                          alt="Open on App store - iOS and iPad"
                          className={classes.storeImage}
                        />
                      </Link>
                      </Grid> 
                      <Grid item >    
                      <Link href="https://play.google.com/store/apps/details?id=com.Snapscene.SnapsceneAR&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                        <img 
                            alt="Get it on Google Play" 
                            src="/images/GooglePlay.png"
                            className={classes.storeImage}
                        />    
                      </Link>
                     </Grid> 
                  </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </main>
  </>;
}

