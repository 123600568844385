import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0C3350"
    },
    secondary: {
      main: "#B38F75"
    },
    error: {
      main: red.A400
    },
    background: {
      default: "#f4f6f8"
    }
  }
});

export default theme;
