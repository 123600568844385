import * as yup from "yup";

export const itemSchema = yup.object().shape({
  Name: yup
    .string()
    .min(3)
    .max(50)
    .required(),
  Brand: yup
    .string()
    //.min(2)
    .max(20),
  Designer: yup
    .string()
    //.min(2)
    .max(20),
  Category: yup.string().required(),
  Alignment: yup.string().required(),
  Height: yup.number(),
  Width: yup.number(),
  Depth: yup.number(),
  Price: yup.number(),
  Style: yup.string().required(),
  urlItemRetailer: yup.string().url(),
  ModelPath: yup.string().required("USDZ model path is required"),
  ModelPathGLB: yup.string().required("GLB model path is required")
});

export const itemRetailerSchema = yup.object().shape({
  urlItemRetailer: yup.string().url(),
  Price: yup.number()
});
