import { initializeApp } from 'firebase/app';

const config = {
    apiKey: "AIzaSyAQH_HiLh6sS5VDeq5m9FUqFrjIztSMj1Q",
    authDomain: "snapscene-sandbox-alban.firebaseapp.com",
    databaseURL: "https://snapscene-sandbox-alban.firebaseio.com",
    projectId: "snapscene-sandbox-alban",
    storageBucket: "snapscene-sandbox-alban.appspot.com",
    messagingSenderId: "666074064967",
    appId: "1:666074064967:web:f98465ca94b62725"
  };

export const firebaseApp = initializeApp(config);