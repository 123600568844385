import _ from "lodash";

export function componentToHex(c) {
  var hex = c.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
}

export function rgbToHex(r, g, b) {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export function colorToHex(color) {
  if (color === undefined) return rgbToHex(255, 255, 255);
  if (!_.has(color, "rgb")) return rgbToHex(255, 255, 255);

  const r = _.has(color.rgb, "r") ? color.rgb.r : 255;
  const g = _.has(color.rgb, "g") ? color.rgb.g : 255;
  const b = _.has(color.rgb, "b") ? color.rgb.b : 255;

  return rgbToHex(r, g, b);
}
