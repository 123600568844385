import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import makeStyles from '@mui/styles/makeStyles';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { green } from "@mui/material/colors";
import CircularProgress from "@mui/material/CircularProgress";
import { useDocumentData } from "react-firebase-hooks/firestore";
import {
  FacebookShareButton,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  LinkedinShareButton,
  TwitterShareButton,
  PinterestShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  RedditShareButton,
  EmailShareButton,
  TumblrShareButton,
  LineShareButton,
  WeiboShareButton,
  PocketShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  PinterestIcon,
  TelegramIcon,
  WhatsappIcon,
  RedditIcon,
  TumblrIcon,
  EmailIcon,
  LineIcon,
  PocketIcon,
  WeiboIcon
} from "react-share";
import { Typography } from "@mui/material";
import {itemRef} from "../../../data/item";

const useStyles = makeStyles(() => ({
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%"
  }
}));

const ShareItem = ({ itemId }) => {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const title = "View in Snapscene App";
  const refItem = itemRef(itemId);
  const [item, loading] = useDocumentData(refItem);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {loading ? (
        <CircularProgress size={24} className={classes.buttonProgress} />
      ) : (
        <>
          {item.dynamicLink ? (
            <Button color="primary" size="small" onClick={handleClickOpen}>
              Share link
            </Button>
          ) : (
            <Typography>No link defined</Typography>
          )}
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            {" "}
            <DialogTitle id="form-dialog-title">
              Share to your Social Media
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Select the platform you want to share the link on:
              </DialogContentText>
              <FacebookShareButton url={item.dynamicLink} quote={title}>
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <FacebookMessengerShareButton url={item.dynamicLink} appId="888">
                <FacebookMessengerIcon size={32} round />
              </FacebookMessengerShareButton>
              <TwitterShareButton url={item.dynamicLink} title={title}>
                <TwitterIcon size={32} round />
              </TwitterShareButton>
              <TelegramShareButton url={item.dynamicLink} title={title}>
                <TelegramIcon size={32} round />
              </TelegramShareButton>
              <WhatsappShareButton
                url={item.dynamicLink}
                title={title}
                separator=":: "
              >
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
              <LinkedinShareButton url={item.dynamicLink}>
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
              <PinterestShareButton
                url={String(window.location)}
                media={`${String(window.location)}/${item.imageAR}`}
              >
                <PinterestIcon size={32} round />
              </PinterestShareButton>
              <RedditShareButton
                url={item.dynamicLink}
                title={title}
                windowWidth={660}
                windowHeight={460}
              >
                <RedditIcon size={32} round />
              </RedditShareButton>
              <TumblrShareButton url={item.dynamicLink} title={title}>
                <TumblrIcon size={32} round />
              </TumblrShareButton>
              <EmailShareButton
                url={item.dynamicLink}
                subject={title}
                body="View in Snapscene app:"
              >
                <EmailIcon size={32} round />
              </EmailShareButton>
              <LineShareButton url={item.dynamicLink} title={title}>
                <LineIcon size={32} round />
              </LineShareButton>
              <WeiboShareButton
                url={item.dynamicLink}
                title={title}
                image={`${String(window.location)}/${item.imageAR}`}
              >
                <WeiboIcon size={32} round />
              </WeiboShareButton>
              <PocketShareButton url={item.dynamicLink} title={title}>
                <PocketIcon size={32} round />
              </PocketShareButton>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </div>
  );
};

export default ShareItem;

ShareItem.propTypes = {
  itemId: PropTypes.string
};
