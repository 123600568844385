import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import CssBaseline from "@mui/material/CssBaseline";
import { TableData, SelectColumnFilter } from "../../components/TableData";
import PropTypes from "prop-types";
import moment from "moment";
import Avatar from "@mui/material/Avatar";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { Link } from "react-router-dom";
import _ from "lodash";
import { itemsActiveRetailer } from "../../data/item";

const useStyles = makeStyles(() => ({
  avatar: {
    backgroundColor: "#FFFFFF",
    display: "inline-flex",
    fontSize: "14px",
    fontWeight: 500,
    height: "36px",
    width: "36px"
  }
}));

export default function TableItemsRetailer({ idRetailer }) {
  const classes = useStyles();
  const retailerId = idRetailer ? idRetailer : "";

  let filteredItems = itemsActiveRetailer(retailerId);

  const [value, loading, error] = useCollectionData(filteredItems);
  const columns = React.useMemo(
    () => [
      {
        Header: "AR Image",
        Cell: function renderCellARImage(page) {
          return (
            <Avatar
              alt={page.row.original.Name}
              src={page.row.original.imageAR}
              className={classes.avatar}
            />
          );
        },
        id: "imageAR"
      },
      {
        Header: "Item name",
        accessor: "Name",
        Cell: function renderCellItemName(page) {
          return (
            <Link to={`/show-item/${page.row.original.key}`}>
              {page.row.original.Name}
            </Link>
          );
        },
        sortType: "basic"
      },
      {
        Header: "Category",
        accessor: "Category",
        Filter: SelectColumnFilter,
        sortType: "basic"
      },
      {
        Header: "Brand",
        accessor: "Brand",
        Filter: SelectColumnFilter,
        filter: "includes",
        sortType: "basic"
      },
      {
        Header: "Last modified",

        sort: "desc",
        accessor: d => {
          return typeof d.lastModifed !== "undefined"
            ? d.lastModifed.toDate()
            : new Date(1985);
        },
        Cell: page => {
          return typeof page.row.original.lastModifed !== "undefined"
            ? moment(page.row.original.lastModifed.toDate()).format(
                "Do MMMM YYYY, h:mm:ss a"
              )
            : "No date";
        },
        sortType: "datetime"
      },
      {
        Header: "Listed until",

        sort: "desc",
        accessor: d => {
          return _.has(d.StopDisplay, "seconds")
            ? d.StopDisplay.toDate()
            : new Date(1985);
        },
        Cell: page => {
          return _.has(page.row.original.StopDisplay, "seconds")
            ? moment(page.row.original.StopDisplay.toDate()).format(
                "Do MMMM YYYY"
              )
            : "No date";
        },
        sortType: "datetime"
      }
    ],
    [classes.avatar]
  );
  const items = React.useMemo(() => {
    if (loading) {
      return [];
    }
    if (!loading && error) {
      console.log(error);
    }
    return value;
  }, [loading, value, error]);

  return (
    <div>
      <CssBaseline />
      <TableData columns={columns} data={items} />
    </div>
  );
}

TableItemsRetailer.propTypes = {
  idRetailer: PropTypes.string.isRequired,
};

