import React from "react";
import { makeStyles } from "@mui/styles";
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Divider
} from "@mui/material";
import Skeleton from '@mui/material/Skeleton';
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { PieChart, Pie, Tooltip, ResponsiveContainer } from "recharts";
import { itemAnalyticsMonth } from '../../../data/static';

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%"
  },
  content: {
    alignItems: "center",
    display: "flex"
  },
  title: {
    fontWeight: 700
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  avatar: {
    backgroundColor: theme.palette.secondary,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  actions: {
    justifyContent: "flex-end"
  }
}));

export default function TopCategory() {
  const classes = useStyles();
  let navigate = useNavigate();
  // Year & Months
  const thisYear = moment().format("YYYY");
  const availableYears = ["2020", "2021","2022"];
  const [selectedYear, setYear] = React.useState(thisYear);
  const thisMonth = moment().format("M");
  const availableMonths = Array.from({ length: 12 }, (v, k) => k + 1);
  const [selectedMonth, setMonth] = React.useState(thisMonth);

  const handleMonthChange = event => {
    setMonth(event.target.value);
  };

  const handleYearChange = event => {
    setYear(event.target.value);
  };

  const handleClick = data => {
    const url = "/edit-category/" + data.name;
    navigate(url);
  };

  const q = itemAnalyticsMonth(selectedYear,moment(selectedMonth, "M").format("MM").toString());
  const [
    collectionAnalytics,
    loadingAnalytics,
    errorAnalytics
  ] = useDocumentData(q);

  const result = loadingAnalytics
    ? []
    : collectionAnalytics
    ? collectionAnalytics.categories
    : [];

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
          variant="body2"
        >
          OPENED CATEGORIES
        </Typography>
        {errorAnalytics && (
          <Typography>Error: {JSON.stringify(errorAnalytics)}</Typography>
        )}
        {loadingAnalytics ? (
          <Skeleton variant="rectangular" width="99%" height={300} animation="wave" />
        ) : (
          <ResponsiveContainer width="99%" height={300}>
            <PieChart>
              <Pie
                dataKey="amount"
                nameKey="name"
                isAnimationActive={true}
                data={result}
                cx="50%"
                cy="50%"
                innerRadius={60}
                fill="#0C3350"
                label={entry => entry.name + " (" + entry.amount + ")"}
                onClick={handleClick}
              />

              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        )}
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        <FormControl className={classes.formControl}>
          <Select
            labelId="year-select-label"
            id="year-simple-select"
            value={selectedYear}
            onChange={handleYearChange}
          >
            {availableYears.map(id => (
              <MenuItem key={id} value={id}>
                {id}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <Select
            labelId="month-select-label"
            id="month-simple-select"
            value={selectedMonth}
            onChange={handleMonthChange}
          >
            {availableMonths.map(id => (
              <MenuItem key={id} value={id}>
                {moment(id, "M").format("MMMM")}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </CardActions>
    </Card>
  );
}
