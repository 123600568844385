import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import {
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
  Avatar,
  CardActions,
  Divider,
  Button
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { useCollectionOnce } from "react-firebase-hooks/firestore";
import { LinkRef } from "../../../components/design/button-link";
import {retailersActive} from "../../../data/retailer";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%"
  },
  content: {
    alignItems: "center",
    display: "flex"
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.secondary,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: "flex",
    alignItems: "center"
  },
  differenceIcon: {
    color: theme.palette.error.dark
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: "flex-end"
  }
}));

export default function ActiveRetailers(props) {
  const { className, ...rest } = props;

  const classes = useStyles();

  const [
    collectionRetailer,
    loadingRetailer,
    errorRetailer
  ] = useCollectionOnce(retailersActive());

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              RETAILERS
            </Typography>
            {errorRetailer && (
              <Typography>Error: {JSON.stringify(errorRetailer)}</Typography>
            )}
            {loadingRetailer && <CircularProgress />}
            {collectionRetailer && (
              <Typography variant="h3">{collectionRetailer.size}</Typography>
            )}
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <ShoppingCartIcon className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid>
        <div className={classes.difference}>
          <Typography className={classes.caption} variant="caption">
            Active only
          </Typography>
        </div>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        <Button
          color="primary"
          size="small"
          variant="text"
          component={LinkRef}
          to="/list-retailers"
        >
          View all <ArrowRightIcon />
        </Button>
      </CardActions>
    </Card>
  );
}

ActiveRetailers.propTypes = {
  className: PropTypes.string
};
