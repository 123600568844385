import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import makeStyles from '@mui/styles/makeStyles';
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import { useDocumentData } from "react-firebase-hooks/firestore";
import Skeleton from '@mui/material/Skeleton';
import LinearProgress from "@mui/material/LinearProgress";
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { useUploadFile } from 'react-firebase-hooks/storage'
import { itemRef, updateItemFields } from "../../../data/item"
import {firebaseApp} from "../../../data/config";

const useStyles = makeStyles(() => ({
  cardContent: {
    position: "relative",
    display: "block", // In case it's not rendered with a div.
    height: "100%",
    overflow: "hidden"
  }
}));

const ARImageForm = ({ itemId, enqueueSnackbar }) => {
  const classes = useStyles();
  const [uploadFile, uploading,, /*snapshot,  error */ ] = useUploadFile();
  const storage = getStorage(firebaseApp);

  // Retrieve current imageAR from back-end
  const refItem = itemRef(itemId);
  const [item, loading] = useDocumentData(refItem)

  const upload = async (selectedFile) => {
    try{
      
    if (selectedFile) {
      enqueueSnackbar("Uploading new file..", {
         variant: "info"
       });
      const extension = /(?:\.([^.]+))?$/.exec(selectedFile.name);
      const refImageAR = ref(storage, 'Item/'+itemId+'/Images/imageAR'+extension[0]);
      const result = await uploadFile(refImageAR, selectedFile, {
        contentType: selectedFile.type
      });
      const url = await getDownloadURL(ref(storage, result.metadata.fullPath));
      await updateItemFields(refItem, {
         imageAR: url || "",
         ImagePath: url || ""
       });
      enqueueSnackbar("Upload completed", {
        variant: "success"
      });
    } 
    }catch(error){
      enqueueSnackbar("Upload failed: " + error, {
        variant: "error"

    });
  }}

  return (
    <Card>
      <CardHeader title={"AR Image"} subheader="Image in AR view" />
      <Divider />
      <CardContent className={classes.cardContent}>
        {loading ? (
          <Skeleton variant="rectangular" width={256} height={256} />
        ) : (
          <>
            <img src={item.imageAR} alt={item.imageAR} />
          </>
        )}
      </CardContent>
      {uploading && <LinearProgress /> }
      <CardActions>
      <label htmlFor="contained-button-file">
        <input
          type="file"
          style={{ display: 'none' }}
          id="contained-button-file"
          onChange={(e) => {
            const file = e.target.files ? e.target.files[0] : undefined;
            upload(file);
          }}
        />
        <Button
          variant="outlined"
          size="medium"
          color="primary"
          component="span"
          disabled={uploading}
        >
          Upload AR image
          </Button>
         </label> 
       
    </CardActions>
    </Card>
  );
};

export default ARImageForm;

ARImageForm.propTypes = {
  itemId: PropTypes.string.isRequired,
  enqueueSnackbar: PropTypes.func
};
