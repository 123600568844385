import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { TableData, SelectColumnFilter } from "../../components/TableData";
import Avatar from "@mui/material/Avatar";
import PropTypes from "prop-types";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { Link } from "react-router-dom";
import makeStyles from '@mui/styles/makeStyles';
import { retailersActive, retailersDeleted } from "../../data/retailer";

const useStyles = makeStyles(theme => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
    display: "inline-flex",
    fontSize: "14px",
    fontWeight: 500,
    height: "36px",
    width: "36px"
  }
}));

export default function TableRetailer(props) {
  const classes = useStyles();
  const deleted = props.deleted;
  const filteredRetailers = deleted ? retailersDeleted() : retailersActive() ;
  const [value, loading, error] = useCollectionData(filteredRetailers);
  const columns = React.useMemo(
    () => [
      {
        Header: "Logo",
        Cell: function renderCellLogo(page) {
          return (
            <Avatar
              alt={page.row.original.Name}
              src={page.row.original.LogoImagePath}
              className={classes.avatar}
            />
          );
        },
        id: "LogoImagePath"
      },
      {
        Header: "Retailer name",
        accessor: "Name",
        Cell: function renderCellRetailerName(page) {
          return (
            <Link to={`/show-retailer/${page.row.original.key}`}>
              {page.row.original.Name}
            </Link>
          );
        },
        Filter: SelectColumnFilter,
        sortType: "basic"
      },
      {
        Header: "Email",
        accessor: "Email",
        sortType: "basic"
      },
      {
        Header: "Country",
        accessor: "Country",
        Filter: SelectColumnFilter,
        filter: "includes",
        sortType: "basic"
      },
      {
        Header: "Display",
        accessor: "display",
        Filter: SelectColumnFilter,
        sortType: "basic"
      }
    ],
    [classes.avatar]
  );
  const retailers = React.useMemo(() => {
    if (loading) {
      return [];
    }
    if (!loading && error) {
      console.log(error);
    }
    return value;
  }, [loading, value, error]);

  return (
    <div>
      <CssBaseline />
      <TableData columns={columns} data={retailers} />
    </div>
  );
}

TableRetailer.propTypes = {
  deleted: PropTypes.any.isRequired
};
