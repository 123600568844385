import React, { useState, useEffect } from "react";
import {getUsers} from "../../data/user";
import {retailerRef, getRetailer} from "../../data/retailer";
import {firebaseApp} from "../../data/config";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import ShowAudit from "../../components/Show-Audit";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import AutocompleteRetailer from "../Retailer/Autocomplete-Retailer";
import Divider from "@mui/material/Divider";
import { useForm } from "react-hook-form";
import Select from "../../components/Select";
import PaperWithTitle from "../../components/PaperWithTitle";
import { useParams } from "react-router-dom";
import { getFirestore, doc } from 'firebase/firestore';
import { updateDocument } from "../../data/api";

export default function UserDetails() {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const urlParams = useParams();
  const uid= urlParams.id;
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const itemsUsers = [
    {
      value: "User",
      label: "User",
    },
    {
      value: "Retailer",
      label: "Retailer",
    },
    {
      value: "Admin",
      label: "Admin",
    }
  ];

  const refUser = doc(getFirestore(firebaseApp), 'Authority',uid );
  const [userBackEnd, loadingUser] = useDocumentData(refUser, {
    idField: "key"
  });

  const { handleSubmit, setValue, control, watch } = useForm({
    mode: "onBlur"
  });
  const fieldRole = watch("Role");

  const onSubmit = data => {
    const submitedUser = {};
    submitedUser.Role = data.Role;
    if (submitedUser.Role === "Retailer") {
      submitedUser.RetailerName = data.retailerId.Name;
      submitedUser.RetailerId = data.retailerId.id;
      submitedUser.RetailerRef = retailerRef(data.retailerId.id);
    }
    updateDocument(refUser,submitedUser,enqueueSnackbar,navigate,"/list-users/");
  }

  useEffect(() => {
    const fetchData = () => {
      getUsers().then((result =>{
      const user = result.data.data.users.filter(u => u.uid === uid);
      setUser(user[0]);
      setLoading(false);
    }));
    };

    fetchData();
  }, [uid]);

  useEffect(async() => {
    if (loadingUser) return;
    setValue("Role",userBackEnd.Role);

    (async () => {
      const initialValue = userBackEnd.RetailerId;
      if (!initialValue) return;
      let retailer =await getRetailer(initialValue);
      if (retailer.exists) {
        const r = retailer.data();
        r.id = retailer.id;
        userBackEnd.retailerId = r;
        setValue("retailerId", r);
      }
    })();
    // eslint-disable-next-line
  }, [loadingUser, setValue]);

  return loading ? (
    "Loading"
  ) : (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
          <PaperWithTitle title="User info" >
            <Typography variant="body2" color="textSecondary" component="p">
              Internal id : {user.uid}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              Created at : {user.creationTime}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              Active : {user.disabled ? "No" : "Yes"}
            </Typography>
          </PaperWithTitle>
      </Grid>
      <Grid item xs={12} md={6}>
          <PaperWithTitle title="Sign-in info" >
            <Typography variant="body2" color="textSecondary" component="p">
              Sign-in Method : {user.providerId}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              Email : {user.email}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              Email verified : {user.emailVerified ? "Yes" : "No"}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              Last sign-in : {user.lastSignInTime}
            </Typography>
        </PaperWithTitle>
      </Grid>
      <Grid item xs={12}>
        {loadingUser ? (
          "Loading profile"
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Card>
              <CardHeader title="Profile" />
              <CardContent>
                <Select control={control} name="Role" items={itemsUsers} />
                
                {fieldRole === "Retailer" && (
                  <AutocompleteRetailer control={control} name="retailerId" />
                )}
              </CardContent>
              <Divider />
              <CardActions disableSpacing>
                <Button
                  variant="contained"
                  size="medium"
                  color="primary"
                  type="submit"
                >
                  UPDATE
                </Button>
              </CardActions>
            </Card>
          </form>
        )}
      </Grid>
      <Grid item xs={12}>
      <PaperWithTitle title="Audit Trail:">
            <ShowAudit id={user.uid} collection="Authority" />
            </PaperWithTitle>
      </Grid>
    </Grid>
  );
}

