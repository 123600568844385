import React from "react";
import {queryAuditCollection} from "../data/audit";
import PropTypes from "prop-types";
import { TableData } from "./TableData";
import { useCollectionData } from "react-firebase-hooks/firestore";
import moment from "moment";
import JsonDiffReact from "jsondiffpatch-for-react";
import _ from "lodash";
import Box from '@mui/material/Box';

export default function ShowAudit({ collection, id }) {
  const changeLogs = queryAuditCollection(collection, id )
  const arrayOmittedObjects = ["RetailerRef"];

  const [value, loading, error] = useCollectionData(changeLogs, {
    idField: "key"
  });

  const columns = React.useMemo(
    () => [
      {
        Header: "Update at",

        sort: "desc",
        accessor: d => {
          return typeof d.updateAt !== "undefined"
            ? d.updateAt.toDate()
            : new Date(1985);
        },
        Cell: page => {
          return typeof page.row.original.updateAt !== "undefined"
            ? moment(page.row.original.updateAt.toDate()).format(
                "Do MMMM YYYY, h:mm:ss a"
              )
            : "No date";
        },
        sortType: "datetime",
        filterable: false
      },
      {
        Header: "Diff",
        Cell: function showJson(page) {
          return (
            <Box sx={{maxWidth:"800px" , overflow:"auto"}}>
            <JsonDiffReact
              left={_.omit(page.row.original.before, arrayOmittedObjects)}
              right={_.omit(page.row.original.after, arrayOmittedObjects)}
              annotated={false}
            />
            </Box>
          );
        },
        id: "diff"
      }
    ],
    [arrayOmittedObjects]
  );
  const items = React.useMemo(() => {
    if (loading) {
      return [];
    }
    if (!loading && error) {
      console.log(error);
    }
    return value;
  }, [loading, value, error]);

  return (
    <Box>
      <TableData columns={columns} data={items} />
    </Box>
  );
}

ShowAudit.propTypes = {
  id: PropTypes.string.isRequired,
  collection: PropTypes.string.isRequired
};
