//import { Children } from "react";
import React from "react";
import PropTypes from "prop-types";
import { Paper, Typography, Box } from "@mui/material";

function PaperWithTitle({ title, children }) {
  return(
      <Paper sx={{m:0}}>
  <Box  sx={{pt: 2, pb: 0, pl: 2, pr: 2}}>         
 <Typography variant="h5" component="span" display="block">
    {title}
 </Typography>
 </Box>
 <Box sx={{p: 2}} >{children}
</Box>

      </Paper>
  )
}

  export default PaperWithTitle;

  PaperWithTitle.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node
  };