import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import ActiveRetailers from "./Components/ActiveRetailers";
import ActiveItems from "./Components/ActiveItems";
import AllUsers from "./Components/AllUsers";
import TopMonth from "./Components/TopMonth";
import TopCategory from "./Components/TopCategory";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

export default function DashboardAdmin() {
  const classes = useStyles();

  return (
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <ActiveRetailers />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <ActiveItems />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <AllUsers />
          </Grid>
          <Grid item lg={6} sm={12} xl={6} xs={12}>
            <TopMonth analyticsType="itemPutInAR" />
          </Grid>
          <Grid item lg={6} sm={12} xl={6} xs={12}>
            <TopMonth analyticsType="stylePutInAR" />
          </Grid>
          <Grid item lg={6} sm={12} xl={6} xs={12}>
            <TopMonth analyticsType="categoryPutInAR" />
          </Grid>
          <Grid item lg={6} sm={12} xl={6} xs={12}>
            <TopMonth analyticsType="retailerPutInAR" />
          </Grid>
          <Grid item lg={6} sm={12} xl={6} xs={12}>
            <TopCategory />
          </Grid>
        </Grid>
      </div>
  );
}
