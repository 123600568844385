import { getFirestore, doc, collection, query, where, getDoc } from 'firebase/firestore';
import {firebaseApp} from "./config";
import _ from "lodash";

 export const retailerRef = (ref) =>{
    const fireapp = firebaseApp; 
    return doc(getFirestore(fireapp), 'Retailer', ref).withConverter(retailerConverter)
  }
  
  export const getRetailer = async(ref) =>{
    return await getDoc(retailerRef(ref))
  }
  
   export const retailersCollection = () => {
    const fireapp = firebaseApp;   
    return collection(getFirestore(fireapp), 'Retailer').withConverter(retailerConverter);
   }
  
   export const retailersActive = () => {
    return query(retailersCollection(), where("isDeleted", "==", false)) ;
   }
  
   export const retailersDeleted = () => {
    return query(retailersCollection(), where("isDeleted", "==", true)) ;
   }
  
   export const retailerConverter = {
    toFirestore: (retailer) => {
      return { 
        Name: retailer.Name,
        Country: retailer.Country,
        Email: retailer.Email,
        Address: retailer.Address,
        Contact: retailer.Contact,
        whatsapp: retailer.whatsapp, 
        Website: retailer.Website,
        display: retailer.display,
        Description: _.trim(retailer.Description),
        LogoImagePath: retailer.imageLogo,
        BannerImagePath: retailer.imageBanner,
        isDeleted: false
      }
  
  
    }, 
    fromFirestore: (snapshot, options ) => {
      const data = snapshot.data(options);
      return { 
        key: snapshot.id,
        Name: data.Name, 
        LogoImagePath: data.LogoImagePath,
        Email: data.Email,
        Country: data.Country,
        Contact: data.Contact,
        whatsapp: data.whatsapp,
        Address: data.Address,
        Website: data.Website,
        display: data.display,
        BannerImagePath: data.BannerImagePath,
        likeCount: data.likeCount,
        Description: data.Description,
        dynamicLink: data.dynamicLink,
        isDeleted: data.isDeleted      
      }
  
  
    }
   }