import React from "react";
import PropTypes from "prop-types";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { NavLink } from "react-router-dom";


export function ListItemLink(props) {
    const { icon, primary, to } = props;
  
    return (
      <li>
        <NavLink to={to} style={{textDecoration: "none", color: "#000000"}}  >
          { ({isActive}) => (
        <ListItemButton
          selected={isActive}
          sx={
            [{
              cursor: "pointer", 
              "&:hover": {
                borderRadius: 4,
                "& .ListItemIcon": {
                  color: 'primary.main',
                },
                "& .ListItemText": {
                  color: 'primary.main',
                  textDecoration: "none",
                },
              },
            },  
              isActive && {
                borderLeft: 4,
                borderColor: "primary.main",
                "& .ListItemIcon": {
                  color: 'primary.main',
                  marginLeft: "-4px"
                },
                "& .ListItemText": {
                  color: 'primary.main',
                },
              }    
          ]        }
        >
          <ListItemIcon className="ListItemIcon">{icon}</ListItemIcon>
          <ListItemText className="ListItemText" primary={primary} />
        </ListItemButton>
        )}
        </NavLink>
      </li>
    );
  }
  
  ListItemLink.propTypes = {
    icon: PropTypes.node.isRequired,
    primary: PropTypes.node.isRequired,
    to: PropTypes.string.isRequired
  };