import { useState, useEffect } from "react";
import moment from "moment";
import { deleteObject, getStorage, ref, getMetadata } from "firebase/storage";
import {firebaseApp} from "./config";

export const deleteFile =  async(fileReference, enqueueSnackbar) =>{
  try{
    enqueueSnackbar("Deleting file..", {
       variant: "info"
     });  
   //const fileToDelete = ref(storage, fileReference);
   deleteObject(fileReference).then(() => {
    // File deleted successfully
    enqueueSnackbar("File deleted", {
      variant: "success"
    });
  }).catch((error) => {
    enqueueSnackbar("Deletion failed: " + error.message, {
      variant: "error"
  }); 
  });

   
   } catch(error){
      enqueueSnackbar("Deletion failed: " + error.message, {
        variant: "error"
    }); 
}
}

export const storageFullPath = (fullPath) => {
  if (fullPath === undefined) return undefined;
  const fireapp = firebaseApp;
  const storage = getStorage(fireapp);
  return ref(storage, fullPath);

}

export const staticDataStorage = (staticType, filename) =>{
  const fireapp = firebaseApp;
  const storage = getStorage(fireapp);
  return ref(storage, 'StaticData/' + staticType + '/' +  filename );

}

export const modelStorage = (filetype) => {
  const fireapp = firebaseApp;
  const storage = getStorage(fireapp);
  const selectedFormat = filetype === "GLB" ? "ModelGLB" : "/Model";
  return ref(storage, selectedFormat)
}

export const getModelUpdated = (fullPath) => {
  const [datetime, setUpdated] = useState(new Date(1985));

  useEffect(() => {
        const modelRef = storageFullPath(fullPath);
  
        getMetadata(modelRef)
          .then(function(metadata) {
            const updatedAtBack = moment(metadata.updated);
            setUpdated(updatedAtBack);
          })
          .catch(error => {
            console.error("Error getting Model last modification: ", error);
          });
      }, [fullPath]);

  return datetime;

}