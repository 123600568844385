import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";
import { useController } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import {modelStorage} from "../../data/storage"
import { listAll } from "firebase/storage";

export default function AutocompleteModel({control, name, format}) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;
  const selectedFormat = format === "GLB" ? "GLB" : "USDZ";
  const labelField = selectedFormat+" 3D model";

  /* eslint-disable no-unused-vars */
  const {
    field: { onChange, onBlur, nameInput, value, ref },
    fieldState: { invalid, isTouched, isDirty, error },
    formState: { touchedFields, dirtyFields }
  } = useController({
    name,
    control,
    rules: { required: true },
    defaultValue: "",
  });

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const files = [];
      const storage = modelStorage(selectedFormat)
      const response = await listAll(storage);
      response.items.forEach(itemRef => {
        files.push(itemRef.fullPath);
      });
      if (active) {
        setOptions(files);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (

  <FormControl error={invalid}>
      <Autocomplete
          id="asynchronous-model"
          style={{ width: 400 }}
          value={value}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          options={options}
          loading={loading}
          renderInput={params => (
            <TextField
              {...params}
              label={labelField}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                )
              }}
            />
          )}
          onChange={(_, data) => onChange(data)}
          onBlur={onBlur}
          name={nameInput}
        />
        {invalid && (
                  <FormHelperText id="component-error-text">
                    {error.message}
                  </FormHelperText>)}
        </FormControl>
  );
}

AutocompleteModel.propTypes = {
  name: PropTypes.string.isRequired,
  format: PropTypes.string,
  control: PropTypes.object.isRequired
};
