import React, { useState, useEffect } from "react";
import { Route, BrowserRouter, Navigate, Routes } from "react-router-dom";
import {firebaseApp} from "./data/config";
import LinearProgress from "@mui/material/LinearProgress";
import Login from "./views/login/login";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./Theme";
import { SnackbarProvider } from "notistack";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { routesPublic, routesSnapAdmin, routesSnapRetailer } from "./Route";

function App() {
  const [initializing, setInit] = useState(true);
  const [auth, setAuth] = useState(false);
  const authFirebase = getAuth(firebaseApp);
  useEffect( () => {
    onAuthStateChanged(authFirebase, async(authUser) => {
      if (authUser) {
        // Retrieve profile
        const db = getFirestore(firebaseApp);
        const userRef = doc(db, "Authority", authUser.uid);
        const docProfile = await getDoc(userRef);
        if (docProfile.exists()) {
          const profile = docProfile.data();
            // default empty role to user
          if (!profile.Role) {
            profile.Role = "user";
          }
          // merge auth and db user
          setAuth ( {
            uid: authUser.uid,
            email: authUser.email,
            ...profile
          });
          setInit(false)
        } else {
          setAuth(false)
          setInit(false)
        }
      } else{
        setAuth(false)
        setInit(false)
      }
    })
  }, [])

  
  return initializing !== true ? (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
        >
          <CssBaseline />
          <BrowserRouter>
            <Routes>
             <Route path="/login" element={<Login />} />
             {auth == false &&  <Route path="/" element={<Navigate to="/login" />} />}
             {auth.Role === "Admin" && routesSnapAdmin()}
             {auth.Role === "Retailer" && routesSnapRetailer(auth.RetailerId)}
             {routesPublic()}
              <Route      path="*"       element={ <h3>404 - Route not found</h3>} />
            </Routes>
          </BrowserRouter>
        </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  ) : (
    <div id="loader">
      <LinearProgress />
    </div>
  );
}

export default App;
