import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import MuiAppBar from "@mui/material/AppBar";
import MuiDrawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Divider from "@mui/material/Divider";
import {userLogout} from "../../data/auth";
import DrawerMenuItem from "./menu-items";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { LinkRef as Link } from "../../components/design/button-link";
import _ from "lodash";
import { breadcrumbsAdmin } from "../../Route";
import { useLocation, useParams } from "react-router-dom";
import { Outlet } from "react-router-dom";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function Snapadmin() {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const isMobile = !useMediaQuery("(min-width:600px)");
  const [breadCrumbs, setCrumbs] = useState([]);

   // Convertion to router v6 - path is not available 
  const getRoutePath = (location, params) => {
    const { pathname } = location;
  
    if (!Object.keys(params).length) {
      return pathname; // we don't need to replace anything
    }
  
    let path = pathname;
    Object.entries(params).forEach(([paramName, paramValue]) => {
      if (paramValue) {
        path = path.replace(paramValue, `:${paramName}`);
      }
    });
    return path;
  };
  const location = useLocation();
  const params = useParams();
  const path = getRoutePath(location, params);



  //Create array for the breadcumb link
  useEffect(() => {
    const route = breadcrumbsAdmin.filter(r => r.path === path);
    if (_.isArray(route) && _.has(route[0], "breadcrumbs")) {
      const crumbs = route[0].breadcrumbs;
      const replacedCrumbs = params.id
        ? crumbs.map(cr => {
            const updatedCrumb = {};
            updatedCrumb.name = cr.name;
            updatedCrumb.path = _.replace(cr.path, "[id]", params.id);
            return updatedCrumb;
          })
        : crumbs;

      setCrumbs(replacedCrumbs);
    }
  }, [path, params.id]);


  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  async function logout() {
    await userLogout();
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        position="fixed"
        open={drawerOpen}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: '36px',
              ...(drawerOpen && { display: 'none' }),
            }}
            size="large">
            <MenuIcon />
          </IconButton>{" "}
          <Box sx={{ flexGrow: 1}}>
            <Typography>Snapadmin</Typography>
          </Box>
          <Button color="inherit" onClick={() => logout()} href="/">
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        variant={isMobile ? "temporary" : "permanent"}
        open={drawerOpen}
      >
        <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
        <Typography>SNAPSCENE ADMIN</Typography>
        <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
       
        <Divider />
        <DrawerMenuItem />
      </Drawer>

      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <DrawerHeader />
        {" "}
        <Breadcrumbs aria-label="breadcrumb" sx={{ gap: 3, mb: 2 }}>
          <Link color="inherit" to="/">
            Dashboard
          </Link>
          {breadCrumbs.map((crumb, key) => {
            return (
              <Link color="inherit" to={crumb.path} key={key}>
                {crumb.name}
              </Link>
            );
          })}
        </Breadcrumbs>
        <Box sx={{m: 1}}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}