import React /*,  { useState, useEffect } */ from "react";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { LinkRef } from "../../components/design/button-link";
import TableItems from "./Table-Items";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  row: {
    height: "42px",
    display: "flex"
  }
}));

export default function ListItems() {
  const classes = useStyles();

  return (
      <Paper className={classes.root}>
        <div className={classes.row}>
          <Button
            variant="outlined"
            size="medium"
            color="primary"
            sx={{ m: 1 }}
            component={LinkRef}
            to="/create-item"
          >
            Add an Item
          </Button>
        </div>
        <TableItems />
      </Paper>
  );
}
