import React from "react";
import PropTypes from "prop-types";
import QRCode from "qrcode.react";
import Typography from "@mui/material/Typography";

const QRCodeItem = ({ url }) => {
  // Reference https://zpao.github.io/qrcode.react/
  // const imageSettings = {
  //   src: "http://snapscene.sg/img/logo.png",
  //   x: null,
  //   y: null,
  //   height: 24,
  //   width: 104,
  //   excavate: true
  // };

  return url ? (
    <QRCode
      value={url}
      size={256}
      bgColor={"#ffffff"}
      fgColor={"#000000"}
      level={"L"}
      includeMargin={false}
      renderAs={"svg"}
      //imageSettings={imageSettings}
    />
  ) : (
    <Typography>Create a Dynamic link first</Typography>
  );
};

export default QRCodeItem;

QRCodeItem.propTypes = {
  url: PropTypes.string.isRequired
};
