import React from "react";
import { Link } from "react-router-dom";

// The usage of React.forwardRef will no longer be required for react-router-dom v6.
// see https://github.com/ReactTraining/react-router/issues/6056
/*const AdapterLink = React.forwardRef((props, ref) => <Link innerRef={ref} {...props} />);

const CollisionLink = React.forwardRef((props, ref) => (
  <Link innerRef={ref} to="/getting-started/installation/" {...props} />
));
*/
export const LinkRef = React.forwardRef((props, ref) => (
  <div ref={ref}>
    <Link {...props} />
  </div>
));

LinkRef.displayName = 'LinkRef';

/*
export default function ButtonLink({ component: To, Label, ...rest }) {
  return (

      props =>(
    <Router>
      <Button color="primary" component={AdapterLink} to={props.to}>
       {props.Label}
      </Button>
    </Router>
  ));
}*/
