import React, { useState, useEffect } from "react";
import { TableData, SelectColumnFilter } from "../../components/TableData";
import { Link } from "react-router-dom";
import {getUsers} from "../../data/user";


export default function TableRetailer() {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchData = () => {
      getUsers().then((result =>{
        setUsers(result.data.data.users);
      }));
    };

    fetchData();
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "UID",
        accessor: "uid",
        Cell: function renderCellUID(page) {
          return (
            <Link to={`/show-user/${page.row.original.uid}`}>
              {page.row.original.uid}
            </Link>
          );
        }
      },
      {
        Header: "Email",
        accessor: "email"
      },
      {
        Header: "Role",
        accessor: "Role",
        Filter: SelectColumnFilter,
        filter: "includes",
        sortType: "basic"
      },
      {
        Header: "Retailer name",
        accessor: "Name",
        Cell: function renderCellRetailerName(page) {
          return (
            <Link to={`/show-retailer/${page.row.original.RetailerId}`}>
              {page.row.original.RetailerName}
            </Link>
          );
        },
        Filter: SelectColumnFilter,
        sortType: "basic"
      },
      {
        Header: "Login method",
        accessor: "providerId",
        Filter: SelectColumnFilter
      },
      {
        Header: "Last sign-in",
        accessor: "lastSignInTime"
      },
      {
        Header: "Disabled?",
        accessor: r => (r.disabled ? "Yes" : "No"),
        Filter: SelectColumnFilter
      }
    ],
    []
  );
  return (
    <div>
      <TableData columns={columns} data={users} />
    </div>
  );
}
