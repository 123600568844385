import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Divider
} from "@mui/material";
import Skeleton from '@mui/material/Skeleton';
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {firebaseApp} from "../../../data/config";
import { useDocumentData } from "react-firebase-hooks/firestore";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LabelList,
  ResponsiveContainer
} from "recharts";
import { getFirestore, doc } from 'firebase/firestore';

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%"
  },
  content: {
    alignItems: "center",
    display: "flex"
  },
  title: {
    fontWeight: 700
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  avatar: {
    backgroundColor: theme.palette.secondary,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: "flex",
    alignItems: "center"
  },
  differenceIcon: {
    color: theme.palette.error.dark
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: "flex-end"
  }
}));

export default function TopPerMonth(props) {
  const { className, analyticsType, ...rest } = props;
  const classes = useStyles();
  let navigate = useNavigate();
  // Year & Months
  const thisYear = moment().format("YYYY");
  const availableYears = ["2020", "2021","2022"];
  const [selectedYear, setYear] = React.useState(thisYear);
  const thisMonth = moment().format("M");
  const availableMonths = Array.from({ length: 12 }, (v, k) => k + 1);
  const [selectedMonth, setMonth] = React.useState(thisMonth);
  // Analytics type
  const topN = 5;
  const analyticsAvailable = [
    {
      collectionName: "itemPutInAR",
      titleName: "TOP ITEMS OF THE MONTH",
      arrayName: "items"
    },
    {
      collectionName: "stylePutInAR",
      titleName: "TOP STYLES OF THE MONTH",
      arrayName: "styles"
    },
    {
      collectionName: "categoryPutInAR",
      titleName: "TOP CATEGORIES OF THE MONTH",
      arrayName: "categories"
    },
    {
      collectionName: "retailerPutInAR",
      titleName: "TOP RETAILERS OF THE MONTH",
      arrayName: "retailers"
    }
  ];
  const [selectedAnalytics] = React.useState(
    _.find(analyticsAvailable, ["collectionName", analyticsType])
  );

  const handleMonthChange = event => {
    setMonth(event.target.value);
  };

  const handleYearChange = event => {
    setYear(event.target.value);
  };

  const handleClick = data => {
    var url = "";
    switch (selectedAnalytics.collectionName) {
      case "itemPutInAR":
        url = url + "/show-item/" + data.id;
        break;
      case "stylePutInAR":
        url = url + "/edit-style/" + data.name;
        break;
      case "categoryPutInAR":
        url = url + "/edit-category/" + data.name;
        break;
      case "retailerPutInAR":
        url = url + "/show-retailer/" + data.id;
        break;
      default:
        break;
    }
    navigate(url);
  };

  const q = doc(getFirestore(firebaseApp), 'Analytics',selectedAnalytics.collectionName, selectedYear,moment(selectedMonth, "M").format("MM").toString() );

  const [
    collectionAnalytics,
    loadingAnalytics,
    errorAnalytics
  ] = useDocumentData(q);

  const topNresult = loadingAnalytics
    ? []
    : collectionAnalytics
    ? _(collectionAnalytics[selectedAnalytics.arrayName])
        .map(item => _.pick(item, ["amount", "name", "id"]))
        .orderBy(["amount", "name"], ["desc", "asc"])
        .take(topN)
        .value()
    : [];
  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
          variant="body2"
        >
          {selectedAnalytics.titleName}
        </Typography>
        {errorAnalytics && (
          <Typography>Error: {JSON.stringify(errorAnalytics)}</Typography>
        )}
        {loadingAnalytics ? (
          <Skeleton variant="rectangular" width="99%" height={300} animation="wave" />
        ) : (
          <ResponsiveContainer width="99%" height={300}>
            <BarChart data={topNresult} layout="vertical">
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                type="number"
                allowDecimals={false}
                dataKey="amount"
                orientation="top"
              ></XAxis>
              <YAxis type="category" dataKey="name" width={85} />
              <Tooltip />
              <Bar dataKey="amount" fill="#0C3350" onClick={handleClick}>
                <LabelList
                  dataKey="amount"
                  position="insideRight"
                  fill="#ffffff"
                />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        )}
        <div className={classes.difference}>
          <Typography className={classes.caption} variant="caption">
            Items added to an AR session
          </Typography>
        </div>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        <FormControl className={classes.formControl}>
          <Select
            labelId="year-select-label"
            id="year-simple-select"
            value={selectedYear}
            onChange={handleYearChange}
          >
            {availableYears.map(id => (
              <MenuItem key={id} value={id}>
                {id}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <Select
            labelId="month-select-label"
            id="month-simple-select"
            value={selectedMonth}
            onChange={handleMonthChange}
          >
            {availableMonths.map(id => (
              <MenuItem key={id} value={id}>
                {moment(id, "M").format("MMMM")}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </CardActions>
    </Card>
  );
}

TopPerMonth.propTypes = {
  className: PropTypes.string,
  analyticsType: PropTypes.string.isRequired
};
