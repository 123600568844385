import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Alert from '@mui/material/Alert';
import makeStyles from '@mui/styles/makeStyles';
import InputAdornment from "@mui/material/InputAdornment";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import BusinessIcon from "@mui/icons-material/Business";
import LinkIcon from "@mui/icons-material/Link";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useForm } from "react-hook-form";
import { green } from "@mui/material/colors";
import CircularProgress from "@mui/material/CircularProgress";
import { retailerProfileSchema } from "../Retailer-validation";
import Input from "../../../components/Input";
import { retailerRef } from "../../../data/retailer";
import { updateDoc } from 'firebase/firestore';

const useStyles = makeStyles(theme => ({
  resultAlert: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2)
    }
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative"
  }
}));

const FromContact = ({ retailerId, contact }) => {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const [submit, setSubmit] = useState({
    result: 0,
    message: ""
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async(data) => {
    setSubmit({
      result: 1,
      message: "Loading"
    });
    const refRetailer = retailerRef(retailerId);
    try{
      await updateDoc(refRetailer,
        {
        Email: data.Email,
        Address: data.Address,
        Contact: data.Contact,
        whatsapp: data.whatsapp,
        Website: data.Website
      });
        setSubmit({
          result: 3,
          message: "Contact card updated"
        });
        setOpen(false);
      }
       catch(error) {
        setSubmit({
          result: 2,
          message: error.message
        });
      }
  };

  const { handleSubmit, control, reset } = useForm({
    mode: "onBlur",
    validationSchema: retailerProfileSchema
  });

  useEffect(() => {
    reset(contact)
}, [reset, contact]);



  return (
    <div>
      <Button color="primary" size="small" onClick={handleClickOpen}>
        Update contact information
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        {" "}
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id="form-dialog-title">
            Update your contact information
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Your contact information is shared on your profile page. It is as
              well used to contact you for requests.
            </DialogContentText>
            {submit.result > 1 && (
              <div className={classes.resultAlert}>
                <Alert severity={submit.result === 3 ? "success" : "error"}>
                  {submit.message}
                </Alert>
              </div>
            )}
             <Input name="Contact" label="Retailer Phone Number" control={control} InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneIphoneIcon />
                  </InputAdornment>
                )
              }} />
             
            {/* <Controller
              as={TextField}
              autoFocus
              margin="dense"
              id="Contact"
              name="Contact"
              label="Phone"
              fullWidth
              control={control}
              defaultValue={contact.Phone}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneIphoneIcon />
                  </InputAdornment>
                )
              }}
              error={errors.Contact ? true : false}
              helperText={errors.Contact && errors.Contact.message}
            /> */}
             <Input name="whatsapp" label="Whatsapp number" control={control}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <WhatsAppIcon />
                    </InputAdornment>
                  )
                }}
             />
             
            {/* <Controller
              as={TextField}
              autoFocus
              margin="dense"
              id="whatsapp"
              name="whatsapp"
              label="Whatsapp"
              fullWidth
              control={control}
              defaultValue={contact.whatsapp}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <WhatsAppIcon />
                  </InputAdornment>
                )
              }}
              error={errors.Whatsapp ? true : false}
              helperText={errors.Whatsapp && errors.Whatsapp.message}
            /> */}

<Input name="Email" label="Email" control={control} type="email" InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailOutlineIcon />
                  </InputAdornment>
                )
              }} />
            

            {/* <Controller
              as={TextField}
              autoFocus
              margin="dense"
              id="Email"
              name="Email"
              label="Email"
              fullWidth
              control={control}
              defaultValue={contact.Email}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailOutlineIcon />
                  </InputAdornment>
                )
              }}
              error={errors.Email ? true : false}
              helperText={errors.Email && errors.Email.message}
            /> */}
              <Input name="Address" label="Retailer Address" control={control} multiline rows="3" InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <BusinessIcon />
                  </InputAdornment>
                )
              }} />
            


            {/* <Controller
              as={TextField}
              autoFocus
              multiline
              rows={3}
              margin="dense"
              id="Address"
              name="Address"
              label="Address"
              fullWidth
              control={control}
              defaultValue={contact.Address}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <BusinessIcon />
                  </InputAdornment>
                )
              }}
            /> */}
 <Input name="Website" label="Retailer Website" control={control} type="url" InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LinkIcon />
                  </InputAdornment>
                )
              }} />
             

            {/* <Controller
              as={TextField}
              autoFocus
              margin="dense"
              id="Website"
              name="Website"
              label="Website (url)"
              fullWidth
              control={control}
              defaultValue={contact.Website}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LinkIcon />
                  </InputAdornment>
                )
              }}
              error={errors.Website ? true : false}
              helperText={errors.Website && errors.Website.message}
            /> */}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <div className={classes.wrapper}>
              <Button color="primary" type="submit">
                Update Contact Information
              </Button>
              {submit.result === 1 && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default FromContact;

FromContact.propTypes = {
  retailerId: PropTypes.string.isRequired,
  contact: PropTypes.object
};
