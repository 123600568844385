import { getFunctions, httpsCallable } from "firebase/functions";
import {firebaseApp} from "./config";


export const getUsers = () => {
    const fireapp = firebaseApp;
    const functions = getFunctions(fireapp);
    const getAuthFunction = httpsCallable(functions, 'getAuth');
    return getAuthFunction();
  }
  
  export const getUsersRetailer = (retailerId) => {
    const fireapp = firebaseApp;
    const functions = getFunctions(fireapp);
    const getAuthFunction = httpsCallable(functions, 'getRetailerAuth');
    return getAuthFunction({ RetailerId: retailerId });
  }