import React from "react";
import { Grid } from "@mui/material";
//import Snapretailer from "../../layouts/Snapretailer/Snapretailer";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import makeStyles from '@mui/styles/makeStyles';
import Container from "@mui/material/Container";
import PropTypes from "prop-types";
import { useCollectionData } from "react-firebase-hooks/firestore";
import CardMedia from "@mui/material/CardMedia";
import CardActionArea from "@mui/material/CardActionArea";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import MoreVert from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ShareItem from "./Components/Share-Item";
import StatusListingItem from "./Components/ListingStatus-Item";
import Link from "@mui/material/Link";
import Skeleton from '@mui/material/Skeleton';
import FromItem from "./Components/Form-Retailer-Item";
import RetailerItemAnalyticsMonth from "./Components/Retailer-item-analytics";
import { itemsActiveRetailer } from "../../data/item";


const useStyles = makeStyles(theme => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none"
    }
  },
  action: {
    marginLeft: 8
  },
  link: {
    margin: theme.spacing(1, 1.5)
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6)
  },
  cardHeader: {
    backgroundColor:
      theme.palette.mode === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[700]
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2)
  },
  cardMediaItem: ({ bgColor = "rgba(0, 0, 0, 0.08)" }) => ({
    width: "100%",
    paddingBottom: "56.25%",
    clipPath: "polygon(0 0, 100% 0%, 100% 84%, 0% 100%)",
    backgroundColor: bgColor
  }),
  cardItem: ({ inactive }) => ({
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    transition: "0.3s",
    ...(!inactive && {
      "&:hover": {
        boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)"
      }
    })
  })
}));

const MenuCard = ({ itemId }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="settings"
        aria-controls="settings-item"
        aria-haspopup="true"
        onClick={handleClick}
        size="large">
        <MoreVert />
      </IconButton>
      <Menu
        id="settings-item"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>
          <ShareItem itemId={itemId} />
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Link
            size="small"
            color="primary"
            target="_blank"
            rel="noopener"
            href={"https://snapscene.app/item/" + itemId}
          >
            Open in App
          </Link>
        </MenuItem>
        <MenuItem onClick={handleClose}>Detailed statictics</MenuItem>
      </Menu>
    </div>
  );
};

MenuCard.propTypes = {
  itemId: PropTypes.string
};

const RetailerItems = ({ retailerId }) => {
  const classes = useStyles();

  let filteredItems = itemsActiveRetailer(retailerId);

  const [items, loading /* error */] = useCollectionData(filteredItems);

  return (
    <>
      <Container maxWidth="sm" component="main" className={classes.heroContent}>
        <Typography
          component="h2"
          variant="h3"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          Items
        </Typography>
        <Typography
          variant="h5"
          align="center"
          color="textSecondary"
          component="p"
        >
          Review and update your current listings for the snapscene app.
        </Typography>
      </Container>
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end">
          {loading
            ? [0, 1, 2, 4, 5, 6].map(sk => (
                <Grid item key={sk} xs={12} sm={6} md={4}>
                  <Skeleton variant="rectangular" width={300} height={500} />
                </Grid>
              ))
            : items.map(item => (
                // Enterprise card is full width at sm breakpoint
                <Grid item key={item.Name} xs={12} sm={6} md={4}>
                  <Card className={classes.cardItem}>
                    <StatusListingItem itemId={item.key} />
                    <CardHeader
                      classes={{
                        action: classes.action
                      }}
                      avatar={
                        <Avatar
                          aria-label="ARImage"
                          className={classes.avatar}
                          src={item.imageAR}
                        >
                          AR
                        </Avatar>
                      }
                      action={<MenuCard itemId={item.key} />}
                      title={item.Name}
                      subheader={item.Category}
                    />
                    <CardActionArea>
                      <CardMedia
                        image={item.imagesItem[0].name}
                        className={classes.cardMediaItem}
                      />
                    </CardActionArea>
                    <CardContent>
                      <div className={classes.cardPricing}>
                        <RetailerItemAnalyticsMonth id={item.key} />
                      </div>
                    </CardContent>
                    <CardActions>
                      <FromItem
                        itemId={item.key}
                        item={{
                          Description: item.Description,
                          urlItemRetailer: item.urlItemRetailer,
                          Price: item.Price
                        }}
                      />
                    </CardActions>
                  </Card>
                </Grid>
              ))}
        </Grid>
      </Container>
    </>
  );
};

export default RetailerItems;

RetailerItems.propTypes = {
  retailerId: PropTypes.string
};
