// Router
import { Route} from "react-router-dom";
//Snapadmin
import React from "react";
import Snapadmin from "./layouts/Snapadmin/snapadmin";
import EditRetailer from "./views/Retailer/Edit-Retailer";
import CreateRetailer from "./views/Retailer/Create-Retailer";
import ShowRetailer from "./views/Retailer/Show-Retailer";
import ListRetailers from "./views/Retailer/List-Retailers";
import ListItems from "./views/Item/List-Items";
import ShowItem from "./views/Item/Show-Item";
import EditItem from "./views/Item/Edit-Item";
import CreateItem from "./views/Item/Create-item";
import DashboardAdmin from "./views/Dashboard/Dashboard-admin";
import ListModels from "./views/Model/List-models";
import ListStaticData from "./views/StaticData/List-StaticData";
import EditStyle from "./views/StaticData/Style/Edit-Style";
import EditCategory from "./views/StaticData/Category/Edit-Category";
import EditColour from "./views/StaticData/Colour/Edit-Colour";
import ListUsers from "./views/User/List-User";
import ShowUser from "./views/User/Show-user";
// Snapretailer
import Snapretailer from "./layouts/Snapretailer/Snapretailer";
import DashboardRetailer from "./views/Dashboard/Dashboard-retailer";
import Pricing from "./views/Pricing/Show-Pricing";
import ProfileRetailer from "./views/Retailer/Profile-Retailer";
import RetailerItems from "./views/Item/Retailer-item";
import RetailerUsers from "./views/User/Retailer-User";
import RetailerSettings from "./views/Settings/Settings";
// Public route
import PublicItem from "./views/Item/Public-Item";
import PublicRetailer from "./views/Retailer/Public-Retailer";
import PublicModel from "./layouts/PublicModel/Public-model";

export const breadcrumbsAdmin = [
  {
    path: "/dashboard-admin",
    name: "Dashboard",
    breadcrumbs: []
  },
  {
    path: "/list-users",
    name: "List users",
    breadcrumbs: []
  },
  {
    path: "/show-user/:id",
    name: "Show a User",
    breadcrumbs: [{ name: "Users", path: "/list-users" }]
  },
  {
    path: "/list-retailers",
    name: "List all retailers",
    breadcrumbs: []
  },
  {
    path: "/edit-retailer/:id",
    name: "Edit a retailer",
    breadcrumbs: [
      { name: "Retailers", path: "/list-retailers" },
      { name: "View Retailer", path: "/show-retailer/[id]" }
    ]
  },
  {
    path: "/create-retailer",
    name: "Create a new retailer",
    breadcrumbs: [{ name: "Retailers", path: "/list-retailers" }]
  },
  {
    path: "/show-retailer/:id",
    name: "Show a retailer",
    breadcrumbs: [{ name: "Retailers", path: "/list-retailers" }]
  },
  {
    path: "/list-items",
    name: "List of items",
    breadcrumbs: []
  },
  {
    path: "/show-item/:id",
    name: "Show an item",
    breadcrumbs: [{ name: "Items", path: "/list-items" }]
  },
  {
    path: "/edit-item/:id",
    name: "Edit an item",
    breadcrumbs: [
      { name: "Items", path: "/list-items" },
      { name: "View Item", path: "/show-item/[id]" }
    ]
  },
  {
    path: "/create-item",
    name: "Create an item",
    breadcrumbs: [{ name: "Items", path: "/list-items" }]
  },
  {
    path: "/list-models",
    name: "List available models",
    element: <ListModels />,
    breadcrumbs: []
  },
  {
    path: "/list-staticdata/:tab",
    name: "Static data",
    breadcrumbs: []
  },
  {
    path: "/list-staticdata/",
    name: "Static data",
    breadcrumbs: []
  },
  {
    path: "/edit-style/:id",
    name: "Edit a style",
    breadcrumbs: [{ name: "Styles", path: "/list-staticdata/style" }]
  },
  {
    path: "/edit-category/:id",
    name: "Edit a category",
    breadcrumbs: [{ name: "Categories", path: "/list-staticdata" }]
  },
  {
    path: "/edit-colour/:id",
    name: "Edit a colour",
    breadcrumbs: [{ name: "Colours", path: "/list-staticdata/colour" }]
  }
];

export const routesSnapAdmin = () => {
  return( 
  <Route path="/" element={<Snapadmin />}>
    <Route index element={<DashboardAdmin />} /> 
    <Route path="dashboard-admin" element={<DashboardAdmin />} />
    <Route path="list-users" element={<ListUsers />} />
    <Route path="/show-user/:id" element={<ShowUser />} />
    <Route path="/list-retailers" element={<ListRetailers />} />
    <Route path="/create-retailer" element={<CreateRetailer />} />
    <Route path="/show-retailer/:id" element={<ShowRetailer />} />
    <Route path="/edit-retailer/:id" element={<EditRetailer />} />
    <Route path="/list-items" element={<ListItems />} />
    <Route path="/create-item" element={<CreateItem />} />
    <Route path="/show-item/:id" element={<ShowItem />} />
    <Route path="/edit-item/:id" element={<EditItem />} />
    <Route path="/list-models" element={<ListModels />} />
    <Route path="/list-staticdata" element={<ListStaticData />} />
    <Route path="/list-staticdata/:tab" element={<ListStaticData />} />
    <Route path="/edit-style/:id" element={<EditStyle />} />
    <Route path="/edit-category/:id" element={<EditCategory />} />
    <Route path="/edit-colour/:id" element={<EditColour />} />  
    <Route path="*"  element={ <h3>Route not found</h3>} />
  </Route>  
  )
 }

export const routesSnapRetailer = (retailerId) => {
  return( <Route path="/" element={<Snapretailer retailerId={retailerId} />}>
                <Route index element={<DashboardRetailer retailerId={retailerId} />} />
                <Route path="dashboard" element={<DashboardRetailer retailerId={retailerId} />} />
                <Route path="users" element={<RetailerUsers retailerId={retailerId} />} />
                <Route path="items" element={<RetailerItems retailerId={retailerId} />} />
                <Route path="pricing" element={<Pricing />} />
                <Route path="retailer" element={<ProfileRetailer retailerId={retailerId} />} />
                <Route path="settings" element={<RetailerSettings />} />
                <Route path="*"  element={ <h3>Page not available - please contact us</h3>} />
   </Route>)
}

export const routesPublic = () => {
  return( 
  <>
  <Route path="/item/:id" element={<PublicItem />} />
  <Route path="/retailer/:id" element={<PublicRetailer />} />
  <Route path="/model/" element={<PublicModel />} />
  </>
  )
}

