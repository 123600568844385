import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Card, CardContent, Typography, Divider } from "@mui/material";
import Slider from "@mui/material/Slider";
import Skeleton from '@mui/material/Skeleton';
import { useCollectionData } from "react-firebase-hooks/firestore";
import _ from "lodash";
import moment from "moment";
import {
  ComposedChart,
  Bar,
  Line,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { itemAnalyticsYear } from "../../../data/item";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%"
  },
  content: {
    alignItems: "center",
    display: "flex"
  },
  title: {
    fontWeight: 700
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  avatar: {
    backgroundColor: theme.palette.secondary,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: "flex",
    alignItems: "center"
  },
  differenceIcon: {
    color: theme.palette.error.dark
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: "flex-end"
  }
}));

export default function ItemAnalytics(props) {
  const { className, id, ...rest } = props;
  const classes = useStyles();
  // Item in back-end
  const itemId = id;
 
  //Item show on screen
  //const [itemsOnGraph, setItemOnGraph] = React.useState([]);
  // Year & Months
  const selectedYear = moment().format("YYYY");
  const thisMonth = moment().format("M");
  const availableMonths = Array.from({ length: 12 }, (v, k) => {
    return {
      value: k + 1,
      label: moment(k + 1, "M")
        .format("MMM")
        .toString()
    };
  });
  const [visbleMonths, setVisibleMonths] = React.useState([
    1,
    parseInt(thisMonth)
  ]);
  // Graph type - line for day, bar for months
  const [typeGraph, setGraph] = React.useState("day");

  const handleGraph = type => {
    setGraph(type);
  };

  const handleSlider = (event, range) => {
    setVisibleMonths(range);
  };

  function showMonth(value) {
    return moment(value, "M")
      .format("MMM")
      .toString();
  }

  const refItem = itemAnalyticsYear(itemId, selectedYear);

  const [
    collectionAnalytics,
    loadingAnalytics,
    errorAnalytics
  ] = useCollectionData(refItem);

  const itemsOnGraph = loadingAnalytics
    ? []
    : _.filter(collectionAnalytics, function(i) {
        // Exclude day or month values depending on the type
        const lenghtToKeep = typeGraph === "day" ? 4 : 2;
        if (i.key.length !== lenghtToKeep) return false;
        const month = _.parseInt(i.key.substring(0, 2));
        const inRange = visbleMonths[0] <= month && month <= visbleMonths[1];
        return inRange;
      });

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
          variant="body2"
        >
          Detailed analytics
        </Typography>
        {errorAnalytics && (
          <Typography>Error: {JSON.stringify(errorAnalytics)}</Typography>
        )}
        {loadingAnalytics ? (
          <Skeleton variant="rectangular" width="99%" height={300} animation="wave" />
        ) : (
          <>
            <Slider
              value={visbleMonths}
              onChange={handleSlider}
              valueLabelDisplay="auto"
              aria-labelledby="range-slider"
              getAriaValueText={showMonth}
              step={1}
              marks={availableMonths}
              min={1}
              max={12}
            />
            <ButtonGroup
              color="primary"
              aria-label="outlined primary button group"
            >
              <Button onClick={() => handleGraph("day")}>Day</Button>
              <Button onClick={() => handleGraph("month")}> Month</Button>
            </ButtonGroup>
            <ResponsiveContainer width="99%" height={300}>
              <ComposedChart
                data={itemsOnGraph}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="1 1" />
                <XAxis dataKey="key" />
                <YAxis />
                <Tooltip />
                <Legend />
                {typeGraph === "month" ? (
                  <Bar
                    dtype="monotone"
                    dataKey="itemPutInAR"
                    name="Added to AR session"
                    fill="#8884d8"
                  />
                ) : (
                  <Area
                    type="monotone"
                    dataKey="itemPutInAR"
                    name="Added to AR session"
                    stroke="#8884d8"
                  />
                )}
                <Line
                  type="monotone"
                  dataKey="ItemWebsite"
                  name="Product URL clicked"
                  stroke="#8884d8"
                />
              </ComposedChart>
            </ResponsiveContainer>
          </>
        )}
        <div className={classes.difference}>
          <Typography className={classes.caption} variant="caption">
            Items added to an AR session
          </Typography>
        </div>
      </CardContent>
      <Divider />
    </Card>
  );
}

ItemAnalytics.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired
};
