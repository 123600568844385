import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import makeStyles from '@mui/styles/makeStyles';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { green } from "@mui/material/colors";
import CircularProgress from "@mui/material/CircularProgress";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { Alert, AlertTitle } from '@mui/material';
import Typography from "@mui/material/Typography";
import moment from "moment";
import { useForm, Controller } from "react-hook-form";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import DatePicker from '@mui/lab/DatePicker';
import TextField from "@mui/material/TextField";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {itemRef,  updateItemFields } from "../../../data/item";

const useStyles = makeStyles(() => ({
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%"
  }
}));

const StatusListingItem = ({ itemId }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const refItem = itemRef(itemId);
  var stopListing = moment();
  var startListing = moment();

  const [item, loading] = useDocumentData(refItem);

  let alertLevel = {
    level: "info",
    title: "Checking",
    message: "Checking listing status"
  };

  const [submit, setSubmit] = useState({
    result: 0,
    message: ""
  });

   if (!loading) {
    const now = moment();
    stopListing = item.StopDisplay
      ? moment(item.StopDisplay)
      : moment().add(888, "days");
    const daysLeft = parseInt(stopListing.diff(now, "days"), 10);
    startListing = item.StartDisplay
      ? moment(item.StartDisplay)
      : moment().subtract(888, "days");
    const daysUntil = parseInt(startListing.diff(now, "days"), 10);

    alertLevel =
      // If the StopDisplay date is in the paste
      daysLeft < 0
        ? {
            level: "error",
            title: "Not listed",
            message: ""
          }
        : // If both StartDisplay and StopDiplay are in the future
        daysUntil > 0
        ? {
            level: "info",
            title: "Pending listing ",
            message: moment().to(startListing)
          }
        : // If StartDisplay in the past and StopDiplay are in near future  (<30 days)
        daysLeft < 30
        ? {
            level: "warning",
            title: "Expiring soon",
            message: moment().to(stopListing)
          }
        : // If StartDisplay in the past and StopDiplay are in future  (>30 days)
          {
            level: "success",
            title: "Listed",
            message: "Expiring " + moment().to(stopListing)
          };
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { handleSubmit, control, reset } = useForm({
    mode: "onBlur"
  });

  useEffect(() => {
    !loading && reset(item);
}, [reset, loading, item]);

  const onSubmit = async(data) => {
    setSubmit({
      result: 1,
      message: "Loading"
    });
    try{
      await updateItemFields(itemId,
        {
          StartDisplay: data.StartDisplay,
          StopDisplay: data.StopDisplay
        })
        setSubmit({
          result: 3,
          message: "Item updated"
        });
        setOpen(false);
      }
      catch(error) {
        setSubmit({
          result: 2,
          message: error.message
        });
      }
  };

  return (
    <div>
      {loading ? (
        <CircularProgress size={24} className={classes.buttonProgress} />
      ) : (
        <>
          <Alert
            severity={alertLevel.level}
            action={
              <Button color="inherit" size="small" onClick={handleClickOpen}>
                UPDATE
              </Button>
            }
          >
            {" "}
            <AlertTitle>{alertLevel.title}</AlertTitle>
            {alertLevel.message}
          </Alert>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            {" "}
            <DialogTitle id="form-dialog-title">
              Share to your Social Media
            </DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
              <DialogContent>
                <DialogContentText>Current listing dates</DialogContentText>
                {submit.result > 1 && (
                  <div className={classes.resultAlert}>
                    <Alert severity={submit.result === 3 ? "success" : "error"}>
                      {submit.message}
                    </Alert>
                  </div>
                )}

                  <FormControl>
                    <InputLabel id="idStartDisplay" shrink>
                      Display after:
                    </InputLabel>
                    <Controller
                    render={({field: { onChange, value }}) => (     
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        clearable
                        inputFormat="dd/MM/yyyy"
                        renderInput={(params) => <TextField labelid="idStartDisplay"  {...params} />}
                        className={classes.textField}
                        onChange={(e) => onChange(e)}
                        value={value}
                      />
                      </LocalizationProvider>
                    )}
                    name="StartDisplay"
                    defaultValue={null}
                    control={control}
                  />
                  </FormControl>
                  <FormControl>
                    <InputLabel id="idStartStop" shrink>
                      Display until:
                    </InputLabel>
                    <Controller
                    render={({field: { onChange, value }}) => (     
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        clearable
                        inputFormat="dd/MM/yyyy"
                        renderInput={(params) => <TextField labelid="idStopDisplay"  {...params} />}
                        className={classes.textField}
                        onChange={(e) => onChange(e)}
                        value={value}
                      />
                      </LocalizationProvider>
                    )}
                    name="StopDisplay"
                    defaultValue={null}
                    control={control}
                  />
                  </FormControl>

                <Typography gutterBottom variant="body2">
                  Display after: {item.StartDisplayString}
                </Typography>
                <Typography gutterBottom variant="body2">
                  Do NOT display after: {item.StopDisplayString}
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <div className={classes.wrapper}>
                  <Button color="primary" type="submit">
                    Update dates
                  </Button>
                  {submit.result === 1 && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </div>
              </DialogActions>
            </form>
          </Dialog>
        </>
      )}
    </div>
  );
};

export default StatusListingItem;

StatusListingItem.propTypes = {
  itemId: PropTypes.string.isRequired
};
