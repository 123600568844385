import React from "react";
import {itemRef} from "../../data/item";
import Typography from "@mui/material/Typography";
import Alert from '@mui/material/Alert';
import Link from "@mui/material/Link";
import { useDocumentData } from "react-firebase-hooks/firestore";
import makeStyles from '@mui/styles/makeStyles';
import Avatar from "@mui/material/Avatar";
import LinearProgress from "@mui/material/LinearProgress";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
//import CardMedia from "@mui/material/CardMedia";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
//import _ from "lodash";
import "@google/model-viewer/dist/model-viewer";
import Box from "@mui/material/Box";

const useStyles = makeStyles(theme => ({
  content: {
    paddingTop: 56,
    height: "100%",
    [theme.breakpoints.up("sm")]: {
      paddingTop: 64
    }
  },
  card: {
    padding: theme.spacing(1),
    maxWidth: 800
  },
  gridItem: {
    textAlign: "center"
  },
  chip: {
    marginRight: theme.spacing(1)
  },
  avatar: {
    height: "50px",
    width: "50px",
    flexShrink: 0,
    flexGrow: 0
  },
  media: {
    height: 0,
    paddingTop: "56.25%" // 16:9
  },
  color: {
    width: "36px",
    height: "14px",
    borderRadius: "2px"
  },
  toolbarLogo: {
    height: 58
  }
}));

export default function PublicModel() {
  const classes = useStyles();
  const ricoh = "vUw3sLZXNOm7PKvjZJyq";
  //const warmemorial = "1FCRmGLscl2x699MIGbD";
  const itemId = ricoh; //warmemorial; //props.match.params.id;
  const glb = "https://firebasestorage.googleapis.com/v0/b/snapscene-sandbox-alban.appspot.com/o/public%2FModel%2Fricoh_copier.glb?alt=media&token=10bd2711-c1e2-4829-b959-4c2d4855863d";
  const usdz = "https://firebasestorage.googleapis.com/v0/b/snapscene-sandbox-alban.appspot.com/o/public%2FModel%2FModel-ricoh_copier.usdz?alt=media&token=8391e3db-6848-4dcd-8e89-fc4b257c0614";
  //const glb =  "https://firebasestorage.googleapis.com/v0/b/snapscene-sandbox-alban.appspot.com/o/public%2FModel%2Fsg_war_memorial.glb?alt=media&token=868f488b-165e-4e2f-bde6-a28b577cd288";
  //const usdz = "https://firebasestorage.googleapis.com/v0/b/snapscene-sandbox-alban.appspot.com/o/public%2FModel%2FModel-sg_war_memorial.usdz?alt=media&token=f441448a-e6b9-4850-acbc-118c02249009";
  //const hdr = "https://firebasestorage.googleapis.com/v0/b/snapscene-sandbox-alban.appspot.com/o/public%2FModel%2Fcolorful_studio_2k.hdr?alt=media&token=d3a35a87-9d6c-4367-8f51-4b7212f9b333";
  const refItem = itemRef(itemId);

  const decodeSize = (SizeObject, SizeParameter) => {
    if (typeof SizeObject === "undefined") {
      return "N/A";
    }
    return SizeObject[SizeParameter];
  };

  const [item, loading, error] = useDocumentData(refItem, {
    idField: "key",
    snapshotListenOptions: { includeMetadataChanges: true }
  });

  return <>
    <AppBar position="static" color="primary">
      <Toolbar variant="dense">
        <Link href="https://www.snapscene.sg">
          <img
            alt="Snapscene Logo"
            src="/images/SS_Horizontal_White.png"
            className={classes.toolbarLogo}
          />
        </Link>
      </Toolbar>
    </AppBar>
    <main className={classes.content}>
      {error && <Alert severity="warning">Error loading item: {error}</Alert>}
      {loading && <LinearProgress />}
      {!loading && !item && (
        <Alert severity="warning">Error item {itemId} not found</Alert>
      )}
      {!loading && item && (
        <Grid
          container
          spacing={1}
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Card className={classes.card}>
              <CardHeader
                avatar={
                  <Avatar
                    aria-label="logo"
                    className={classes.avatar}
                    src={item.imageAR}
                  >
                    No AR Image
                  </Avatar>
                }
                titleTypographyProps={{ variant: "h5" }}
                title={item.Name}
                subheader={item.Category + " / " + item.Style}
              />
              {/* <CardMedia
                className={classes.media}
                image={
                  _.has(item.imagesItem[0], "name")
                    ? item.imagesItem[0].name
                    : item.imageAR
                }
                title={item.Name}
              /> */}

              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Box>
                      <model-viewer
                        src={glb}
                        ios-src={usdz}
                        alt={item.Name}
                        //skybox-image={hdr}
                        auto-rotate
                        camera-controls
                        poster={item.imageAR}
                        exposure={4}
                        ar-scaled="fixed"
                        environment-change
                        shadow-intensity={1}
                        ar
                      />
                    </Box>

                    {/* <Link href={usdz} rel="ar">
                      <img
                        src="/images/ARKit_Glyph.svg"
                        alt={usdz}
                        style={{ width: 80, borderRadius: "50%" }}
                      />
                    </Link>
                    <Link href={glb} rel="ar">
                      Test GLB file.
                    </Link> */}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography gutterBottom variant="subtitle1">
                      Brand: {item.Brand}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography gutterBottom variant="subtitle1">
                      Designer: {item.Designer}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography gutterBottom variant="subtitle1">
                      Dimension: L{decodeSize(item.Size, "Depth") + " "}W
                      {decodeSize(item.Size, "Width")}H
                      {decodeSize(item.Size, "Height") + " "}
                    </Typography>
                  </Grid>{" "}
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">
                      Description: {item.Description}
                    </Typography>
                  </Grid>
                </Grid>
                <Typography gutterBottom variant="subtitle1">
                  Retailer product url:
                  {item.urlItemRetailer ? (
                    <Link href={item.urlItemRetailer}>
                      {item.urlItemRetailer}
                    </Link>
                  ) : (
                    "Not link defined"
                  )}
                </Typography>
              </CardContent>
            </Card>
          </Grid>{" "}
        </Grid>
      )}
    </main>
  </>;
}

