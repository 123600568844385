import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import makeStyles from '@mui/styles/makeStyles';
import { itemSchema } from "./Item-validation";
import { useSnackbar } from "notistack";
import UploadFile from "../../components/Upload-File";
import { useNavigate } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import { useDocumentData } from "react-firebase-hooks/firestore";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { itemAlignments } from "./item-constant";
import DatePicker from '@mui/lab/DatePicker';
import { ReactSortable } from "react-sortablejs";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
//import _ from "lodash";
import AutocompleteRetailer from "../Retailer/Autocomplete-Retailer";
import AutocompleteModel from "../Model/Autocomplete-Model";
import PickerColour from "../StaticData/Colour/Picker-Colour";
import Divider from "@mui/material/Divider";
import { ImageCard } from "./Components/Image-card";
import ARImageForm from "../Item/Components/ARImage-Form";
import { yupResolver } from '@hookform/resolvers/yup';
import Input from "../../components/Input";
import Select from "../../components/Select";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { useParams } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import { updateDocument, updateFieldArrayRemove } from "../../data/api";
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { deleteFile } from "../../data/storage";
import { itemRef } from "../../data/item";
import { getRetailer } from "../../data/retailer";
import { getStaticData } from "../../data/static";
import { storageFullPath } from "../../data/storage"
import { firebaseApp } from "../../data/config";

const useStyles = makeStyles(theme => ({
  textField: {
    marginRight: theme.spacing(3)
  },
  multiLinetextField: {
    width: "100%",
    marginRight: theme.spacing(3)
  }
}));

export default function EditItem() {
  const classes = useStyles();

  const [isImageUploading, setImageUploading] = useState(false);
  const [imagesItem, setImagesItem] = useState([]);
  let navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const urlParams = useParams();
  const itemId = urlParams.id

  const itemImageStorage = "Item/" + itemId + "/Images/";
  const storage = getStorage(firebaseApp);

  const refItem = itemRef(itemId);

  //Get Static data
  const refDStaticData = getStaticData();
  const [staticData, loadingStatic, errorStatic] = useDocumentData(refDStaticData);

  if (!loadingStatic && errorStatic) {
    console.log(errorStatic);
  }

  const { handleSubmit, control,setValue, reset } = useForm({
    mode: "onBlur",
    resolver: yupResolver(itemSchema),
    //defaultValues: {}
  });

  const onSubmit = data => {
    data.imagesItem=imagesItem;
    updateDocument(refItem,data,enqueueSnackbar,navigate,"/show-item/" + itemId);
   };

   const [item, loading, ] = useDocumentData(refItem, {
    snapshotListenOptions: { includeMetadataChanges: true }
  });

  useEffect(() => {
    async function getRetailerDoc() {
            const initialValue = item.RetailerId;
            if (!initialValue) return;
            let retailer = await getRetailer(initialValue);
            if (retailer.exists) {
              const r = retailer.data();
              r.id = retailer.id;
              setValue('retailerId',r);
              }
    }
      !loading && reset(item);
      !loading && getRetailerDoc();
      !loading && item.imagesItem !== undefined && setImagesItem(item.imagesItem);

  }, [reset, loading, item]);

  const handleUploadImagesSuccess = async(uploadedFilename) => {
    const url = await getDownloadURL(ref(storage, itemImageStorage + '/' + uploadedFilename));
    setImagesItem(existingItem => [
          ...existingItem,
          { id: existingItem.length, name: url }
        ]);
   setImageUploading(false);
  };

  const handleDeletedImage = async(id, imageUrl) => {
    if (imageUrl === "") {
      enqueueSnackbar("Path incorrect: " + imageUrl, {
        variant: "error"
      });
      return;
    }

    try {
      //Delete database entry
      const item = itemRef(itemId);
      const previousArray = {
        id: id,
        name: imageUrl
      }
      await updateFieldArrayRemove(item, "imagesItem", previousArray, enqueueSnackbar);
          const imageRef = storageFullPath(imageUrl);
          await deleteFile(imageRef, enqueueSnackbar);
          setImagesItem(imagesItem.filter(image => image.id !== id));
          enqueueSnackbar("Image deleted", {
            variant: "success"
          });
        
    } catch (error) {
      enqueueSnackbar("Firebase Error" + error.message, {
        variant: "error"
      });
    }
  };

  const handleUploadStart = () => {
    setImageUploading(true);
  };

  return (
    <> 
    {loading && <LinearProgress />}
    {!loading && item &&
      <Grid container spacing={1}>
        <Grid item xs={12} md={9}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container direction="column" spacing={2}>
              <Grid item xs={12}>
                <Card>
                  <CardHeader subheader={"Item info"} />
                  <Divider />
                  <CardContent>
                    <Input name="Name" label="Name" control={control} />
                    <Input name="Brand" label="Brand" control={control} />
                    <Input name="Designer" label="Designer" control={control} />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                   <Input name="Description" label="Item description" control={control} multiline rows="5" />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <CardHeader subheader={"Dimensions"} />
                  <Divider />
                  <CardContent>
                  <Input name="Height" label="Height" control={control} InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">cm</InputAdornment>
                  )
                }}/>
              <Input name="Width" label="Width" control={control} InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">cm</InputAdornment>
                  )
                }}/>
                <Input name="Depth" label="Length" control={control} InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">cm</InputAdornment>
                  )
                }}/>  
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12}>
                <Card>
                  <CardHeader subheader={"Model information"} />
                  <Divider />
                  <CardContent>
                    {" "}
                    <AutocompleteModel control={control} name="ModelPath" />           
              {" "}
                    <AutocompleteModel control={control} name="ModelPathGLB" format="GLB" />
              {" "}     
                    <Select control={control} name="Alignment" items={itemAlignments} />
                  </CardContent>
                  <Divider />
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <CardHeader subheader={"Retailer information"} />
                  <Divider />
                  <CardContent>
                    <AutocompleteRetailer control={control} name="retailerId" />{" "}
                    <Input name="Price" label="Price" control={control} InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  )
                }}/>
              <Input name="urlItemRetailer" label="Product url" control={control} type="url" />
                    
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <CardHeader subheader={"Filtering and selection"} />
                  <Divider />
                  <CardContent>
                    {!loadingStatic && <Select control={control} name="Category" items={staticData.Categories} />}
                     <PickerColour
                      setFormValue={color => {
                        setValue("primaryColor", color);
                      }}
                      currentValue={item.primaryColor}
                      label="Primary color"
                    />
                    <PickerColour
                      setFormValue={color => {
                        setValue("secondaryColor", color);
                      }}
                      currentValue={item.secondaryColor}
                      label="Secondoary color"
                    /> 
                     {!loadingStatic && <Select control={control} name="Style" items={staticData.Styles} />}
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12}>
                <Card>
                  <CardHeader subheader={"Display dates"} />
                  <Divider />
                  <CardContent>
                  <FormControl>
                  <InputLabel id="idStartDisplay" shrink>
                    Display after:
                  </InputLabel>
                  <Controller
                    render={({field: { onChange, value }}) => (     
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        clearable
                        inputFormat="dd/MM/yyyy"
                        renderInput={(params) => <TextField labelid="idStartDisplay"  {...params} />}
                        className={classes.textField}
                        onChange={(e) => onChange(e)}
                        value={value}
                      />
                      </LocalizationProvider>
                    )}
                    name="StartDisplay"
                    defaultValue={null}
                    control={control}
                  />
                </FormControl>
                <FormControl>
                  <InputLabel id="idStartStop" shrink>
                    Display until:
                  </InputLabel>
                  <Controller
                    render={({field: { onChange, value }}) => (     
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        clearable
                        inputFormat="dd/MM/yyyy"
                        renderInput={(params) => <TextField labelid="idStopDisplay"  {...params} />}
                        className={classes.textField}
                        onChange={(e) => onChange(e)}
                        value={value}
                      />
                      </LocalizationProvider>
                    )}
                    name="StopDisplay"
                    defaultValue={null}
                    control={control}
                  />
                </FormControl>  
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12}>
                <Button
                  variant="contained"
                  size="medium"
                  color="primary"
                  type="submit"
                  disabled={isImageUploading}
                >
                  Save details
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
        <Grid item xs={12} md={3}>
          <Grid container direction="column" spacing={2}>
            <Grid item xs={12}>
              <ARImageForm itemId={itemId} enqueueSnackbar={enqueueSnackbar} />
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardHeader
                  title={"Gallery Images"}
                  subheader="Drag and drop to change the order"
                />
                <Divider />
                <CardContent>
                  {Array.isArray(imagesItem) && imagesItem.length > 0 && (
                    <ReactSortable list={imagesItem} setList={setImagesItem}>
                      {imagesItem.map(ImagePath => (
                        <ImageCard
                          key={ImagePath.id}
                          imageId={ImagePath.id}
                          imageUrl={ImagePath.name}
                          deleteImage={handleDeletedImage}
                        />
                      ))}
                    </ReactSortable>
                  )}
                </CardContent>
                <Divider />
                <CardActions>
                  <Button
                    variant="outlined"
                    size="medium"
                    color="primary"
                    component="label"
                    onClick={handleUploadStart}
                  >
                    Add Images to the gallery
                    <UploadFile
                      storePath={'Item/' + itemId + '/' +  'Images'}
                      fileUrl={handleUploadImagesSuccess}
                      multiple
                      enqueueSnackbar={enqueueSnackbar}
                    />
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
   }
    </>
  );
}
