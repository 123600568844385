import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Alert from '@mui/material/Alert';
import makeStyles from '@mui/styles/makeStyles';
import InputAdornment from "@mui/material/InputAdornment";
//import LinkIcon from "@mui/icons-material/Link";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useForm } from "react-hook-form";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import _ from "lodash";
import { itemRetailerSchema } from "../Item-validation";
import Input from "../../../components/Input";
import { updateItemFields } from "../../../data/item";

const useStyles = makeStyles(theme => ({
  resultAlert: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2)
    }
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative"
  }
}));

const FromItem = ({ itemId, item }) => {
  const [open, setOpen] = React.useState(false);

  const classes = useStyles();

  const [submit, setSubmit] = useState({
    result: 0,
    message: ""
  });

  const { handleSubmit, control, reset } = useForm({
    mode: "onBlur",
    validationSchema: itemRetailerSchema
  });

  useEffect(() => {
    reset(item)
}, [reset, item]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async(data) => {
    setSubmit({
      result: 1,
      message: "Loading"
    });

    try{
      await updateItemFields(itemId,
        {
        Description: _.trim(data.Description),
        Price: data.Price || 0,
        urlItemRetailer: data.urlItemRetailer || "",
      })

        setSubmit({
          result: 3,
          message: "Item updated"
        });
        setOpen(false);
      
    }
    catch (error) {
        setSubmit({
          result: 2,
          message: error.message
        });
      }
  };

  return (
    <div>
      <Button color="primary" size="small" fullWidth onClick={handleClickOpen}>
        EDIT ITEM
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id="form-dialog-title">Update this item</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Use the fields below to update your item. Please contact us if you
              are not able to update a field.
            </DialogContentText>
            {submit.result > 1 && (
              <div className={classes.resultAlert}>
                <Alert severity={submit.result === 3 ? "success" : "error"}>
                  {submit.message}
                </Alert>
              </div>
            )}
            <Input name="Description" label="Item description" control={control} multiline rows={4} />
            <Input name="urlItemRetailer" label="Product url" control={control} type="url" />
            <Input name="Price" label="Price" control={control} InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  )
                }}/>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <div className={classes.wrapper}>
              <Button color="primary" type="submit">
                Update Item
              </Button>
              {submit.result === 1 && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default FromItem;

FromItem.propTypes = {
  itemId: PropTypes.string.isRequired,
  item: PropTypes.object
};
