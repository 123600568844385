/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from "react";
import PropTypes from "prop-types";
import { List } from "@mui/material";
import {ListItemLink} from "../../../../../../components/ListItemLink"

const SidebarNav = props => {
  const { pages, ...rest } = props;

  return (
    <List {...rest} >
      {pages.map(page => (
        <ListItemLink
        to={page.href}
        key={page.title}
        primary={page.title}
        icon={page.icon}
      />
      ))}
    </List>
  );
};

SidebarNav.propTypes = {
  pages: PropTypes.array.isRequired
};

export default SidebarNav;
