import React from "react";
import PropTypes from "prop-types";
import SelectMui from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useController } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";

/* eslint-disable no-unused-vars */
function Select({ control, name, items, ...option }) {
    const {
      field: { onChange, onBlur, nameInput, value, ref },
      fieldState: { invalid, isTouched, isDirty, error },
      formState: { touchedFields, dirtyFields }
    } = useController({
      name,
      control,
      rules: { required: true },
      defaultValue: "",
    });
  
    return (
      <FormControl
                  // className={classes.textField}
                  // margin="normal"
                  error={invalid}
                >
                  <InputLabel id="idAlignment" shrink>
                  {nameInput}
                  </InputLabel>
      <SelectMui
         labelId= {"id"+nameInput}
         value={value} 
         displayEmpty
         onChange={onChange} 
      >
                        <MenuItem value="">
                          <em>Not defined</em>
                        </MenuItem>
                        {items.map(option => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
      </SelectMui>
      {invalid && (
                    <FormHelperText id="idAlignment">
                      {error.message}
                    </FormHelperText>
                  )}
                  </FormControl> 
    );
  }

  export default Select;
  
  Select.propTypes = {
    control: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired
  
  };