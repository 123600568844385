import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import { LinkRef } from "./design/button-link";

export default function Panel({tabs, links, selected}) {
  const [value, setValue] = useState("0");
  const showLinks = links.length != 0 ? true : false;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const position = selected || '0';
    setValue(position);
}, [selected]);
  

  return (
      <Paper sx={{ mt: 3 }}>
          { showLinks && <Box sx={{ height: "42px",    display: "flex", }}>
          {links.map( (link, index)  => (
              <Button
              variant="outlined"
              size="medium"
              color="primary"
              sx={{ m: 1 }}
              component={LinkRef}
              to={link.to}
              key={index}
            >
              {link.name}
            </Button>
          ))
          }

          </Box>}
    <Box sx={{ width: '100%', typography: 'body1' }}>
        
      <TabContext value={value}>
       <AppBar position="static">
          <TabList onChange={handleChange} aria-label="Tabs" indicatorColor="primary" 
            textColor="inherit" >
          {tabs.map((tab, index) => (
  <Tab
    label={tab.label}
    id={`tabs-tab-${index}`}
    key={tab.label}
    value={tab.position}
  />
))}
          </TabList></AppBar> 
        {tabs.map( (tab, index)  => (
               <TabPanel value={tab.position} key={tab.label} id={`tabs-panel-${index}`}>{tab.content}</TabPanel>
            ))}
      </TabContext>
    </Box>
    </Paper>
  );
}

 Panel.propTypes = {
  tabs: PropTypes.array.isRequired,
  links: PropTypes.array.isRequired,
  selected: PropTypes.string,
};