import React from "react";
import TableRetailer from "./Table-Retailer";
import TabPanel from "../../components/TabPanel";

export default function ListRetailers() {
  const links = [
    {position: "0", name: "Add Retailer", to: "/create-retailer"},
    {position: "1", name: "Add Item", to: "/create-item"},
  ]
  const tabs =[
    {position: "0", label:"Active Retailer", content:<TableRetailer deleted={false} />},
    {position: "1", label:"Deleted Retailer", content:<TableRetailer deleted={true} />},
  ]

  return (
        <TabPanel tabs={tabs} links={links} />
  );
}
