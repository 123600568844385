import React, { useState, useEffect } from "react";
import { PhotoshopPicker } from "react-color";
import PropTypes from "prop-types";
import makeStyles from '@mui/styles/makeStyles';
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles(() => ({
  color: {
    width: "36px",
    height: "14px",
    borderRadius: "2px"
  },
  swatch: {
    padding: "2px",
    background: "#fff",
    borderRadius: "1px",
    boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
    display: "inline-block",
    cursor: "pointer"
  }
}));

export default function PickerColour(props) {
  const classes = useStyles();
  const { setFormValue, currentValue, label } = props;
  const [currentColor, setCurrentColor] = useState(currentValue || { hex: "" });
  const [newColor, setNewColor] = useState(currentValue || { hex: "" });
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    const color = currentValue || { hex: "#ffffff" };
    setNewColor(color);
  }, [currentValue]);

  const onCancel = () => {
    setNewColor(currentColor);
    setAnchorEl(null);
  };
  const onAccept = () => {
    setAnchorEl(null);
    setFormValue(newColor);
  };
  const openPicker = event => {
    setAnchorEl(event.currentTarget);
    setCurrentColor(newColor);
  };

  const open = Boolean(anchorEl);
  const id = open ? "pick-color-popover" : undefined;

  return (
    <>
      <Typography variant="caption" display="block">
        {label}
      </Typography>

      <Box className={classes.swatch}>
        <Tooltip title={"RGB:" + newColor.hex} placement="top-start">
          <Button
            onClick={openPicker}
            className={classes.color}
            style={{ backgroundColor: newColor.hex }}
          >
            {" "}
          </Button>
        </Tooltip>
      </Box>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onCancel}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        <PhotoshopPicker
          color={newColor}
          onChange={color => setNewColor(color)}
          onCancel={onCancel}
          onAccept={onAccept}
        />
      </Popover>
    </>
  );
}

PickerColour.propTypes = {
  currentValue: PropTypes.object,
  setFormValue: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired
};
