import React from "react";
import { TableData, SelectColumnFilter } from "../../../components/TableData";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { Link } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import makeStyles from '@mui/styles/makeStyles';
import { getStaticData } from "../../../data/static";

const useStyles = makeStyles(theme => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
    display: "inline-flex",
    fontSize: "14px",
    fontWeight: 500,
    height: "36px",
    width: "36px"
  }
}));

export default function TableCategory() {
  const classes = useStyles();
  const [value, loading, error] = useDocumentData(getStaticData(), {
    idField: "key"
  });
  const columns = React.useMemo(
    () => [
      {
        Header: "Image",
        Cell: function renderCellImage(page) {
          return (
            <Avatar
              alt={page.row.original.label}
              src={page.row.original.imagePath}
              className={classes.avatar}
            />
          );
        },
        id: "LogoImagePath"
      },
      {
        Header: "Category Label (English)",
        accessor: "label",
        Cell: function renderCellLabel(page) {
          return (
            <Link to={`/edit-category/${page.row.original.value}`}>
              {page.row.original.label}
            </Link>
          );
        },
        Filter: SelectColumnFilter,
        filter: "includes",
        sortType: "basic"
      },
      {
        Header: "Category internal name",
        accessor: "value",
        Filter: SelectColumnFilter,
        filter: "includes",
        sortType: "basic"
      },
      {
        Header: "Order",
        accessor: "order",
        sortType: "basic"
      },
      {
        Header: "Display (old value)",
        accessor: "display",
        Cell: page => {
          return page.row.original.display ? "True" : "False";
        }
      },
      {
        Header: "Display (new value)",
        accessor: "displayCategory",
        Filter: SelectColumnFilter,
        filter: "includes",
        sortType: "basic"
      }
    ],
    [classes.avatar]
  );
  const categories = React.useMemo(() => {
    if (loading) {
      return [];
    }
    if (!loading && error) {
      console.log(error);
    }
    return value.Categories;
  }, [loading, value, error]);

  return (
    <div>
      <TableData columns={columns} data={categories} />
    </div>
  );
}
