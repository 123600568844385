import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Button from "@mui/material/Button";
import { retailerSchema } from "./Retailer-validation";
import Avatar from "@mui/material/Avatar";
import { useSnackbar } from "notistack";
import UploadFile from "../../components/Upload-File";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import { yupResolver } from '@hookform/resolvers/yup';
import Input from "../../components/Input";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useParams } from "react-router-dom";
import { useDocumentData } from "react-firebase-hooks/firestore";
import LinearProgress from "@mui/material/LinearProgress";
import { updateDocument } from "../../data/api";
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { retailerRef } from "../../data/retailer";
import {firebaseApp} from "../../data/config";

export default function EditRetailer() {
  const [isImageUploading, setImageUploading] = useState(false);
  const [imageLogo, setimageLogo] = useState("");
  const [imageBanner, setimageBanner] = useState("");
  let navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const urlParams = useParams();
  const retailerId = urlParams.id;
  const refRetailer = retailerRef(retailerId);

  const storage = getStorage(firebaseApp);
  const retailerImageStorage = "Retailer/" + retailerId + "/Image/";

  const { handleSubmit, reset, control } = useForm({
    resolver: yupResolver(retailerSchema),
    defaultValues: ""
  });
  const onSubmit = data => {
    data.imageLogo= imageLogo;
    data.imageBanner= imageBanner;
    console.log(data);
    updateDocument(refRetailer,data,enqueueSnackbar,navigate,"/show-retailer/" + retailerId);
  };

  const [retailer, loading, ] = useDocumentData(refRetailer, {
    snapshotListenOptions: { includeMetadataChanges: true }
  });

  useEffect(() => {
      if(!loading){ 
        reset(retailer);
        setimageLogo(retailer.LogoImagePath);
        setimageBanner(retailer.BannerImagePath);
    }
  }, [reset, loading, retailer]);

  const handleUploadSuccess = async (uploadedFilename, filename) => {
    const url = await getDownloadURL(ref(storage, retailerImageStorage + '/' + uploadedFilename));
    filename === "logo" ? setimageLogo(url) : setimageBanner(url)
    setImageUploading(false);
  };

  const handleUploadStart = () => {
    setImageUploading(true);
  };

  return (
    <>
    {loading && <LinearProgress />}
    {!loading && 
      
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Card>
              <CardActionArea>
                {imageBanner && (
                  <CardMedia
                    sx={{ height: 200 }}
                    image={imageBanner}
                    title={retailer.Name}
                  />
                )}
              </CardActionArea>
              <CardActions disableSpacing>
                <Grid container justifyContent="space-between">
                  <Button
                    size="small"
                    color="primary"
                    component="label"
                    onClick={handleUploadStart}
                  >
                    <UploadFile
                      fileUrl={handleUploadSuccess}
                      storePath={retailerImageStorage}
                      fileName={"banner"}
                      enqueueSnackbar={enqueueSnackbar}
                    />
                    Upload Banner image
                  </Button>
                </Grid>
              </CardActions>
            </Card>
          </Grid>

          <Grid item xs={12} md={9}>
            <Card>
              <CardActions disableSpacing>
                <Button
                  variant="contained"
                  size="medium"
                  color="primary"
                  type="submit"
                  disabled={isImageUploading}
                >
                  Save details
                </Button>
              </CardActions>
              <CardContent>
              <Input name="Name" label="Retailer Name" control={control} />
              <Input name="Country" label="Country" control={control} />
              <Input name="Email" label="Email" control={control} type="email" />
              <Input name="Address" label="Retailer Address" control={control} multiline rows="3" />
              <Input name="Contact" label="Retailer Phone Number" control={control} />
              <Input name="whatsapp" label="Whatsapp number" control={control} />
              <Input name="Website" label="Retailer Website" control={control} type="url" />
              <Input name="Description" label="Retailer Description" control={control} multiline rows="10" />
              <FormControl component="fieldset">
          <FormLabel component="legend">Display option: </FormLabel>
              <Controller
  control={control}
  name="display"
  defaultValue=""
  render={({ field }) => (
              <RadioGroup {...field}>
                    <FormControlLabel
                      value="hide"
                      control={<Radio color="primary" />}

                      label="Hide"
                    />
                    <FormControlLabel
                      value="show"
                      control={<Radio color="primary" />}

                      label="Show"
                    />
                    <FormControlLabel
                      value="showAdminOnly"

                      control={<Radio color="primary" />}
                      label="Show to admin profile only"
                    />
                  </RadioGroup>)}
                />
               </FormControl>
              
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card>
              <CardContent>
                <Avatar sx={{ width: 110, height: 110 }} src={imageLogo} />
              </CardContent>
              <CardActions disableSpacing>
                <Button
                  size="small"
                  color="primary"
                  component="label"
                  onClick={handleUploadStart}
                >
                  <UploadFile
                    fileUrl={handleUploadSuccess}
                    storePath={retailerImageStorage}
                    fileName={"logo"}
                    enqueueSnackbar={enqueueSnackbar}
                  />
                  Upload Icon image
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </form>
}
    </>
  
  );
}

