import * as yup from "yup";

export const retailerSchema = yup.object().shape({
  Name: yup
    .string()
    .min(2)
    .max(30)
    .required(),
  Country: yup
    .string()
    .min(3)
    .max(30),
  Email: yup.string().email(),
  Contact: yup.string(),
  Website: yup.string().url(),
  Whatsapp: yup.string()
});

export const retailerProfileSchema = yup.object().shape({
  Email: yup.string().email(),
  Contact: yup.string(),
  Website: yup.string().url(),
  Whatsapp: yup.string()
});
