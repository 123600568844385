import React from "react";
import TableModels from "./Table-models";
import TabPanel from "../../components/TabPanel";

export default function ListModels() {
  const links = []
  const tabs =[
    {position: "0", label:"USDZ", content:<TableModels />},
    {position: "1", label:"GLB", content:<TableModels format="GLB" />},
  ]

  return (
      <TabPanel tabs={tabs} links={links} />
  );
}
