import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import { LinkRef } from "../../components/design/button-link";
import Button from "@mui/material/Button";
import Alert from '@mui/material/Alert';
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import TableItemsRetailer from "../Item/Table-Items-Retailer";
import ShowAudit from "../../components/Show-Audit";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Link from "@mui/material/Link";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PublicIcon from "@mui/icons-material/Public";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import BusinessIcon from "@mui/icons-material/Business";
import LinkIcon from "@mui/icons-material/Link";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import Divider from "@mui/material/Divider";
import Box from '@mui/material/Box';
import PaperWithTitle from "../../components/PaperWithTitle";
import { useParams } from "react-router-dom";
import { getDocs, updateDoc  } from 'firebase/firestore';
import { retailerRef } from "../../data/retailer";
import { itemsAllRetailer } from "../../data/item";

function RetailerDetails(props) {
  const { retailerId } = props;

  const refRetailer = retailerRef(retailerId);
  const [retailer, loading, error] = useDocumentData(refRetailer, {
    snapshotListenOptions: { includeMetadataChanges: true }
  });
  let navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const updateIsDeleted = async () => {
    // Check if any item as this retailer as a reference
    const item = itemsAllRetailer(retailerId);
    const queryItem = await getDocs(item);
      // If query return no item then only we delete
      if (retailer.isDeleted === true || queryItem.empty) {
        let currentDeletedValue = retailer.isDeleted;
        try{
        await updateDoc(refRetailer, {
          isDeleted: !currentDeletedValue
        });
        let successMsg = !currentDeletedValue ? "deleted" : "restored";
         enqueueSnackbar("Retailer has been " + successMsg + ".", {
              variant: "success"
        });
        retailer.isDeleted = !currentDeletedValue;

         if (!currentDeletedValue) {
              navigate("/list-retailers");
            }

          } catch(error)  {
            enqueueSnackbar("Firebase error when deleting." + error, {
              variant: "error"
            });
          }
      } else {
        enqueueSnackbar(
          "Retailer has existing items. Please delete these items first.",
          {
            variant: "warning"
          }
        );
      }
  };

  if (loading) return <Typography>Loading</Typography>;

  if (!loading && error)
    return <Alert severity="warning">Error loading retailer: {error}</Alert>;

  if (!retailer)
    return (
      <Alert severity="warning">Error retailer {retailerId} not found</Alert>
    );

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Card>
          <CardActionArea>
            <CardMedia
              sx={{ height: 200 }}
              image={retailer.BannerImagePath}
              title={retailer.Name}
            />
          </CardActionArea>
        </Card>
      </Grid>

      {retailer.isDeleted && (
        <Grid item xs={12}>
          <Alert severity="warning">This retailer has been deleted!</Alert>{" "}
        </Grid>
      )}

      <Grid item xs={12} md={6}>
        <Card>
          <CardHeader
            avatar={
              <Avatar
                aria-label="logo"
                sx={{ width: 110, height: 110 }}
                src={retailer.LogoImagePath}
              >
                No Logo
              </Avatar>
            }
            titleTypographyProps={{ variant: "h3" }}
            title={retailer.Name}
            subheader={"Liked: " + retailer.likeCount}
          />
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p">
              {retailer.Description}
            </Typography>
          </CardContent>
          <Divider />
          <CardActions disableSpacing>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Button
                  size="small"
                  color="primary"
                  component={LinkRef}
                  to={`/edit-retailer/${retailer.key}`}
                >
                  Edit
                </Button>
              </Grid>{" "}
              <Grid item>
                {retailer.isDeleted ? (
                  <Button
                    size="small"
                    color="secondary"
                    component="button"
                    onClick={updateIsDeleted}
                  >
                    Restore
                  </Button>
                ) : (
                  <IconButton
                    aria-label="delete"
                    sx={{ m: 1 }}
                    onClick={updateIsDeleted}
                    size="large">
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card>
          <CardHeader title="Contact Card" />
          <CardContent>
            <Typography variant="body2" component="p">
              <PublicIcon />
              {retailer.Country}
            </Typography>
            <Typography variant="body2" component="p">
              <PhoneIphoneIcon />
              {retailer.Contact}
            </Typography>
            <Typography variant="body2" component="p">
              <WhatsAppIcon />
              {retailer.whatsapp}
            </Typography>
            <Typography variant="body2" component="p">
              <MailOutlineIcon />
              {retailer.Email}
            </Typography>
            <Typography variant="body2" component="p">
              <BusinessIcon /> {retailer.Address}
            </Typography>
            <Typography variant="body2" component="p">
              <LinkIcon />
              {retailer.Website ? (
                <Link href={retailer.Website}>{retailer.Website}</Link>
              ) : (
                "No link defined"
              )}
            </Typography>
            <Typography variant="body2" component="p">
              <TouchAppIcon />
              {retailer.dynamicLink ? (
                <Link href={retailer.dynamicLink}>{retailer.dynamicLink}</Link>
              ) : (
                "No link defined"
              )}
            </Typography>
          </CardContent>
          <Divider />
          <CardActions>
            <Button
              size="small"
              component={LinkRef}
              to={`/edit-retailer/${retailer.key}`}
            >
              EDIT
            </Button>
          </CardActions>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <PaperWithTitle title="List of items:">
            <TableItemsRetailer idRetailer={retailer.key} />
        </PaperWithTitle>
      </Grid>
      <Grid item xs={12}>
      <PaperWithTitle title="Audit Trail:">
      <ShowAudit id={retailer.key} collection="Retailer" />
        </PaperWithTitle>
      </Grid>
    </Grid>
  );
}

RetailerDetails.propTypes = {
  retailerId: PropTypes.string.isRequired
};

export default function ShowRetailer() {
  const urlParams = useParams();
  const retailerId=urlParams.id

  return (
      <Box sx={{mt:3, width: "100%"}}>
        <RetailerDetails retailerId={retailerId} />
      </Box>
  );
}
