import React, { useEffect, useState } from "react";
import TableStyle from "./Style/Table-Style";
import TableColour from "./Colour/Table-Colour";
import TableCategory from "./Category/Table-Category";
import TabPanel from "../../components/TabPanel";
import { useParams } from "react-router-dom";

export default function ListStaticData() {
  const [value, setValue] = useState("0");
  const urlParams = useParams();
  const tab = urlParams.tab;

  const links = [
    {position: "0", name: "Add New Category", to: "/edit-category/new_entry"},
    {position: "1", name: "Add New Style", to: "/edit-style/new_entry"},
    {position: "2", name: "Add New Colour", to: "/edit-colour/new_entry"},
  ]
  const tabs =[
    {position: "0", label:"Categories", content:<TableCategory />},
    {position: "1", label:"Styles", content:<TableStyle />},
    {position: "2", label:"Colours", content:<TableColour />},
  ]

  useEffect(() => {
    let tabId = "0";
    switch (tab) {
      case "style":
        tabId = "1";
        break;
      case "colour":
        tabId = "2";
        break;

      default:
        //  "Category" is the default
        tabId = "0";
        break;
    }
    setValue(tabId);
  }, [tab]);

  return (
      <TabPanel tabs={tabs} links={links} selected={value} />
  );
}

