/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import makeStyles from '@mui/styles/makeStyles';
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useForm, Controller } from "react-hook-form";
import Paper from "@mui/material/Paper";
import UploadFile from "../../components/Upload-File";
import { useSnackbar } from "notistack";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import { itemSchema } from "./Item-validation";
import InputAdornment from "@mui/material/InputAdornment";
import { useDocumentData } from "react-firebase-hooks/firestore";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { itemAlignments } from "./item-constant";
import DatePicker from '@mui/lab/DatePicker';
import { ReactSortable } from "react-sortablejs";
import AutocompleteRetailer from "../Retailer/Autocomplete-Retailer";
import AutocompleteModel from "../Model/Autocomplete-Model";
import Divider from "@mui/material/Divider";
import { ImageCard } from "./Components/Image-card";
import _ from "lodash";
import { LinkRef } from "../../components/design/button-link";
import PickerColour from "../StaticData/Colour/Picker-Colour";
import { yupResolver } from '@hookform/resolvers/yup';
import Input from "../../components/Input";
import Select from "../../components/Select";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { getFirestore, doc, collection, updateDoc } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { createDocument, updateFieldArrayRemove } from "../../data/api";
import { deleteFile } from "../../data/storage";
import { itemRef } from "../../data/item";
import { getRetailer } from "../../data/retailer";
import { getStaticData } from "../../data/static";
import { storageFullPath } from "../../data/storage"
import { firebaseApp } from "../../data/config";
import {itemsCollection} from "../../data/item";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3)
  },
  textField: {
    width: "420px",
    maxWidth: "100%",
    marginRight: theme.spacing(3)
  },
  multiLinetextField: {
    width: "840px",
    maxWidth: "100%",
    marginRight: theme.spacing(3)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

function ItemFieldForm(props) {
  const { nextStep } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const refDStaticData = getStaticData();
  const [staticData, loadingStatic, errorStatic] = useDocumentData(
    refDStaticData,
    {
      idField: "key",
      snapshotListenOptions: { includeMetadataChanges: true }
    }
  );

  if (!loadingStatic && errorStatic) {
    console.log(errorStatic);
  }

  const { handleSubmit, control, setValue,  register, formState: { errors }} = useForm({
    mode: "onBlur",
    resolver: yupResolver(itemSchema),
    defaultValues: ""
  });

  const onSubmit = async(data) => {
    console.log(data);
    data.imagesItem = [];
    const newItem = doc(itemsCollection())
    await createDocument(newItem,data,enqueueSnackbar);
    const newItemId = newItem.id;
    return nextStep(newItemId);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction="column" spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardHeader subheader={"Item info"} />
            <Divider />
            <CardContent>
              <Input name="Name" label="Name" control={control} />
              <Input name="Brand" label="Brand" control={control} />
              <Input name="Designer" label="Designer" control={control} />
            </CardContent>
          </Card>
        </Grid>
         <Grid item xs={12}>
          <Card>
            <CardContent>
              <Input name="Description" label="Item description" control={control} multiline rows="5" />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader subheader={"Dimensions"} />
            <Divider />
            <CardContent>
            <Input name="Height" label="Height" control={control} InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">cm</InputAdornment>
                  )
                }}/>
              <Input name="Width" label="Width" control={control} InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">cm</InputAdornment>
                  )
                }}/>
                <Input name="Depth" label="Length" control={control} InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">cm</InputAdornment>
                  )
                }}/>  
             
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardHeader subheader={"Model information"} />
            <Divider />
            <CardContent>
              {" "}
                <AutocompleteModel control={control} name="ModelPath" />           
              {" "}
              <AutocompleteModel control={control} name="ModelPathGLB" format="GLB" />
              {" "}     
                <Select control={control} name="Alignment" items={itemAlignments} />
                
            </CardContent>
            <Divider />
          </Card>
        </Grid> 
        <Grid item xs={12}>
          <Card>
            <CardHeader subheader={"Retailer information"} />
            <Divider />
            <CardContent>
              <AutocompleteRetailer control={control} name="retailerId" />{" "}
              <Input name="Price" label="Price" control={control} InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  )
                }}/>
              <Input name="urlItemRetailer" label="Product url" control={control} type="url" />
             {" "}
            </CardContent>
          </Card>
        </Grid> 
        <Grid item xs={12}>
          <Card>
            <CardHeader subheader={"Filtering and selection"} />
            <Divider />
            <CardContent>
            {!loadingStatic && <Select control={control} name="Category" items={staticData.Categories} />}
              <PickerColour
                setFormValue={color => {
                  setValue("primaryColor", color);
                }}
                label="Primary color"
              />
              <PickerColour
                setFormValue={color => {
                  setValue("secondaryColor", color);
                }}
                label="Secondoary color"
              />
              {!loadingStatic && <Select control={control} name="Style" items={staticData.Styles} />}
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardHeader subheader={"Display dates"} />
            <Divider />
            <CardContent>
                 <FormControl>
                  <InputLabel id="idStartDisplay" shrink>
                    Display after:
                  </InputLabel>
                  <Controller
                    render={({field: { onChange, value }}) => (     
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        clearable
                        inputFormat="dd/MM/yyyy"
                        renderInput={(params) => <TextField labelid="idStartDisplay"  {...params} />}
                        className={classes.textField}
                        onChange={(e) => onChange(e)}
                        value={value}
                      />
                      </LocalizationProvider>
                    )}
                    name="StartDisplay"
                    defaultValue={null}
                    control={control}
                  />
                </FormControl>
                <FormControl>
                  <InputLabel id="idStartStop" shrink>
                    Display until:
                  </InputLabel>
                  <Controller
                    render={({field: { onChange, value }}) => (     
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        clearable
                        inputFormat="dd/MM/yyyy"
                        renderInput={(params) => <TextField labelid="idStopDisplay"  {...params} />}
                        className={classes.textField}
                        onChange={(e) => onChange(e)}
                        value={value}
                      />
                      </LocalizationProvider>
                    )}
                    name="StopDisplay"
                    defaultValue={null}
                    control={control}
                  />
                </FormControl>  
            </CardContent>
          </Card>
        </Grid> 

        <Grid item xs={12}>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            type="submit"
          >
            Save details
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

ItemFieldForm.propTypes = {
  nextStep: PropTypes.func.isRequired
};

function ItemARImageForm(props) {
  const { nextStep, itemId } = props;
  const [imageAR, setImageAR] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [isImageUploading, setImageUploading] = useState(false);
  const itemImageStorage = "Item/" + itemId + "/Images/";
  const storage = getStorage(firebaseApp);

  const handleUploadStart = () => {
    setImageUploading(true);
  };

  const handleUploadSuccess = async(uploadedFilename) => {
    const url = await getDownloadURL(ref(storage, itemImageStorage + '/' + uploadedFilename));
    setImageAR(url);
    setImageUploading(false);
  };

  const handleSave = async() => {
    const refItem = itemRef(itemId);
    await updateDoc(refItem, {
      imageAR: imageAR,
      ImagePath: imageAR
    });
    return nextStep();
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardHeader title={"AR Image"} subheader="Image in AR view" />
          <Divider />
          <CardContent>
            {" "}
            <img src={imageAR} alt={imageAR} />
          </CardContent>
          <Divider />
          <CardActions>
            <Button
              variant="outlined"
              size="medium"
              color="primary"
              component="label"
              onClick={handleUploadStart}
            >
              <UploadFile
                fileUrl={handleUploadSuccess}
                storePath={itemImageStorage}
                fileName={"imageAR"}
                enqueueSnackbar={enqueueSnackbar}
              />
              Upload AR image
            </Button>
          </CardActions>
        </Card>{" "}
        <Grid item xs={12}>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={handleSave}
            disabled={isImageUploading}
          >
            Save AR Images and go to Image Gallery
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
ItemARImageForm.propTypes = {
  nextStep: PropTypes.func.isRequired,
  itemId: PropTypes.string.isRequired
};

function ItemImageGalleryForm(props) {
  const { nextStep, itemId } = props;
  const [imagesItem, setImagesItem] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [isImageUploading, setImageUploading] = useState(false);
  const itemImageStorage = "Item/" + itemId + "/Images/";
  const storage = getStorage(firebaseApp);

  const handleUploadImagesSuccess = async(uploadedFilename) => {
    const url = await getDownloadURL(ref(storage, itemImageStorage + '/' + uploadedFilename));
    setImagesItem(existingItem => [
          ...existingItem,
          { id: existingItem.length, name: url }
        ])
    setImageUploading(false);
  };

  const handleSave = async() => {
    const refItem = itemRef(itemId);
    await updateDoc(refItem, {
      imagesItem: imagesItem
    });
    return nextStep();
  };

  const handleUploadStart = () => {
    setImageUploading(true);
  };

  const handleDeletedImage = async(id, imageUrl) => {
    if (imageUrl === "") {
      enqueueSnackbar("Path incorrect: " + imageUrl, {
        variant: "error"
      });
      return;
    }

    try {
      //Delete database entry
      const item = itemRef(itemId);
      const previousArray = {
        id: id,
        name: imageUrl
      }
      await updateFieldArrayRemove(item, "imagesItem", previousArray, enqueueSnackbar);
          const imageRef = storageFullPath(imageUrl);
          await deleteFile(imageRef, enqueueSnackbar);
          setImagesItem(imagesItem.filter(image => image.id !== id));
          enqueueSnackbar("Image deleted", {
            variant: "success"
          });
    } catch (error) {
      enqueueSnackbar("Firebase Error" + error.message, {
        variant: "error"
      });
    }
  };

  return (
    <Paper>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title={"Gallery Images"}
              subheader="Drag and drop to change the order"
            />
            <Divider />
            <CardContent>
              {Array.isArray(imagesItem) && imagesItem.length > 0 && (
                <ReactSortable list={imagesItem} setList={setImagesItem}>
                  {imagesItem.map(ImagePath => (
                    <ImageCard
                      key={ImagePath.id}
                      imageId={ImagePath.id}
                      imageUrl={ImagePath.name}
                      deleteImage={handleDeletedImage}
                    />
                  ))}
                </ReactSortable>
              )}
            </CardContent>
            <Divider />
            <CardActions>
              <Button
                variant="outlined"
                size="medium"
                color="primary"
                component="label"
                onClick={handleUploadStart}
              >
                Add Images to the gallery
                <UploadFile
                      storePath={'Item/' + itemId + '/' +  'Images'}
                      fileUrl={handleUploadImagesSuccess}
                      multiple
                      enqueueSnackbar={enqueueSnackbar}
                    />
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={handleSave}
            disabled={isImageUploading}
          >
            Save AR Images and go to Image Gallery
          </Button>
        </Grid>{" "}
      </Grid>
    </Paper>
  );
}
ItemImageGalleryForm.propTypes = {
  nextStep: PropTypes.func.isRequired,
  itemId: PropTypes.string.isRequired
};

function ItemStepper() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [itemId, setItemnId] = useState("");
  const steps = [
    "Create a new item",
    "Select AR image",
    "Select images for Gallery"
  ];

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <ItemFieldForm nextStep={goStepTwo} />;
      case 1:
        return <ItemARImageForm nextStep={goNextStep} itemId={itemId} />;
      case 2:
        return <ItemImageGalleryForm nextStep={goNextStep} itemId={itemId} />;
      default:
        return "Unknown step";
    }
  }

  const goNextStep = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const goStepTwo = id => {
    setItemnId(id);
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={index} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Grid>
      <Grid item xs={12}>
        {activeStep === steps.length ? (
          <Paper>
            <Typography className={classes.instructions}>
              Item added to the back-end - you&apos;re finished
            </Typography>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              sx={{ m: 1 }}
              component={LinkRef}
              to={`/show-item/${itemId}`}
            >
              SEE ITEM
            </Button>
          </Paper>
        ) : (
          getStepContent(activeStep)
        )}
      </Grid>
    </Grid>
  );
}

export default function CreateItem() {
  const classes = useStyles();
  return (
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <ItemStepper />
        </Grid>
      </Grid>
  );
}
