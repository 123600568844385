import React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  card: {
    maxWidth: 300,
    margin: "auto",
    transition: "0.3s",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)"
    }
  },
  media: {
    height: 140
  }
}));

export const ImageCard = ({ imageId, imageUrl, deleteImage }) => {
  const classes = useStyles();

  const handleDelete = (id, url) => {
    return deleteImage(id, url);
  };

  return (
    <Grid item>
      <Card className={classes.card}>
        <CardActionArea>
          <CardMedia
            className={classes.media}
            image={imageUrl}
            title={imageUrl}
          />
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p">
              Current Position: {imageId}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button
            size="small"
            color="primary"
            onClick={() => handleDelete(imageId, imageUrl)}
          >
            Delete
          </Button>
          <Button size="small" color="primary">
            Open Image
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};

ImageCard.propTypes = {
  imageId: PropTypes.number.isRequired,
  imageUrl: PropTypes.string.isRequired,
  deleteImage: PropTypes.func.isRequired
};
