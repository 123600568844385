import { setDoc,arrayUnion, arrayRemove,  updateDoc } from 'firebase/firestore';



export const createDocument = 
async (doc, data, enqueueSnackbar) =>{
    try{
     enqueueSnackbar("Creating record..", {
        variant: "info"
      });  
    await setDoc(doc, data);
    enqueueSnackbar("Creation completed", {
       variant: "success"
     });
    } catch(error){
       enqueueSnackbar("Creation failed: " + error.message, {
         variant: "error"
     }); 
   }
 }



export const updateDocument = 
async (doc, data, enqueueSnackbar, navigate, url) =>{
    try{
     enqueueSnackbar("Updating record..", {
        variant: "info"
      });  
    await setDoc(doc, data, { merge: true });
    navigate && navigate(url);
    enqueueSnackbar("Update completed", {
       variant: "success"
     });
    } catch(error){
       enqueueSnackbar("Update failed: " + error.message, {
         variant: "error"
     }); 
   }
 }

 export const updateFieldArrayUnion = async(doc, field, array,enqueueSnackbar ) => {
    return await updateFieldArray (doc, field, array,enqueueSnackbar, "Union" )
 }

 export const updateFieldArrayRemove = async(doc, field, array,enqueueSnackbar ) => {
    return await updateFieldArray (doc, field, array,enqueueSnackbar, "Remove" )
}

const updateFieldArray = async(doc, field, array,enqueueSnackbar, arrayAction ) => {
  try{
    enqueueSnackbar("Updating array..", {
       variant: "info"
     });
     const arrayField = {}  
     arrayField[field]= arrayAction === "Union" ? arrayUnion(array) : arrayRemove(array);  
     await updateDoc(doc, arrayField);
     enqueueSnackbar("Update completed", {
      variant: "success"
    });
   } catch(error){
      enqueueSnackbar("Update failed: " + error.message, {
        variant: "error"
    }); 
  }
 }