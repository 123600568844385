import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import {
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
  Avatar,
  CardActions,
  Divider,
  Button
} from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { LinkRef } from "../../../components/design/button-link";
import {getUsers} from "../../../data/user";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%"
  },
  content: {
    alignItems: "center",
    display: "flex"
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.secondary,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: "flex",
    alignItems: "center"
  },
  differenceIcon: {
    color: theme.palette.error.dark
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: "flex-end"
  }
}));

export default function AllUsers(props) {
  const { className, ...rest } = props;
  const classes = useStyles();
  const [nbUsers, setNbUsers] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      getUsers().then((result =>{
      setNbUsers(result.data.data.nbUser);
      setLoading(false);
      }));
    };

    fetchData();
  }, []);

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              USERS
            </Typography>
            {loading && <CircularProgress />}
            {!loading && <Typography variant="h3">{nbUsers}</Typography>}
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <PeopleIcon className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid>
        <div className={classes.difference}>
          <Typography className={classes.caption} variant="caption">
            All users
          </Typography>
        </div>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        <Button
          color="primary"
          size="small"
          variant="text"
          component={LinkRef}
          to="/list-users"
        >
          View all <ArrowRightIcon />
        </Button>
      </CardActions>
    </Card>
  );
}

AllUsers.propTypes = {
  className: PropTypes.string
};
