import React from "react";
import List from "@mui/material/List";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import FilterIcon from "@mui/icons-material/Filter";
import InsertLink from "@mui/icons-material/InsertLink";
import ViewModule from "@mui/icons-material/ViewModule";
import PeopleIcon from "@mui/icons-material/People";
import {ListItemLink} from "../../components/ListItemLink"


export default function DrawerMenuItem() {

  return (
    <List component="nav" aria-label="Snapscene admin">
      <ListItemLink
        to="/dashboard-admin"
        primary="Dashboard"
        icon={<DashboardIcon />}
      />
      <ListItemLink
        to="/list-users"
        primary="Users"
        icon={<PeopleIcon />}
      />
      <ListItemLink
        to="/list-retailers"
        primary="Retailers"
        icon={<ShoppingCartIcon />}
      />
      <ListItemLink
        to="/list-items"
        primary="Items"
        icon={<FilterIcon />}
      />
      <ListItemLink
        to="/list-models"
        primary="Models"
        icon={<InsertLink />}
      />
      <ListItemLink
        to="/list-staticdata"
        primary="Static data"
        icon={<ViewModule />}
      />
    </List>
  );
}
