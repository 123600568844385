
import { getFirestore, doc } from 'firebase/firestore';
import {firebaseApp} from "./config";

export const getStaticData = () => {
    const fireapp = firebaseApp;
    return doc(getFirestore(fireapp), 'StaticData', 'StaticData')
   }

// Analytics

export const itemAnalyticsMonth = (year, month) =>{
    const fireapp = firebaseApp;
    doc(getFirestore(fireapp), 'Analytics','tapCategoryInChooseView',year, month);
  }
  