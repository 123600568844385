import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import { styleSchema } from "../StaticData-validation";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import SaveIcon from "@mui/icons-material/Save";
import TableItemsStaticData from "../../Item/Table-item-staticData";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import UploadFile from "../../../components/Upload-File";
import Input from "../../../components/Input";
import { useParams } from "react-router-dom";
import { updateFieldArrayUnion, updateFieldArrayRemove } from "../../../data/api";
import { getStaticData } from "../../../data/static";
import { staticDataStorage } from "../../../data/storage";
import { getDownloadURL } from "firebase/storage";
import { useDocumentData } from "react-firebase-hooks/firestore";

export default function EditStyle() {
  const [style, setStyle] = useState({
    value: "",
    label: "",
    imagePath: ""
  });
  // Define if the form is for edit a new entry
  const [newEntry, setNewEntry] = useState(false);
  const [image, setimage] = useState("");
  const [isImageUploading, setImageUploading] = useState(false);
  let navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const urlParams = useParams();
  const styleID = urlParams.id

  const { handleSubmit, reset, control } = useForm({
    validationSchema: styleSchema,
    defaultValues: {}
  });

  const [ staticDataDocument, loading, ] = useDocumentData(getStaticData());

  useEffect(() => {
     function getStyles() {
            const { Styles } = staticDataDocument;
            const sty = Styles.filter(function(s) {
              return s.value === styleID;
            });
            if (sty.length > 0) {
              setNewEntry(false);
              setStyle(sty[0]);
              reset(sty[0]);
              setimage(sty[0].imagePath);
            }
          }
          !loading && getStyles();

  }, [reset, staticDataDocument, loading, styleID]);

  useEffect(() => {
    if (styleID === "new_entry") {
      setNewEntry(true);
      reset(style);
    }
  }, [styleID, style, reset]);

  const onSubmit = async(data) => {
    data.imagePath = image;
    if (_.isEqual(data, style)) return;
    const submittedArray = {
          label: data.label,
          value: data.value,
          imagePath: data.imagePath
        }
    await updateFieldArrayUnion(getStaticData(), "Styles", submittedArray, enqueueSnackbar);
    if (newEntry != true) {
      const previousArray = {
        label: style.label,
        value: style.value,
        imagePath: style.imagePath
      }
      await updateFieldArrayRemove(getStaticData(), "Styles", previousArray, enqueueSnackbar);
    }
    navigate("/list-staticdata/style");
  };

  const handleUploadSuccess = async (uploadedFilename, ) => {
    const file = staticDataStorage("Styles", uploadedFilename);
    const url = await getDownloadURL(file)
    setimage(url);
    setImageUploading(false);
  };

  const handleUploadStart = () => {
    setImageUploading(true);
  };

  return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader
                title={newEntry ? "New Style " : "Style : " + style.label}
                subheader="Update Style information"
              />
              <Divider />
              <CardContent>
                <Input name="label" label="Style Name" control={control} />
                <Input name="value" label="Value" control={control} disabled={!newEntry} />
              </CardContent>
              <Divider />
              <CardActions>
                <Button
                  variant="contained"
                  size="medium"
                  color="primary"
                  type="submit"
                  startIcon={<SaveIcon />}
                  disabled={isImageUploading}
                >
                  Save details
                </Button>
              </CardActions>
            </Card>
          </Grid>{" "}
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader
                title="Style Image"
                subheader="Upload/Replace the Style banner"
              />
              <Divider />
              <CardContent>
                <img src={image} alt={image} />
              </CardContent>
              <Divider />
              <CardActions disableSpacing>
                <Button
                  size="small"
                  color="primary"
                  component="label"
                  onClick={handleUploadStart}
                  startIcon={<CloudUploadIcon />}
                >
                  <UploadFile
                    fileUrl={handleUploadSuccess}
                    storePath={"StaticData/Styles"}
                    fileName={style.value}
                    enqueueSnackbar={enqueueSnackbar}
                  />
                  Upload
                </Button>
              </CardActions>
            </Card>
          </Grid>
          {!newEntry && style.label && (
            <Grid item xs={12}>
              <Card>
                <CardHeader title="List of item(s) under this Style:" />
                <Divider />
                <CardContent>
                  <TableItemsStaticData
                    staticData="Style"
                    staticDataValue={style.label}
                  />
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      </form>
  );
}

