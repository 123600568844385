/*export const itemColours = [
  {
    value: "black",
    label: "black"
  },
  {
    value: "blue",
    label: "blue"
  },
  {
    value: "brown",
    label: "brown"
  },
  {
    value: "cream",
    label: "cream"
  },
  {
    value: "green",
    label: "green"
  },
  {
    value: "grey",
    label: "grey"
  },
  {
    value: "red",
    label: "red"
  },
  {
    value: "purple",
    label: "purple"
  },
  {
    value: "white",
    label: "white"
  },
  {
    value: "yellow",
    label: "yellow"
  },
  {
    value: "orange",
    label: "orange"
  }
];

export const itemStyles = [
  {
    value: "modern",
    label: "modern"
  },
  {
    value: "industrial",
    label: "industrial"
  },
  {
    value: "vintage",
    label: "vintage"
  },
  {
    value: "classic",
    label: "classic"
  }
];

export const itemCategories = [
  {
    value: "Chair",
    label: "Chair"
  },
  {
    value: "Table",
    label: "Table"
  },
  {
    value: "Sofa",
    label: "Sofa"
  },
  {
    value: "Lighting",
    label: "Lighting"
  },
  {
    value: "Rug",
    label: "Rug"
  },
  {
    value: "Decor",
    label: "Decor"
  },
  {
    value: "Festive",
    label: "Festive"
  }
];
*/
export const itemAlignments = [
  {
    value: 1,
    label: "Horizontal"
  },
  {
    value: 2,
    label: "Vertical"
  },
  {
    value: 255,
    label: "Horizontal & Vertical"
  }
];
