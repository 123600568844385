/* eslint-disable react/display-name */
import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import CssBaseline from "@mui/material/CssBaseline";
import { TableData } from "../../components/TableData";
import ShowModelURL from "../Model/Show-Model-Url";
import moment from "moment";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteIcon from "@mui/icons-material/Delete";
import { Typography } from "@mui/material";
import PropTypes from "prop-types";
import {modelStorage, getModelUpdated, deleteFile} from "../../data/storage"
import {modelUsed} from "../../data/item"
import { listAll } from "firebase/storage";

export default function TableModels({format}) {
  const [loading, setLoading] = useState(true);
  const [models, setModels] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [modelToDelete, setModelToDelete] = useState("");
  const selectedFormat = format === "GLB" ? "GLB" : "USDZ"; 
  const { enqueueSnackbar } = useSnackbar();

  function ShowDeleteButton({ modelReference }) {

    const [used, setUsed] = useState(true);

    useEffect(async () => {
      if (modelReference === undefined) return undefined;
      const fullPath = modelReference.fullPath;
      setUsed(await modelUsed(selectedFormat, fullPath ))
    }, [modelReference]);

    if (!used) {
      return (
        <Button
          onClick={() => {
            handleClickDelete(modelReference);
          }}
        >
          <DeleteIcon />
        </Button>
      );
    } else {
      return <Typography>In use</Typography>;
    }
  }

  ShowDeleteButton.propTypes = {
    modelReference: PropTypes.any.isRequired
  };

  function handleClickDelete(fullPath) {
    setModelToDelete(fullPath);
    setOpenDialog(true);
  }

  function handleCancelDelete() {
    setModelToDelete("");
    setOpenDialog(false);
    enqueueSnackbar("Model deletion has been canceled.");
  }

  async function handleConfirmDelete() {
    setOpenDialog(false);
    await deleteFile(modelToDelete, enqueueSnackbar);
    setLoading(true);
  }

  const columns = React.useMemo(
    () => [
      {
        Header: "File name",
        accessor: "name",
        sortType: "basic"
      },
      {
        Header: "File name",
        accessor: "fullPath",
        sortType: "basic"
      },
      {
        Header: "Update",
        sort: "desc",
        Cell: page => {
          const update = getModelUpdated(page.row.original.fullPath);
          return moment(update).format("Do MMMM YYYY, h:mm:ss a");
        },
        id: "update"
      },
      {
        Header: "Model",
        id: "ModelPath",
        Cell: page => {
          return <ShowModelURL fullPath={page.row.original.fullPath} />;
        }
      },
      {
        Header: "Delete?",
        id: "delete",
        Cell: page => <ShowDeleteButton modelReference={page.row.original} />,
        filterable: false
      }
    ],
    []
  );

  useEffect(() => {
    const storage = modelStorage(selectedFormat)
    listAll(storage).then(
      res => {
        const files = [];
        res.items.forEach(itemRef => {
          files.push(itemRef);
        });
        setLoading(false);
        setModels(files);
      },
      err => {
        console.log(err);
      }
    );
  }, [loading, selectedFormat]);

  return (
    <div>
      <CssBaseline />
      <TableData columns={columns} data={models} />
      <Dialog
        open={openDialog}
        onClose={handleCancelDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm deletion of this model?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            By clicking confirm, the model will be deleted from Firebase.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

TableModels.propTypes = {
  format: PropTypes.string,
};