import React from "react";
import { Link, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useDownloadURL } from "react-firebase-hooks/storage";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import {storageFullPath} from "../../data/storage";

function ShowModelURL(props) {
  const { fullPath } = props;

  const [value, loading, error] = useDownloadURL(storageFullPath(fullPath));

  return (
    <React.Fragment>
      {error && (
        <Typography component={"span"}>
          Error: {JSON.stringify(error)}
        </Typography>
      )}
      {loading && (
        <Typography component={"span"}>
          Loading Model URL...
        </Typography>
      )}
      {!loading && value && (
        <Link href={value} rel="ar">
          {value.indexOf(".glb?") > 0 ?
          ( 
           <CloudDownloadIcon /> 
          ):(
          <img
            src="/images/ARKit_Glyph.svg"
            alt={value}
            style={{ width: 80, borderRadius: "50%" }}
          />)}
        </Link>
      )}
    </React.Fragment>
  );
}

ShowModelURL.propTypes = {
  fullPath: PropTypes.string.isRequired
};

export default ShowModelURL;
