import React, { useState, useEffect } from "react";
import { TableData, SelectColumnFilter } from "../../components/TableData";
import {getUsersRetailer} from "../../data/user";
import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6)
  }
}));

const RetailerUser = ({ retailerId }) => {
  const classes = useStyles();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      getUsersRetailer(retailerId).then((result =>{
      setUsers(result.data.data.users);
    }));
  };

    fetchData();
  }, [retailerId]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Email",
        accessor: "email"
      },
      {
        Header: "Login method",
        accessor: "providerId",
        Filter: SelectColumnFilter
      },
      {
        Header: "Last sign-in",
        accessor: "lastSignInTime"
      },
      {
        Header: "Disabled?",
        accessor: r => (r.disabled ? "Yes" : "No"),
        Filter: SelectColumnFilter
      }
    ],
    []
  );
  return (
      <div className={classes.root}>
        <Container
          maxWidth="sm"
          component="main"
          className={classes.heroContent}
        >
          <Typography
            component="h4"
            variant="h5"
            align="center"
            color="textPrimary"
            gutterBottom
          >
            Users
          </Typography>
          <Typography
            variant="body"
            align="center"
            color="textSecondary"
            component="p"
          >
            List of users who can access Snapadmin and modify your data.
          </Typography>
        </Container>
        <Container maxWidth="md" component="main">
          <TableData columns={columns} data={users} />
        </Container>
      </div>
  );
};

export default RetailerUser;

RetailerUser.propTypes = {
  retailerId: PropTypes.string
};
