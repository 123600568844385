import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import { colourSchema } from "../StaticData-validation";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import SaveIcon from "@mui/icons-material/Save";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Input from "../../../components/Input";
import { useParams } from "react-router-dom";
import { updateFieldArrayUnion, updateFieldArrayRemove } from "../../../data/api";
import { getStaticData } from "../../../data/static";
import { useDocumentData } from "react-firebase-hooks/firestore";

export default function EditColour() {
  const [colour, setColour] = useState({
    value: "",
    label: ""
  });
  // Define if the form is for edit a new entry
  const [newEntry, setNewEntry] = useState(false);

  let navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const urlParams = useParams();
  const colourID = urlParams.id;

  const { handleSubmit, reset, control } = useForm({
    validationSchema: colourSchema,
    defaultValues: {}
  });

  const [ staticDataDocument, loading, ] = useDocumentData(getStaticData());

  useEffect(() => {
      function getColours() { 
        const { Colours } = staticDataDocument;
        const col = Colours.filter(function(c) {
          return c.value === colourID;
        });
        if (col.length > 0) {
          setNewEntry(false);
          setColour(col[0]);
          reset(col[0]);
        }
      }
        !loading && getColours();

  }, [reset, staticDataDocument, loading, colourID]);

  useEffect(() => {
    if (colourID === "new_entry") {
      setNewEntry(true);
      reset(colour);
    }
  }, [colourID, colour, reset]);

  const onSubmit = async(data) => {
    if (_.isEqual(data, colour)) return;
    const submittedArray = {
      label: data.label,
      value: data.value,
    }
    await updateFieldArrayUnion(getStaticData(), "Colours", submittedArray, enqueueSnackbar);
    if (newEntry != true) {
      const previousArray = {
        label: colour.label,
        value: colour.value
      }
      await updateFieldArrayRemove(getStaticData(), "Colours", previousArray, enqueueSnackbar);
    }
    navigate("/list-staticdata/colour");     
  };

  return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Card>
              <CardHeader
                title={newEntry ? "New Colour " : "Colour : " + colour.label}
                subheader="Update Colour information"
              />
              <Divider />
              <CardContent>
                <Input name="label" label="Style Name" control={control} />
                <Input name="value" label="Value" control={control} disabled={!newEntry} />
              </CardContent>
              <Divider />
              <CardActions>
                <Button
                  variant="contained"
                  size="medium"
                  color="primary"
                  type="submit"
                  startIcon={<SaveIcon />}
                >
                  Save details
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </form>
  );
}
